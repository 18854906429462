.WrapperProductBestSeller {
  flex: 1;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 237px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.HeaderProductBestSeller > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.ContentProductBestSeller {
  display: flex;
  flex-direction: column;
}

.WrapperContentProductBestSeller {
  display: flex;
  column-gap: 12px;
  padding-top: 10px;
}

.ImageProductBestSeller > img {
  width: 68px;
  height: 48px;
  border-radius: 2px;
}

.ContentTextProductBestSeller {
  display: flex;
  flex-direction: column;
}

.ContentTextProductBestSeller > span:first-child {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.ContentTextProductBestSeller > span:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #7d7d7d;
}
