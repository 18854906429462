.Container {
  width: 100%;
  height: 100vh;
}

.Header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 24px;
}

.Loading {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.LoadingWidth {
  width: 200px;
}
