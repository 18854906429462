.SearchbarComp {
  position: relative;
  height: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.SearchbarComp > input {
  height: 40px;
  width: 395px;
  padding: 22px 60px 22px 20px;
  border-radius: 15px;
  color: var(--txt-color);
  background-color: var(--main-bg);
}

.SearchbarComp > i {
  font-size: 18px;
  position: absolute;
  right: 20px;
  /* color: #fafafa; */
}
@media (max-width: 1024px) {
  .SearchbarComp > input {
    width: 350px;
  }
}
