.modalBackground-modal-tabs-delete-published {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    /* opacity: 50%; */
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalContainer-modal-tabs-delete-published {
    width: 438px;
    height: 368px;
    border-radius: 12px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    display: flex;
    flex-direction: column;
    padding: 15px;
    position: absolute;
    top: 100px;
  }
  