.Modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 438px;
    height: 437px;
    border-radius: 12px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    display: flex;
    flex-direction: column;
    padding: 15px;
    position: absolute;
    top: 100px;
}

.ModalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ModalImageContainer {
    margin-bottom: 24px;
}

.ModalImageContainer>img {
    width: 154px;
    height: 154px;
}

.ModalTitleContainer {
    display: flex;
    flex-direction: column;
}

.ModalTitleContainer>span {
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    padding-top: 0;
    margin-bottom: 10px;
    text-align: center;
}


.ModalText {
    font-size: 12px;
    font-weight: 400;
    color: #757575
}

.ButtonWrapper {
    margin-top: 24px;
}

.ButtonOk {
    background-color: #1571de !important;
    width: 51px;
    height: 40px;
    border-radius: 4px;
    color: #fff !important;
}