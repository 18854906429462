.Container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 734px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 30px 40px;
}

/* .heading-approval {
  display: flex;
  flex-direction: column;
} */

.Heading {
  padding-top: 24px;
}

.Heading > span {
  font-size: 33px;
  color: #333333;
  font-weight: 600;
}

.Heading > p {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}

.ContentItem {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  padding-top: 50px;
}

.InputGroup {
  display: flex;
  /* align-items: center; */
  column-gap: 10px;
  width: 270px;
}

.InputGroupEmployee {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.ColumnGroupEmployee {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ColumnGroup {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.ColumnGroup > span {
  font-size: 11px;
  font-weight: 400;
  color: #797a7b;
}

.ColumnGroup > p {
  font-size: 13px;
  font-weight: 500;
  color: 500;
}

.IconContent {
  height: 24px;
  width: 24px;
}

.BorderBlue {
  /* width: 100%; */
  background: rgba(21, 113, 222, 0.1);
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.BorderBlue > img {
  width: 90px;
  /* height: 60px; */
  cursor: pointer;
  border-radius: 8px;
}

.BorderBlueEmployee {
  width: 100%;
  background: rgba(21, 113, 222, 0.1);
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
}

.BorderBlueEmployee > img {
  width: 90px;
  /* height: 60px; */
  /* cursor: pointer; */
  border-radius: 8px;
}

.BorderBlueEmployee > span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

.ButtonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding-top: 60px;
  margin-top: auto;
}

/* APPROVAL EMPLOYEE  */

.ContainerEmployee {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WrapperEmployee {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  /* position: relative; */
  /* padding-inline: 353px; */
}

.Background {
  width: 100%;
  height: 168px;
  background: linear-gradient(
    91.1deg,
    #1571de -1.57%,
    rgba(21, 113, 222, 0.44) 103.08%
  );
  position: absolute;
  z-index: 1;
  top: 0;
}

.WrapperFormEmployee {
  width: 734px;
  /* width: 100%; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  row-gap: 16px;
  z-index: 3;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  position: absolute;
  top: 120px;
}

.StatusController {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff9e9;
  border: 1px solid #22970f;
  border-radius: 8px;
  padding: 16px;
}

.StatusText {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.StatusText > span:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #22970f;
}

.StatusText > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.StatusControllerReject {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff4f2;
  border: 1px solid #d52701;
  border-radius: 8px;
}

.StatusTextReject {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.StatusTextReject > span:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #d52701;
}

.StatusTextReject > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.HeadingEmployee {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 16px;
  padding-top: 8px;
}

.Text > span {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.TextIcon {
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
}

.TextIcon > span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

.Divider {
  width: 100%;
  border: 1px solid #ededed;
}

.ValueColumn {
  display: flex;
  justify-content: space-between;
}

.ColumnChild {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ColumnChild > label {
  display: flex;
  align-items: flex-end;
}

.ColumnChildResponsive {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ColumnChildResponsive > label {
  display: flex;
  align-items: flex-end;
}

.ValueControlResponsive {
  padding-top: 10px;
}

.ValueControlResponsive > label {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a0a;
}

.ValueControl {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 16px;
}

.ValueControlPrev {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 16px;
  background: #fafafa;
  border-radius: 8px;
  padding: 10px;
}

.ValueControlPrev > label {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.ValueControl > label {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.ValueResponsive {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .Value > ul  {
  padding-top: 16px;
} */

.Value > ul > li {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  padding-top: 10px;
}

.Keys {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}

.Keys > span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.Label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 10px;
}

.Label > span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

.DescIconResponsive {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  column-gap: 10px;
  /* row-gap: 5px; */
}

.DescIconResponsive > span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.DescIcon {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
}

.DescIcon > img {
  width: 20px;
  height: 20px;
}

.DescIcon > span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.DescResponsive {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  column-gap: 10px;
  /* row-gap: 5px; */
}

.DescResponsive > span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.DescResponsive > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.Desc {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Desc > span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
  text-align: right;
}

.PhotoController {
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  row-gap: 16px;
}

.PhotoController > label {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.ButtonGroupEmployee {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding-top: 60px;
  margin-top: auto;
}

.ButtonGroupEmployee > button:first-child {
  text-transform: none;
  background: #d52701;
  border-radius: 8px;
  color: #ffffff;
  width: 100%;
  /* max-width: 230px; */
  height: 40px;
  font-size: 14px;
  font-weight: 500;
}

.ButtonGroupEmployee > button:last-child {
  text-transform: none;
  background: #1571de;
  border-radius: 8px;
  color: #ffffff;
  width: 100%;
  /* max-width: 230px; */
  height: 40px;
  font-size: 14px;
  font-weight: 500;
}

.WrapperResponsive {
  padding-top: 10px;
}

.WrapperResponsive > label {
  font-size: 16px;
  color: #0a0a0a;
  font-weight: 500;
  display: flex;
  max-width: 150px;
}

/* SET PASSWORD */
.SetPasswordContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 24px;
}

.TopBG {
  position: absolute;
  width: 100%;
  height: 240px;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    91.1deg,
    #1571de -1.57%,
    rgba(21, 113, 222, 0.44) 103.08%
  );
}

.SetPasswordBoxContainer {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 48px;
  width: 560px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
}

.SetPasswordBoxContainer div {
  width: 100%;
}

.PasswordContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.PasswordContainerHeader img {
  width: 50px;
  height: 42px;
  object-fit: cover;
}

.BoxTop {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.BoxTop > span:first-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #0a0a0a;
}

.BoxTop > span:last-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.BoxForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.BoxForm label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
}

.InputField {
  height: 40px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #0a0a0a !important;
  width: 100% !important;
  border-radius: 8px !important;
}

.Form > button {
  padding: 10px;
  gap: 10px;
  width: 480px;
  height: 40px;
  background: #1571de;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 40px;
}

.Error {
  background-color: #fee;
  border: 1px solid #edd;
  color: #a66;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 14px;
}

/* RESIGN FORM */
.ContainerResign {
  width: 100%;
  position: relative;
}

.BackgroundResign {
  position: absolute;
  width: 100%;
  height: 130px;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    91.1deg,
    #1571de -1.57%,
    rgba(21, 113, 222, 0.44) 103.08%
  );
}

.BoxWrapperResign {
  width: 100%;
  padding-inline: 200px;
}

.WrapperResign {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.WrapperResignForm {
  width: 100%;
  position: absolute;
  max-width: 734px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  z-index: 3;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  top: 80px;
}

.HeadingResign > span {
  font-size: 33px;
  color: #333333;
  font-weight: 600;
}

.HeadingResign > p {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}

.ValueControlResign {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 10px;
  padding-top: 30px;
}

.MaintenanceContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.MaintenanceMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/* .MaintenanceImage {
  display: flex;
  position: relative;
} */

.MaintenanceImage > img {
  width: 100%;
  height: auto;
}
.MaintenanceText {
  height: 100%;
  margin-left: -250px;
}

.MaintenanceLabel > span {
  color: #000000;
  font-size: 64px;
  font-weight: 700;
}

.MaintenanceMain {
  padding-top: 10px;
}

.MaintenanceTextMain > span {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.MaintenanceTextLast > span {
  color: #808080;
  font-size: 16px;
  font-weight: 400;
}

@media (min-width: 769px) {
  .ValueControlResponsive,
  .ColumnChildResponsive {
    display: none;
  }

  .IconButton {
    position: absolute !important;
    right: 3%;
    top: 0%;
  }
}

@media (max-width: 768px) {
  .WrapperFormEmployee {
    width: 700px;
  }

  .BoxWrapperResign {
    padding-inline: 50px;
  }

  .ValueColumn {
    display: none;
  }

  .ValueControlResponsive {
    display: block;
  }

  .TopBG {
    height: 30vh;
  }

  .IconButton {
    position: absolute !important;
    right: 3%;
    top: 0%;
  }

  .MaintenanceImage {
    padding-left: 40px;
  }

  .MaintenanceImage > img {
    display: none;
  }

  .MaintenanceText {
    margin-left: 0;
  }

  .MaintenanceTextMain {
    padding-top: 0;
  }

  .MaintenanceTextLast {
    padding-top: 5px;
  }
}

@media (max-width: 700px) {
  .WrapperFormEmployee {
    width: 100%;
  }

  .SetPasswordBoxContainer {
    width: 100%;
    gap: 20px;
  }

  .Form > button {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  /* .container-approval-page {
    height: auto;
  } */
  .Wrapper {
    border-radius: 0px;
    box-shadow: none;
    height: 100vh;
  }

  .BoxWrapperResign {
    padding-inline: 15px;
  }

  .Background {
    display: none;
  }

  .WrapperFormEmployee {
    top: 0;
    border-radius: 0px;
    box-shadow: none;
    padding: 20px;
  }

  .Text > span {
    font-size: 20px;
  }

  .Keys > span,
  .Label > span,
  .DescIcon > span,
  .Desc > span,
  .BorderBlueEmployee > span {
    font-size: 12px;
  }

  .WrapperResponsive > label {
    font-size: 14px;
  }

  .StatusControllerReject {
    padding: 10px;
  }

  .StatusTextReject > span:first-child {
    font-size: 14px;
  }

  .StatusTextReject > span:last-child {
    font-size: 12px;
  }

  .StatusController {
    padding: 10px;
  }

  .StatusText > span:first-child {
    font-size: 14px;
  }

  .StatusText > span:last-child {
    font-size: 12px;
  }

  .TopBG {
    height: 100vh;
  }

  .SetPasswordBoxContainer {
    width: 100%;
    gap: 15px;
    padding: 20px;
  }

  .Form > button {
    width: 100%;
    margin-top: 15px;
  }

  .IconButton {
    position: absolute !important;
    right: 5%;
    top: 0%;
  }
}


@media (max-width: 375px) {
  .MaintenanceText {
    padding-inline: 20px;
  }
}

@media (max-width: 320px) {
  .Keys > span,
  .Label > span,
  .DescIcon > span,
  .Desc > span .BorderBlueEmployee > span {
    font-size: 10px;
  }

  .Wrapper {
    padding-right: 30px;
    padding-left: 40px;
    padding-block: 40px;
  }

  .IconButton {
    position: absolute !important;
    right: 3%;
    top: 0%;
  }
}
