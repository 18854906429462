.client-setting-menu {
  width: 100%;
  height: 100%;
}
.wrapper-client-setting {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 20px;
}
.card-setting-menu {
  width: 320px;
  height: auto;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 10px;
  /* padding-top: 20px; */
}
.items-setting-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 10px; */
}

.items-setting-radio > span {
  font-size: 18px;
  font-weight: 500;
  color: #404040;
}
.items__sub-setting {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
  text-align: left;
  /* margin-bottom: 20px; */
}

.items-setting-footer {
  display: flex;
  align-items: center;
  padding-top: 45px;
}
.items-setting-footer > span {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}
