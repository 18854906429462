.Container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.HeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    gap: 17px;
}

.AddButton {
    border-radius: 8px !important;
    color: #1571DE !important;
    border: 1px solid #1571DE !important;
    text-transform: capitalize !important;
}

.TableWrapper {
    padding-top: 24px;
}

.TableContainer {
    min-width: 600;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    border-bottom: 0;
}

.Table {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;

}

.TableSortLabel {
    color: #0a0a0a !important;
    font-weight: 500;
    font-size: 14px;
}

.TableCell {
    font-size: 14px;
    font-weight: 400;
    color: #0a0a0a;
}

.PinLabel span {
    background-color: #F4FFF2;
    border: 1px solid #22970F;
    border-radius: 6px;
    font-size: 12px;
    color: #22970F;
    padding: 2px 8px;
}

.TablePagination {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.Address>span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DeleteCell button {
    display: flex;
    align-items: center;
    background: transparent;
}

.DeleteCell img {
    height: 17px;
    width: 15px;
    object-fit: cover;
}

.Filterer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}