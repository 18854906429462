.ContainerTablePromoFMCG {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.HeaderTablePromoFMCG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.ContainerTablePromoFMCG {
    /* padding-top: 30px; */
}
