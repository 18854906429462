.header__item {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
}

.box__yellow {
  width: 15px;
  height: 28px;
  background-color: #ffe200;
  border-radius: 4px;
}

.header__item > span {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
