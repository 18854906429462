.container {
  background-color: #fff;
  width: 453px;
  max-width: 100vw;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 24px;

    >h3 {
      flex-grow: 1;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    >div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .error {
    padding-block: 12px;
    color: #D42701;
    font-size: 12px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    >* {
      width: 100px;
    }
  }
}

.label {

  >span {
    font-size: 14px;
    line-height: 142.857%;

    &:first-child {
      padding-right: 4px;
    }

    &:nth-child(2) {
      color: #D42701;
    }
  }
}