.Container{
    background-color: #FAFAFA;
    margin: 0;
    padding-inline: 23.7px;
}

.ContainerSetting {
    width: 100%;
    height: 100%;
}

.HeaderSetting {
    display: flex;
    align-items: center;
}

.HeaderSetting span {
    font-size: 20px;
    font-weight: 400;
    color: #255bfc;
  }