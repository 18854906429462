.container {
  position: relative;
  cursor: pointer;
  width: 100%;
  .iconWrapper {
    position: absolute;
    left: 10px;
    top: 8px;
    bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    padding-left: 40px;
    padding-right: 10px !important; // Menghapus ruang untuk ikon
    color: var(--Primary-Main, #1571de);

    /* Text-L/medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  .input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
