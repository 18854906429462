.ContainerDetailsAccountGroup {
  width: 100%;
  height: 100vh;
}

.WrapperContentDetailsAccountGroup {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 30px;
}

.WrapperItemHeaderDetailsAccountGroup {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.WrapperItemHeaderDetailsAccountGroup > span {
  color: #1571de;
  font-size: 16px;
  font-weight: 500;
}

.BorderImageDetailsAccountGroup {
  width: 84px;
  height: 84px;
  background-color: #e3f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.BorderImageDetailsAccountGroup > img {
  width: 32px;
  height: 32px;
}

.WrapperFormDetailsAccountGroup {
  padding-top: 30px;
}

.WrapperItemsInputDetailsAccountGroup {
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.ItemsInputDetailsAccountGroup {
  display: flex;
  column-gap: 15px;
}

.ItemInputDetailsAccountGroup {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  width: 100%;
}

.ButtonActionDetailsStoreAccountGroup {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
  padding-top: 30px;
}
