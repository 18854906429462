.container {
  border-radius: 8px;
  background: var(--Neutral-10, #fff);
  width: 628px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 550px;
  overflow: auto;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .left {
      display: flex;
      align-items: center;
      gap: 15px;
      > div {
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > span {
        color: var(--Neutral-100, #0a0a0a);

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
    }

    .right {
      border-radius: 6px;
      border: 1px solid #22970f;
      background: #f4fff2;
      display: flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      color: #22970f;
      text-align: center;

      /* Text S/Medium */
      //   font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      width: 100px;
      &.void {
        border: 1px solid var(--Danger-Main, #d42701);
        background: var(--Danger-Surface, #fff4f2);
        color: var(--Danger-Main, #d42701);
      }
      &.sold {
        border: 1px solid var(--Neutral-60, #9e9e9e);
        background: var(--Neutral-30, #ededed);
        color: var(--Neutral-60, #9e9e9e);
      }
      &.return {
        border: 1px solid #1571de;
        background: #d0e3f84d;
        color: #1571de;
      }
    }
  }

  .detail {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    background: var(--Neutral-30, #ededed);
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span:first-child {
        color: #88888c;
        // font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
      > span:last-child {
        color: var(--Neutral-100, #0a0a0a);
        text-align: right;

        /* Text-M/regular */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  .log {
    display: flex; // nanti ganti flex lagi kalau mau ditampilin
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-50, #ced4da);
    gap: 40px;

    .loadingBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      > span {
        color: var(--Neutral-100, #0a0a0a);
        text-align: center;

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        &:last-child {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .dataNotFound {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--Neutral-90, #404040);

      /* Text-M/regular */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .listLog {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .left {
        width: 100%;
        max-width: 158px;

        color: var(--Neutral-60, #adb5bd);

        /* Body/Body 2 */
        // font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .middle {
        width: 70px;
        align-items: center;
        // justify-content: center;
        display: flex;
        flex-direction: column;
        position: relative;
        .bullet {
          width: 24px;
          height: 24px;
          border-radius: 50px;
          background-color: rgba(188, 211, 232, 0.8); // 0.5 = Transparansi 50%
          padding: 5px;

          &.disabled {
            background-color: rgba(
              158,
              158,
              158,
              0.3
            ); // 0.3 adalah tingkat transparansi
            > div {
              background-color: #c2c2c2;
            }
          }
          > div {
            // width: 24px;
            // height: 24px;
            width: 100%;
            height: 100%;
            border-radius: 50px;
            background-color: #357abb;
          }
        }

        .line {
          position: absolute;
          top: 25px;
          right: 18px;
          left: 18px;
          width: 2px;
          height: 44px;
          border: 1.5px dashed #c2c2c2;
        }
      }
      .right {
        width: 100%;

        color: var(--Neutral-100, #000);

        /* Subtitle/Subtitle 2 */
        // font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        > span {
          font-weight: 700;
          &.blue {
            color: var(--Primary-Main, #1571de);
          }
        }
      }
    }
  }
}
