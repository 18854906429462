.product__details-container {
  margin-top: 20px;
}

.product__details-wrapper {
  display: flex;
}
.product__detail-img {
  flex: 1;
}

.product__detail-img > img {
  width: 100%;
  height: auto;
}

.wrapper__detail-desc {
  flex: 1;
}

.item__product-detail {
  display: flex;
  justify-content: row;
  justify-content: space-evenly;
}
.item__product-detail-2 {
  display: flex;
  justify-content: row;
  justify-content: space-evenly;
  padding-top: 24px;
}
.item__product-detail-3 {
  display: flex;
  justify-content: row;
  justify-content: space-evenly;
  padding-top: 24px;
}

.desc__detail {
  display: flex;
  flex-direction: column;
}

.desc__detail > label {
  color: #616161;
  font-size: 18px;
  font-weight: 500;
}

.desc__detail > input[type="text"] {
  color: #0a0a0a;
  font-size: 18px;
  font-weight: 400;
}

.desc__detail > input {
  width: 182px;
  padding-top: 8px;
}

.desc__detail-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box__status-detail {
  background: #fbe7b3;
  border: 1px solid #e09f3e;
  border-radius: 8px;
  width: 182px;
  height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.box__status-detail > label {
  color: #a1621f;
  font-size: 14px;
  font-weight: 500;
}

.item__product-button {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.store__available-container {
  padding-top: 32px;
}

.store__available-container > label {
  color: #0a0a0a;
  font-size: 20px;
  font-weight: 500;
}

.items__store-flex {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
}
.item__store {
  max-width: 150px;
}
.item__store > img {
  width: 100%;
  height: auto;
}

.item__add-store {
  width: 144px;
  height: 120px;
  border: 2px dashed #c2c2c2;
  border-radius: 4px;
  background: #f3f5f7;
  cursor: pointer;
}

.item__add-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 15px 0;
}

.item__add-icon > span {
  font-size: 18px;
  font-weight: 500;
  color: #3072B8;
}

.item__store-desc {
  display: flex;
  flex-direction: column;
}

.item__store-desc > label {
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 500;
}

.item__store-desc > span {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (min-width: 2000px) {
  .item__product-button {
    padding-top: 130px;
  }
  .desc__detail > label {
    color: #616161;
    font-size: 24px;
    font-weight: 500;
  }

  .desc__detail > input[type="text"] {
    color: #0a0a0a;
    font-size: 24px;
    font-weight: 400;
  }
}
