.ContainerWrapper {
    margin-left: 32px;
    margin-block: 24px;
}

.ContainerWrapper>span {
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    margin-bottom: 16px;
}

.Container {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding-block: 24px;
}

.Top,
.Bottom,
.ButtonWrapper {
    padding-inline: 24px;
}

.Top>span {
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    margin-bottom: 16px;
}

.BoxForm {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
}

.BoxForm label {
    width: 30%;
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}

.BoxForm>input {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    color: #0a0a0a;
}

/* .BoxForm input[type="text"] {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    color: #0a0a0a;
} */

.MapContainer {
    width: 100%;
    /* height: 359px; */
    background-color: #fff;
    position: relative;
    /* display: flex; */
    padding: 24px;
    margin-inline: 0;
}

.Bottom {
    margin-bottom: 16px;
}

.Bottom>div:first-child {
    margin-bottom: 8px;
}

.BoxAuto {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: rgba(208, 227, 248, 0.3);
    padding: 8px;
    border-radius: 8px;
}

.BoxAuto>span:first-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
}

.BoxAuto>span:last-child {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.LastWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.LastWrapper>div {
    width: 100%;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.ButtonWrapper button {
    border-radius: 8px !important;
    width: 80px !important;
    height: 40px !important;
    text-transform: capitalize !important;
    font-size: 14px;
    font-weight: 500;
}

.SaveButton {
    background: #1571DE !important;
}

.ResetButton {
    color: #1571DE !important;
    border: 1px solid #1571DE !important;
}

.AlertWrapper {
    margin-inline: 24px;
    border-radius: 5px;
}