.container-AT {
  background: #fafafa;
  /* padding-left: 16px;
  padding-right: 99px;
  padding-top: 16px;
  padding-bottom: 16px; */
  padding: 20px;
  border-radius: 8px;
}
.form-group-TA {
  display: flex;
  justify-content: space-between;
}
.form-control-TA {
  display: flex;
  flex-direction: column;
}
.form-control-TA > label {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  font: unset;
}
.form-control-TA > input {
  width: 402px;
  height: 40px;
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 8px;
  padding-left: 12px;
  margin-top: 10px;
}

.form-group-change-password {
  padding-top: 32px;
}
.title-change-password-TA > span {
  font-size: 20px;
  font-weight: 500;
  color: #404040;
}

.form-group-TA-change-password {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.button-helper-TA {
  display: flex;
  padding-top: 24px;
  column-gap: 12px;
}
.form-control-nested {
  width: 402px;
  height: 40px;
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 8px;
  padding-left: 12px;
  margin-top: 10px;
}

@media screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1024px) {
    .form-group-TA {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .form-group-TA-change-password {
        flex-wrap: wrap;
        row-gap: 20px;
    }
}
