.modal-add-user-backgroundSA {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-add-user-container-SA {
  width: 644px;
  height: 426px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: absolute;
  top: 150px;
}

.body-modal-add-user-SA {
  display: flex;
  justify-content: space-around;
  /* flex-direction: row; */
  padding-top: 20px;
  padding-bottom: 20px;
}
.wrapper-user-add-box-1 {
  display: flex;
  column-gap: 10px;
  /* justify-content: space-between; */
}
.user-add-items-1 {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.user-add-items-1 > span {
  font-size: 12px;
  font-weight: 400;
}
.user-add-items-1 > input {
  width: 286px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 8px;
  margin-top: 8px;
}

.body-modal-add-user-SA-main {
  text-align: left;
  padding-inline: 15px;
}
.wrapper-user-add-box-2 {
  display: flex;
}
.wrapper-user-add-box-2 > span {
  font-size: 12px;
  font-weight: 400;
}

/* dropdwown selected */
.box-dropdown-modal-add-user {
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 8px;
  width: 158px;
  height: 40px;
  position: relative;
  user-select: none;
  cursor: pointer;
  margin-top: 10px;
  padding-top: 3px;
}

.box-dropdown-modal-add-user .dropdown-content-modal-add-user {
  position: absolute;
  background: #ffffff;
  padding: 5px;
  color: #9e9e9e;
  top: 110%;
  width: 95%;
}

.box-dropdown-modal-add-user
  .dropdown-content-modal-add-user
  .dropdown-item-modal-add-user {
  padding: 10px;
  cursor: pointer;
}
.box-dropdown-modal-add-user
  .dropdown-content-modal-add-user
  .dropdown-item-modal-add-user:hover {
  background-color: #fafafa;
}

.user-add-items-2 {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.user-add-items-2 > span {
  font-size: 12px;
  font-weight: 400;
}
.user-add-items-2 > input {
  width: 286px;
  height: 40px;
  border: 1.5px solid #d3c9c9;
  /* background: #e0e0e0; */
  border-radius: 8px;
  margin-top: 8px;
}

.footer-modal-add-user-SA {
  display: flex;
  justify-content: flex-start;
  padding: 15px;
}
.wrapper-button-modal-add-user-SA {
  display: flex;
  column-gap: 10px;
}
.wrapper-button-modal-add-user-SA > button {
  text-transform: none;
}
