.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: var(--Neutral-10, #fff);
  width: 488px;
  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px 24px 12px 24px;
    max-height: 500px;
    overflow: auto;

    .top {
      display: flex;
      flex-direction: column;
      gap: 6px;
      > span {
        color: var(--Neutral-90, #556377);

        /* Text-M/regular */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      > div, .div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        gap: 16px;
        border-radius: 8px;
        background: #fff;
        cursor: pointer;

        /* Shadow/100 */
        box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
          0px 4px 4px -2px rgba(24, 39, 75, 0.08);
        > div {
          display: flex;
          align-items: center;
          gap: 12px;
          .imagesExcel {
            padding: 16px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #d6ffeb;
            width: 50px;
            height: 100%;
          }

          > span {
            color: #575361;

            /* Text-M/medium */
            // font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 24px 24px 12px 24px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
    }
    > span {
      color: var(--Neutral-100, #0a0a0a);

      /* Text L/SemiBold */
      //   font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 24px 24px 24px;
    > span {
      font-size: 12px;
      color: red;
    }
    .button {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
}

.succesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: var(--Neutral-10, #fff);
  width: 488px;
  padding: 16px;

  > div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > span:nth-child(2) {
    color: #0f161f;
    text-align: center;
    // font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
  }
  > div:last-child {
    width: 100%;
  }
}
