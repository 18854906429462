.Background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  width: 341px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 24px;
}

.Container img {
  width: 154px;
  height: 154px;
  margin-bottom: 24px;
}

.Desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.Desc > span:first-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #0a0a0a;
}

.Desc > span:nth-child(2) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  margin-bottom: 24px;
}

.OkButton {
  width: 100px;
  height: 40px;
  background: #1571de;
  border-radius: 8px;
  color: #fff !important;
}
