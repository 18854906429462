.container__e-pay-slip {
  width: 100%;
  height: 313px;
  background-color: #fafafa;
  padding: 20px;
  border: 1px solid #dfe0eb;
}

.wrapper__ePay {
  display: flex;
  width: 400px;
}
.rectangleYellow_ePay {
  width: 15px;
  height: 28px;
  background-color: #ffe200;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.chartTitle_ePay {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}
.top__nav-ePay {
  display: flex;
  column-gap: 50px;
}

.items__name-ePay {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 10px;
}

.items__job-ePay {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 70px;
}

.items__date-ePay {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 40px;
}

.detailButton{
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  cursor: pointer;
  color: #1571de;
}
