.Modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalContainer {
    display: flex;
    align-items: center;
    width: 400px;
    height: 80vh;
    border-radius: 12px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    overflow: auto;
}

.ModalBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.Title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 8px;
    background-color: #E0E0E0;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
}

.Body{
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.Component{
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}