.container-client-menu-2 {
  width: 100%;
  height: 100vh;
}

.wrapper-client-menu-2 {
  display: flex;
  align-items: center;
  padding-top: 50px;
  /* width: 379px; */
  /* width: 100%; */
}
.container-table-client-menu-2 {
  padding-top: 24px;
}
