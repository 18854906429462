.Spacer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ButtonAction {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ButtonAction > button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: none;
}

.ButtonAction > button:hover {
  background-color: transparent;
}

.ButtonAction > button:first-child > span {
  font-size: 14px;
  font-weight: 400;
  color: #1571de;
}

.ButtonAction > button:last-child > span {
  font-size: 14px;
  font-weight: 400;
  color: #D1421A;
}

.ButtonAction > button > img {
  width: 17px;
  height: 15px;
}
