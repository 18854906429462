.Container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.Searchbar {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.ButtonPrimary {
    width: 164px !important;
    height: 40px !important;
    background: #1571DE !important;
    border-radius: 8px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff !important;
    text-transform: capitalize !important;
}

.PrimaryText {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.APPROVED {
    background-color: #F4FFF2;
    color: #22970F !important;
    border: 1px solid #22970F;
    padding: 2px 5px;
    border-radius: 5px;
}

.FINISHED {
    background-color: #DEFEFF;
    color: #1571DE !important;
    border: 1px solid #1571DE;
    padding: 2px 5px;
    border-radius: 5px;
}

.PENDING {
    background-color: #FFF9E9;
    color: #E9B62F !important;
    border: 1px solid #E9B62F;
    padding: 2px 5px;
    border-radius: 5px;
}

.REJECTED {
    background-color: #FFF4F2;
    color: #D42701 !important;
    border: 1px solid #D42701;
    padding: 2px 5px;
    border-radius: 5px;
}

.errorWrapper{
    display: flex;
    width: 100%;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    color: #D42701;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;
}

.ProfileWrapper{
    display: flex;
    gap: 12px;
    align-items: center;
}