.newCustomer {
  padding: 20px;
  border: 1px solid #dfe0eb;
  height: 313px;
  background-color: #f3f5f7;
  border-radius: 8px;
}

.wrapperTextNewCust {
  display: flex;
  width: 400px;
}
.rectangleYellowCust {
  width: 15px;
  height: 28px;
  background-color: #ffe200;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.chartTitle {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.wrapperChartCustomer {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  /* text-align: center; */
  justify-content: space-between;
}

/* .wrapperChart-1 {
  flex: 1;
  height: 50%;
}

.wrapperChart-2 {
  flex: 1;
} */

.textChartCircleOne {
  align-items: center;
  height: 100%;
  width: 200px;
}

.wrapperChartNewCust {
  padding-top: 35px;
}
.circleOneTextMain {
  font-size: 22px;
  font-weight: 500;
  color: #616161;
}
.numberBig {
  font-size: 24px;
  font-weight: 500;
}
.numberSmall {
  font-size: 14px;
  font-weight: 400;
}

.wrapperNewCustRate {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.ChartIconBelow {
  color: #1571de;
}
.textCustMain {
  color: #1571de;
}

.textCustLast {
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
}

#textInChart {  
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .wrapperChartNewCust {
    padding-top: 60px;
  }
  .circleOneTextMain  {
    font-size: 18px;
  }

  /* .textInChart {

  } */
}

@media screen and (max-width: 1024px) {
  .wrapperChartNewCust {
    padding-top: 40px;
  }
}
