.Background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Container {
    width: 528px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
    padding: 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.Header span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}

.Header button {
    padding: 0;
    margin: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Content {
    padding: 24px;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.BoxForm {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.BoxForm label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
}

.BoxForm input,
.BoxForm textarea {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
}

.BoxForm input {
    height: 40px;
}

.BoxForm textarea {
    min-height: 60px;
    padding: 10px 8px;
}

.ButtonWrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.ButtonWrapper button {
    width: 80px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
}

.ButtonWrapper>button:first-child {
    background: #1571DE;
    color: #fff;
}

.ButtonWrapper>button:last-child {
    border: 1px solid #1571DE;
    color: #1571DE;
    background: #fff;
}