.container-DD {
    width: 100%;
    height: 100vh;
  }
  .wrapper-DD {
    padding-top: 32px;
  }
  .wrapper-text-DD {
    display: flex;
    flex-direction: column;
  }
  .wrapper-text-DD span {
    font-size: 24px;
    font-weight: 500;
    color: #0a0a0a;
  }
  .wrapper-text-DD label {
    font-size: 12px;
    font-weight: 400;
    color: #757575;
  }
  .input-DD {
    padding-top: 32px;
  }
  .input-DD span {
    font-size: 14px;
    font-weight: 400;
    color: #404040;
  }
  