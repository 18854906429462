.Container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.GroupControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-inline: 8px;
}

.ItemTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.TextHeading > span {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.ButtonsHelper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ButtonsHelper > img {
  width: 24px;
  height: 24px;
}

.ButtonEdit {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

.ButtonEdit > span {
  font-size: 14px;
  font-weight: 500;
  color: #1571DE;
}

.ButtonEdit > img {
  width: 20px;
  height: 20px;
}

.ButtonDelete {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

.ButtonDelete > span {
  font-size: 14px;
  font-weight: 500;
  color: #D1421A;
}

.ButtonDelete > img {
  width: 20px;
  height: 20px;
}

.BoxControl {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.TextLabel {
  width: 100%;
}

.TextLabel > span {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 500;
}

.TextLabelTop {
  width: 100%;
}

.TextLabelTop > span {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 500;
}

.ValueTop {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.ValueTop > label {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.ValueTop > span {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  text-align: justify;
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

.Value {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.Value > label {
  max-width: 200px;
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.Value > span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

/* .Value > ul > li {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
} */

.LeftContent {
  padding-left: 14px;
}

.LeftContent > ul > li {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.RightContent {
  text-align: right;
}

.RightContent > ul > li {
  font-size: 14px;
  font-weight: 400;
  color: #0a0a0a;
}

.RightContent > ul {
  list-style-type: none;
}

.BoxControlGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 15px;
  column-gap: 10px;
}

.BoxGroup {
  flex: 50%;
  display: flex;
  flex-direction: column;
  max-width: 520px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  row-gap: 10px;
  margin-top: 8px;
}

.ValueGroup {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.BoxGroupInformation {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 520px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  row-gap: 10px;
  margin-top: 8px;
}
