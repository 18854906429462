.wrapper-client-SA {
  display: flex;
  align-items: center;
}
.wrapper-button-client-SA {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 108px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
  margin-left: 24px;
  margin-bottom: 40px;
  cursor: pointer;
  padding-inline: 10px;
}

.wrapper-button-client-SA > button {
    text-transform: none;
}
.wrapper-button-client-SA > span {
   padding-left: 10px;
}
