.container__add-scheme {
  /* width: 100%;
  height: 100vh; */
  padding: 0;
  margin: 5px 8px;
}

.wrapper__form-add-scheme {
  margin-top: 20px;
  /* height: 582px; */
}

.wrapper__form-add-scheme>span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
  display: block;
  margin-bottom: 20px;
  width: 100%;
}

hr {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #9e9e9e;
}


.box__form-add-scheme {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  row-gap: 10px;
}

.box__form-add-scheme > span {
  font-size: 14px;
  color: #404040;
  font-weight: 400;
}



.box__form-add-scheme>input {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #616161;
  padding:8px;
}

.box__form-add-scheme>i {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 8px;
}

.box__form-add-scheme-desc {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 10px;
  padding-bottom: 20px;
}

.box__form-add-scheme-desc>span {
  font-size: 14px;
  color: #404040 !important;
  font-weight: 400;
  margin-top: 8px;
}

.box__form-add-scheme-desc>input,
.box__form-add-scheme-desc>textarea {
  border: 1px solid #d9d9d9;
  background: #fbfbfb;
  border-radius: 8px;
  width: 100%;
  height: 80px;
  padding: 8px;
  font-size: 14px;
  color: #616161;
}

.box__form-add-scheme-desc>i {
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 8px;
}

.duration_input {
  border-radius: 8px !important;
}

/* body main  */
.main__add-scheme {
  padding-top: 20px;
}

.wrapper__main-add-scheme {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.wrapper__main-add-scheme {
  font-size: 20px;
  color: #616161;
  font-weight: 400;
  line-height: 28px;
}

.button-save-adds-scheme {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-save-adds-scheme>button {
  width: 120px;
  height: 40px;
  border: none;
  background-color: #1571de;
  border-radius: 8px;
  color: white;
}

.button-clear-adds-scheme {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-clear-adds-scheme>button {
  width: 120px;
  height: 40px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #1571de !important;
  border: none;
  color: #1571de;
}



/* popup modal  */
.container-modal-add-scheme {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-modal-add-scheme {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  column-gap: 12px;
  row-gap: 10px;
}

.card-pagi {
  width: 217px;
  height: 194px;
  background: #fafafa;
  border-radius: 8px;
  padding: 10px;
}

.container__time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.container__time-picker>span {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f70;
}

/* modal keterlambatan */
.container-modal-keterlambatan {
  display: flex;
  justify-content: center;
  align-items: center;
  /*  */
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 10px;
}

.wrapper-modal-keterlambatan {
  /* height: 68px; */
  /* background: #fafafa; */
  border-radius: 25px;
  padding-top: 10px;
  /* padding: 20px; */
}

.note-text-cutoff {
  display: flex;
  align-items: center;
  color: #6f6f70;
}

.card-max {
  width: 911px;
  /* width: 100%; */
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  padding-bottom: 10px;
  height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 10px;
}

.card-max-work-hour-total {
  width: 699px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  padding-bottom: 10px;
  height: 80px;
}

.card-max-lateness {
  width: 699px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  padding-bottom: 10px;
  height: 80px;
}

.card-max-cut-off {
  width: 699px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  padding-bottom: 10px;
  height: 80px;
}

.main-text-keterlambatan>span {
  font-size: 20px;
  font-weight: 500;
  color: #404040;
}

.item-sub-keterlambatan {
  display: flex;
  column-gap: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #6f6f70;
  /* padding-left: 20px; */
}

.box-input {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.box-input2 {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.box-input2 > span,
.box-input > span
{
 color: #616161;
}

.box-input >input {
  width: 42px;
  height: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.box-input>input[readonly] {
  width: 42px;
  height: 28px;
  background: #d9d9d9;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  /* padding-left: 20px; */
}

.box-input>input:disabled {
  width: 42px;
  height: 36px;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  color: #9e9e9e;
  border-radius: 8px;
  /* padding-left: 20px; */
}

@media (max-width: 1024px) {
  .box__form-add-scheme-desc>input {
    width: 599px;
  }

  .card-max {
    column-gap: 10px;
    padding: 10px;
    width: 650px;
  }

  .card-max-work-hour-total {
    width: 650px;
  }

  .card-max-lateness {
    width: 650px;
  }

  .card-max-cut-off {
    width: 650px;
  }

  .main-text-keterlambatan>span {
    font-size: 14px;
  }

  .item-sub-keterlambatan {
    column-gap: 0px;
  }
}