.ContainerDetailsStore {
  width: 100%;
  height: 100%;
}

.ContentDetailsStore {
  padding-top: 20px;
  width: 100%;
  position: relative;
  margin-bottom: -50px;
}

.ContentDetailsStore > img {
  width: 100%;
  object-fit: cover;
}

.WrapperContentFloatDetailsStore {
  display: flex;
  column-gap: 15px;
  transform: translateY(-100px);
  padding-inline: 20px;
}

.ContentStatusDetailsStore {
  padding: 20px;
  flex: 0.4;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  height: 186px;
  justify-content: center;
}

.ContainerTableListEmployeeDetailsStore {
  padding-top: 30px;
}

.WrapperImageCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background-color: #e3f0ff;
}

.WrapperImageCircle > img {
  width: 34px;
  height: 34px;
}

.WrapperTextDetailsStore > span {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.BoxStatusDetailsStore {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1ac635;
  border-radius: 15.5px;
  width: 72px;
  height: 30px;
}

.BoxStatusDetailsStore > span {
  color: #1ac635;
  font-size: 13px;
  font-weight: 400;
}

.ContentInfoStatusDetailsStore {
  padding: 20px;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 186px;
  overflow: scroll;
  display: flex;
  justify-content: space-between;
}

.WrapperItemsDetailsStore {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 6px;
}

.ItemsDetailsStore {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.ItemsDetailsStoreLeft {
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-width: 100px;
}

.ItemsDetailsStoreLeft > img {
  width: 16px;
  height: 16px;
}

.ItemsDetailsStoreLeft > span {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

.ItemsDetailsStoreRight > span {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

.ButtonActionDetailsStore {
  display: flex;
  column-gap: 10px;
}

.ButtonActionDetailsStore > img:nth-of-type(1) {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.ButtonActionDetailsStore > img:nth-of-type(2) {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.SearchbarDetailsStore {
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
}
