.container-ACIE {
  width: 100%;
  height: 100vh;
}
.wrapper-ACIE {
  padding-top: 37px;
}

/* .title-ACIE {
  padding-top: 20px;
} */

.title-ACIE > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.items-ACIE {
  /* display: flex;
    justify-content: flex-start; */
  padding-top: 24px;
}

.title-main-ACIE > span {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}

/* .item-1-ACIE {

} */
.item-1-ACIE > span {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.btn-ACIE {
  padding-top: 56px;
}
.btn-ACIE > button {
  width: 59px;
  height: 40px;
  background: #1571de;
  border-radius: 4px;
  color: white;
}
