.Container {
  padding-left: 10px;
  padding-right: 24px;
  padding-top: 24px;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.GroupControl {
  display: flex;
  flex: 1.3;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.FormControl {
  width: 100%;
}

.Heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Text > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.Switch {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.Switch > span {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.Value {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.ValueControl {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.ValueControl > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.ValueControl > input {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  padding: 10px 8px;
}

.ValueControl > select {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 40px;
  padding-inline: 10px;
  padding-block: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 7px;
}

.ValueControl > select:focus {
  outline: none;
}

.ValueControl > input::-webkit-input-placeholder {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.ValueControlDisabled {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.ValueControlDisabled > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.ValueControlDisabled > input {
  background: #e0e0e0;
  border: 1px solid #c2c2c2;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  padding: 10px 8px;
}

.ValueControlDisabled > input::-webkit-input-placeholder {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.InputContainer {
  display: flex;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 38px;
}

.InputAdornment {
  height: 100%;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 10px 10px 10px 10px;
  background-color: #fafafa;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputAdornment > span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.InputContainer > input {
  width: 100%;
  border: 0;
  outline: none;
  padding-inline: 10px;
  padding-top: 8px;
  height: 30px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.InputContainer > input::-webkit-input-placeholder {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.InputContainerRight {
  display: flex;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 38px;
}

.InputContainerRightDate {
  display: flex;
  width: 100%;
  /* background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 38px; */
}

.InputAdornmentRight {
  height: 100%;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputAdornmentRight > span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.InputContainerRight > input {
  width: 100%;
  border: 0;
  outline: none;
  padding-inline: 10px;
  padding-top: 8px;
  height: 30px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.InputContainerRight > input::-webkit-input-placeholder {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.GroupControlImage {
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
}

/* .GroupControlImage > img {
  width: 250px;
  height: 250px;
} */

.PictureContainer {
  padding-top: 20px;
}

.PictureContainerControl {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 24px;
}

.PictureContainerControl > span {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.PictureContainerControl > span > label {
  font-size: 14px;
  font-weight: 400;
  color: #9e9e9e;
}

.WrapperPictureUpload {
  text-align: center;
  width: 100%;
  /* max-width: 500px; */
  height: 156px;
  background: rgba(211, 226, 255, 0.1);
  border: 1px dashed #9e9e9e;
  border-radius: 8px;
  padding-block: 16px;
  padding-inline: 10px;
}

.DragAreaPicture {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.DragAreaPicture > img {
  width: 120px;
  height: 76px;
  object-fit: cover;
  border-radius: 8px;
}

.TextInside {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
}

.TextInside > span {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.Desc {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 200px;
}

.Desc > span {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #616161;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.Desc > button {
  border: 1px solid #1571de;
  color: #1571de;
  width: 120px;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  text-transform: none;
}

.BoxInputImage {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.BoxInputImage > span {
  font-size: 14px;
  font-weight: 500;
  color: #616161;
}

.BoxInputFile span {
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
}

.BoxInputOr span {
  font-size: 14px;
  font-weight: 500;
  color: #616161;
}

.ContainerInputImage {
  position: absolute;
  top: 10px;
  left: 250px;
  z-index: 1;
}

.BoxInputButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BoxInputButton > button {
  width: 126px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 8px;
  text-transform: none;

  display: flex;
  column-gap: 5px;
}

.BoxInputButton > button > span {
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
}

.BoxInputButton > button > img {
  width: 20px;
  height: 20px;
  padding-bottom: 2px;
}

.ButtonAction {
  display: flex;
  column-gap: 16px;
}

.ButtonAction > button:first-child {
  width: 100%;
  background: #1571de;
  border-radius: 8px;
  text-transform: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.ButtonAction > button:last-child {
  width: 100%;
  background: #ffffff;
  border: 1px solid #1571de;
  border-radius: 8px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
}

.DisplayImage {
  /* display: flex; */
  width: 200px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eae6e6;
  position: absolute;
  /* position: sticky; */
  top: 10%;
  /* top: 270px; */
  right: 50%;
  transform: translate(50%, -50%);
}

.DisplayImage > img {
  object-fit: cover;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.DisplayImage:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}

.DisplayImage:hover > img {
  opacity: 0.5;
}

.DisplayImage:hover > .DisplayImageHover {
  cursor: pointer;
  opacity: 1;
}

.DisplayImageHover {
  position: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  transition: 0.5s ease;
  opacity: 0;
}

.DisplayImageHover > img {
  width: 32px;
  height: 32px;
  color: white;
}

.DisplayImageHover > span {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 220px;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
}



