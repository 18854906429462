.Container {
    width: 100%;
    max-height: 100%;
    height: 100%;
    min-height: calc(100vh - 130px);
  }
  
  .Content {
    width: 100%;
    padding-inline: 10px;
    padding-top: 24px;
  }
  
  .ValueControl {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
  }
  
  .ValueControlContent {
    width: 100%;
    padding-top: 24px;
  }
  
  .ValueInductionControl {
    width: 100%;
    padding-top: 24px;
  }
  
  .GroupControl {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  
  .GroupControl > span {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }
  
  .ToolbarClassName {
    background-color: red;
    display: flex;
    padding-top: 10px;
  }
  
  .EditorClassName {
    width: 100%;
    background-color: #ffffff;
    min-height: 200px;
  }
  
  /* .GroupDraft {
    widows: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
  } */

  .GroupDraft {
  widows: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  gap: 8px;
}

.GroupDraft > span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.GroupDraft > textarea {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.05) inset;
  resize: vertical;
  min-height: 100px;
  outline: none;
  height: 100px;
  padding-inline: 8px;
  padding-block: 10px;
}
  
  .Selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    padding-top: 8px;
  }
  
  .Type {
    display: flex;
    flex: 1;
    column-gap: 10px;
    align-items: center;
    padding: 24px;
    background: #ffffff;
    /* border: 1px solid #1571de; */
    border-radius: 8px;
    cursor: pointer;
  }
  
  .Type:hover {
    border: 1px solid #1571de;
  }
  
  .Active {
    display: flex;
    flex: 1;
    column-gap: 10px;
    align-items: center;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #1571de;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .Active > img {
    widows: 54px;
    height: 54px;
  }
  
  .Active > span {
    font-size: 16px;
    font-weight: 500;
    color: #0a0a0a;
  }
  
  .Type > img {
    widows: 54px;
    height: 54px;
  }
  
  .Type > span {
    font-size: 16px;
    font-weight: 500;
    color: #0a0a0a;
  }
  
  .Value {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  
  .Value > span {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }
  
  .Value > input {
    width: 100%;
    max-width: 520px;
    height: 40px;
    background: #fff;
    border: 1px solid #d3c9c9;
    border-radius: 8px;
    padding-left: 10px;
  }
  
  .Value > textarea {
    font-size: 14px;
    font-weight: 500;
    resize: vertical;
    padding-inline: 8px;
    padding-block: 10px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
    border-radius: 8px;
    min-height: 100px;
    outline: none;
    height: 171px;
  }
  
  .ActionButton {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
  }
  
  .ActionButton > button {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
  }
  
  .ActionButton > button:nth-of-type(1) {
    color: #1571de;
  }
  
  .ActionButton > button:nth-of-type(2) {
    width: 100px;
    color: #ffffff;
    background: #1571de;
    border-radius: 8px;
  }
  
  .ActionButton > button:nth-of-type(3) {
    width: 100px;
    color: #1571de;
    background: #ffffff;
    border: 1px solid #1571de;
    border-radius: 8px;
  }
  
  .CompanyControl {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding-top: 16px;
  }
  
  .CompanyValue {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    column-gap: 8px;
  }
  
  .CompanyValue > img {
    width: 15px;
    height: 16px;
  }
  
  .CompanyValue > span {
    font-size: 14px;
    font-weight: 500;
    color: #0a0a0a;
  }
  
  .ContainerUploadImage {
    margin-block: 20px;
    display: flex;
    row-gap: 24px;
    /* justify-content: center; */
  }
  
  
  .WrapperUploadImage {
    text-align: center;
    width: 100%;
    height: 190px;
    background: rgba(211, 226, 255, 0.1);
    border: 1px dashed #9e9e9e;
    border-radius: 8px;
    padding: 16px;
  }
  
  .BoxInputImage {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  
  .BoxInputImage > span {
    font-size: 20px;
    font-weight: 400;
    color: #616161;
  }
  
  .BoxInputFile span {
    font-size: 16px;
    font-weight: 500;
    color: #c2c2c2;
  }
  
  .BoxInputOr span {
    font-size: 20px;
    font-weight: 500;
    color: #616161;
    padding-bottom: 16px;
  }
  
  .ContainerInputImage {
    position: absolute;
    top: 10px;
    left: 250px;
    z-index: 1;
  }
  
  .BoxInputButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .BoxInputButton > button {
    width: 126px;
    height: 40px;
    border: 1px solid #1571de;
    border-radius: 8px;
    text-transform: none;
  }
  