.container-bpjs-menu {
  width: 100%;
  height: 100vh;
  /* background-color: aquamarine; */
}
/* .wrapper-bpjs-menu  {

} */
.wrapper-box-top-bpjs {
  display: flex;
  align-items: center;
  column-gap: 16px;
  /* justify-content: space-between; */
}

.wrapper-table-box-search-bpjs-menu {
  padding-top: 24px;
}

/* modal bpjs menu  */
.title-modal-bpjs-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-modal-bpjs-menu > label {
  display: hidden;
  font-size: 0;
}
.title-modal-bpjs-menu > span {
  font-size: 24px;
  font-weight: 500;
  color: #404040;
}
.wrapper-img-bpjs-menu {
  padding-top: 24px;
  display: flex;
}
.desc-profile-bpjs-menu {
  display: flex;
  /* background-color: aqua; */
  flex: 1.6;
}
.desc-profile-bpjs-menu > img {
  width: 79px;
  height: 79px;
}
.detail-text-bpjs {
  padding-left: 12px;
}
.detail-text-bpjs > h1 {
  font-size: 20px;
  font-weight: 500;
  color: #0A0A0A;
}
.detail-text-bpjs > span {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
}
.detail-text-bpjs > h4 {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}
.desc-button-download {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aquamarine; */
}
.wrapper-box-bpjs-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

