.ContainerWrapper {
    margin-block: 24px;
    margin-left: 32px;
}

.ContainerWrapper>span {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
}

.Container {
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-top: 16px;
}

.Form>span {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
    margin-bottom: 26px;
}


.BoxForm {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.BoxForm label {
    width: 30%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
}

.BoxForm input {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.ButtonWrapper button {
    border-radius: 8px !important;
    width: 80px !important;
    height: 40px !important;
    text-transform: capitalize !important;
    font-size: 14px;
    font-weight: 500;
}

.SaveButton {
    background: #1571DE !important;
}

.ResetButton {
    color: #1571DE !important;
    border: 1px solid #1571DE !important;
}