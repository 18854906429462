.recentStore {
  /* flex: 1; */
  /* margin: 20px; */
  padding: 20px;
  border: 1px solid #dfe0eb;
  width: 100%;
  /* width: 935px; */
  height: 300px;
  /* margin-inline: 20px; */
  background-color: #f3f5f7;
}

.wrapperStorePromo {
  display: flex;
}
.rectangleYellowStorePromo {
  width: 15px;
  height: 28px;
  background-color: #ffe200;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.chartTitleStorePromo {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.wrapperItemsBestSpg {
  padding-bottom: 40px;
}
.textItemsStoreMain {
  font-size: 20px;
  font-weight: 500;
  color: #404040;
}

.textItemsStoreSub {
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
}

.titleTopNavStorePromo {
  display: flex;
  /* justify-content: space-between; */
}
/* // column-gap: 100px; */

.productNameStorePromo {
  padding-left: 100px;
}

.StorePromo {
  padding-left: 100px;
}
.dateStorePromo {
  padding-left: 150px;
}

.boxProductCustBottom {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.boxProductCustBottom > span {
  font-size: 14px;
  font-weight: 500;
}
.itemsStorePromoName {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding-left: 10px;
}

.itemsStorePromoProduct {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 55px;
}

.itemsStorePromoProductDisplay {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 50px;
}

.itemsStorePromoKatalog {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 85px;
}
.itemsStorePromo {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 65px;
}

.itemsStorePromoDisplay {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 50px;
}

.itemsStorePromoKatalogName {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 120px;
}
.itemsDateStorePromo {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 115px;
}

.itemsDateStorePromoDisplay {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 125px;
}

.itemsDateStorePromoKatalog {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 130px;
}
/* .detailButton {
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    cursor: pointer;
    color: #1571de;
  } */
/* 
  .detailButton > span{
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    cursor: pointer;
    color: #1571de;
  } */

/* store download  */
.storeDownload {
  /* width: 935px; */
  height: 385px;
  /* padding: 20px; */
  /* background-color: turquoise; */
  /* padding: 20px; */
  margin-top: 20px;
  /* padding-inline: 20px; */
}

.wrapper__store-download {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

/* .wrapper__left {
  position: relative;
  height: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-right: auto;
}

.wrapper__left > input {
  height: 40px;
  width: 395px;
  padding: 22px 60px 22px 20px;
  border-radius: 15px;
  color: var(--txt-color);
  background-color: var(--main-bg);
}

.wrapper__left > i {
  font-size: 18px;
  position: absolute;
  right: 20px;
} */
.wrapper__right {
  display: flex;
  align-items: center;
  width: 108px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
  margin-left: 24px;
  margin-bottom: 40px;
  cursor: pointer;
  padding-inline: 10px;
}

.button__right-store {
  display: flex;
}

.button__right-store > span {
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
}

.button__add-store {
  display: flex;
  align-items: center;
  width: 135px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
  margin-left: 24px;
  margin-bottom: 40px;
  cursor: pointer;
  padding-inline: 10px;
  background-color: #1571de;
}

.button__add-store-main {
  display: flex;
}

.button__add-store-main > span {
  text-align: left;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.wrapper__table-store {
  width: 934px;
  height: 385px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 20px;
}

.nav__table-store {
  display: flex;
  column-gap: 80px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}

.items__table-store {
  padding: 10px;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  /* column-gap: 40px; */
}

/* .items__table-store > img {
    padding-right: auto;
  } */

.coordinat__table {
  padding-left: 10px;
}

.store-name__table {
  padding-left: 63px;
}

.store-employee__table {
  padding-left: 65px;
}

.store-number__table {
  padding-left: 35px;
}

.items__table-store > span {
  font-size: 14px;
  font-weight: 400;
  /* padding-left: 50px; */
}

.wrapper__icon-employee {
  padding-left: 20px;
}
.wrapper__icon-employee > img {
  /* position: absolute;
    top: 0; */
  width: 24px;
  height: 24px;
}
