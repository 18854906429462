.ContainerTableAttendanceFMCG {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.HeaderTableAttendanceFMCG {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.BoxFilterTableAttendancesFMCG {
  width: 100%;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  flex-direction: row;
  gap: 10px;
}

.WrapperPosition {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}

.WrapperStatus {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}

.WrapperLeader {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}

.WrapperRangeDate {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
}

.WrapperItemsRangeDate {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ItemRangeDateFrom {
  display: flex;
  width: 100%;
}

.WrapperRangeDate > span {
  display: flex;
  margin-right: auto;
}

.ItemRangeDateTo {
  display: flex;
  width: 100%;
}

.ContainerAttendanceFMCG {
  padding-top: 30px;
}

.ContainerAttendanceFMCGLoading {
  padding: 90px 0;
  /* height: 100%; */
  display: flex;
  justify-content: center;
}

.IconWrapper {
  display: flex;
  justify-content: center;
}

.IconContainer {
  background: #ecf5ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.SearchWrapper {
  display: flex;
  gap: 10px;
}

/* 
@media (max-width: 1100px) {
  .BoxFilterTableAttendancesFMCG {
    flex-wrap: wrap;
  }
} */
