.items-setting-footer-workspace-seting-menu {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.items-setting-footer-workspace-seting-menu > span {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}
