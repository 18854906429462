.container {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  border-radius: 8px;
  overflow: hidden;
  // border-bottom-left-radius: unset;
  // border-bottom-right-radius: unset;
  border: 1px solid #dee2e7;
  // .borderPagination {
  //   border: unset;
  //   background-color: salmon;
  // }
  .expandButton {
    display: grid;
    place-items: center;

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .tableWrapper {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      thead {
        z-index: 1;
        tr {
          th {
            padding: 12px 16px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 142.857%;
            white-space: nowrap;
            z-index: 1;
            background-color: #ededed;
            color: #0a0a0a;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid black;
          &:hover {
            background-color: #fafafa;
          }
          &[is_active="true"] {
            background-color: #1571de;
          }
          td {
            padding: 12px 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            white-space: wrap;
            border-bottom: 1px solid #edeff2;
            border-collapse: collapse;

            > span {
              overflow: hidden;
              display: -webkit-box;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              width: max-content;
            }
          }

          &.expandedRow {
            background-color: #edeff2;
          }
        }
      }
      .emptyData {
        &:hover {
          background-color: unset;
        }
      }
    }
  }

  .tableWrapper::-webkit-scrollbar {
    display: none;
  }
  .tableWrapper {
    scrollbar-width: thin;
    scrollbar-color: #919eb0 transparent;
  }
  .tableWrapper::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .tableWrapper::-webkit-scrollbar-thumb {
    background-color: #919eb0;
  }
  .paginationWrapper {
    padding: 10px 16px;
    background-color: #edeff2;
  }
}

.emptyHandlerBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  .images {
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 231.048px;
      height: 213.493px;
      object-fit: cover;
    }
  }
  > span:nth-child(2) {
    color: var(--Neutral-100, #0a0a0a);
    text-align: center;

    /* Text L/SemiBold */
    // font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
  }

  > span:last-child {
    color: var(--Neutral-100, #0a0a0a);

    /* Text-M/regular */
    // font-family: Poppins;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
