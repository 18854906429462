.container-user-detail-employee {
  width: 100%;
  height: 100vh;
}

.heading-user-detail-employee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 33px;
}

.heading-user-detail-left > span {
  font-size: 40px;
  font-weight: 500;
  color: #000000;
}

.heading-user-detail-right {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.heading-user-detail-right > button:nth-of-type(1) {
  border: 1px solid #1571de;
  border-radius: 17px;
  width: 118px;
  height: 34px;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 13px;
}

.button-assign-leader-user-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .button-assign-leader-user-detail > button {
  text-transform: none;
  background: #1571de;
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;

} */

/* .heading-user-detail-right > button:nth-of-type(2) {
  background: #1571de;
  border-radius: 17px;
  width: 118px;
  height: 34px;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
} */

.wrapper-user-detail-employee {
  display: flex;
  align-items: center;
  /* padding-top: 20px; */
}
.wrapper-user-detail-employee-data {
  display: flex;
  align-items: center;
  column-gap: 20px;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  row-gap: 20px;
}
.wrapper-searchbox-detail-employee {
  position: relative;
  height: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  /* box-shadow: var(--box-shadow); */
  border-radius: var(--border-radius);
  overflow: hidden;
}
.wrapper-table-detail-employee {
  margin-top: 24px;
  background-color: #fafafa;
}
.data-employee-menu-right-download {
  margin-left: auto;
}
@media (max-width: 1024px) {
  .wrapper-searchbox-detail-employee {
    width: 310px;
  }
}
