.input__text {
  width: 100%;
  height: 40px;
  /* background: #fbfbfb; */
  border: 1.5px solid #d3c9c9;
  border-radius: 8px;
  padding-left: 10px;
}

.input__text:hover {
  border-color: black;
}

.input__text:focus{
  border-color:#1876d1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* @media screen and (max-width: 1200px) {
  .input__text {
    width: 300px;
  }
} */
