.FormControl {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.InputControl > p {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  color: #000000;
}

.InputControl > textarea {
  width: 100%;
  resize: vertical;
  padding-inline: 8px;
  padding-block: 10px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  min-height: 100px;
  outline: none;
}
