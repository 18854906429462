.container {
  height: calc(100vh - 130px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header>.left {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 12px;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user>img {
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;
}