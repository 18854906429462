.worskpace-settings-menu {
  width: 100%;
  height: 100vh;
}
.wrapper-worskspace-download-settings {
  display: flex;
  /* padding-top: 20px; */
  align-items: center;
}
.workspace__settings-left {
  position: relative;
  height: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  /* box-shadow: var(--box-shadow); */
  border-radius: var(--border-radius);
  overflow: hidden;
}
.workspace__settings-left > input {
  height: 40px;
  width: 395px;
  padding: 22px 60px 22px 20px;
  border-radius: 15px;
  color: var(--txt-color);
  background-color: var(--main-bg);
}
.workspace__settings-left > i {
  font-size: 18px;
  position: absolute;
  right: 20px;
}

.button-add-scheme-workspace {
  margin-left: auto;
}
.button-add-scheme-workspace > button {
  width: 148px;
  height: 40px;
  /* text-transform: none; */
  /* background-color: #1571de; */
  border-radius: 4px;
}
.wrapper-card-workspace {
  padding-top: 24px;
}
.card-setting-workspace {
  width: 298px;
  height: 170px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 10px;
}
.title-setting-workspace {
  display: flex;
  flex-direction: column;
  width: 200px;
  row-gap: 10px;
}
.title-setting-workspace > span {
  font-size: 20px;
  font-weight: 500;
  color: #404040;
}
.title-setting-workspace > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}
.button-view-detail-workspace > button {
  text-transform: none;
}
.wrapper-button-bottom-workspace {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}
.button-view-detail-workspace-right {
  display: flex;
  column-gap: 10px;
}
.button-view-detail-workspace-right > button {
  /* border: none; */
  background-color: #fafafa;
}
