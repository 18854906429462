.Container {
    width: 100%;
    min-height: 100vh;
}


.Header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 16px;
}

.Searchbar {
    position: relative;
    height: 40px;
    background-color: var(--main-bg);
    display: flex;
    align-items: center;
    overflow: hidden;
}

.AutoComplete {
    width: 210px;
    background-color: var(--main-bg);
}

.TableContainer {
    min-width: 600px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    border-bottom: 0;
}

.Table {
    font-size: 20px !important;
}

.TableCell {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #0A0A0A !important;
}

.ViewDetails {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1571DE;
}

.Searchbar {
    position: relative;
    height: 40px;
    background-color: var(--main-bg);
    display: flex;
    align-items: center;
    overflow: hidden;
}

.TableWrapper {
    margin-top: 16px;
}

.TableContainer {
    min-width: 600px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    overflow: hidden;
    border-bottom: 0 !important;
}

.Table {
    font-size: 20px !important;
}

.Plus {
    cursor: pointer;
    color: #1571DE;
    font-size: 12px !important;
}

.PopoverDiv {
    max-width: 200px;
    padding: 5px 8px;
}

.PopoverDiv span {
    font-size: 12px;
    color: #0a0a0a;
    text-transform: capitalize !important;
}

.Avatar {
    width: 30px !important;
    height: 30px !important;
}