.container-client-edit-menu {
  width: 100%;
  height: 100vh;
}
.wrapper-add-menu-client {
  width: 100%;
  background: #fafafa;
  margin-top: 20px;
  padding: 20px;
}
/* .wrapper-items-edit-menu-client {
  width: 100%;
} */

.items-row-edit-menu-client {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 40px;
}
.item-row-edit-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.item-row-edit-menu > span {
  font-size: 14px;
  font-weight: 400;
}


/* .item-row-add-menu-item {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
} */
/* .container-row-add-menu-item {
  display: flex;
  flex-direction: column;
} */
/* .container-row-add-menu-item > span {
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 8px;
} */
/* .container-row-add-menu-item > input {
  border: 1.5px solid #d3c9c9;
  background: #fbfbfb;
  border-radius: 4px;
  width: 389.5px;
  height: 40px;
  padding-left: 10px;
} */
.container__upload-image-client-menu {
  margin-block: 20px;
  display: flex;
}

.container__upload-image-client-edit-menu {
  margin-block: 20px;
  display: flex;
  justify-content: flex-start;
}
.container__upload-image-client-edit-menu > img {
  border-radius: 50%;
}

.wrapper__img-client-edit-menu {
  display: flex;
}
.wrapper__img-client-edit-menu > img {
  border-radius: 50%;
}

.wrapper__upload-image-client-menu {
  text-align: center;
  width: 400px;
  height: 190px;
  background: rgba(211, 226, 255, 0.1);
  border: 1px dashed #9e9e9e;
  border-radius: 8px;
}
.box__input-image-client-menu {
  padding-block: 10px;
  position: relative;
}
.box__input-image-client-menu > span {
  font-size: 20px;
  font-weight: 400;
  color: #616161;
}
.box__input-file-client-menu {
  font-size: 16px;
  font-weight: 500;
  color: #c2c2c2;
}
.box__input-or-client-menu {
  font-size: 20px;
  font-weight: 500;
  color: #616161;
  padding-bottom: 16px;
}
.container__input-image-client-menu {
  position: absolute;
  top: 10px;
  left: 250px;
  z-index: 1;
}
.box__input-button-client-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box__input-button-client-menu > button {
  width: 126px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
}
.button-item-add-menu-item-client-menu {
  display: flex;
  column-gap: 10px;
}
.button__bottom-main-client-menu > button {
  background-color: #1571de;
  width: 70px;
  height: 40px;
}
.button__bottom-main-client-menu > span {
  text-transform: none;
  color: white;
}
.button__cancel-main-client-menu > button {
  width: 75px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
}
.button__bottom-main-client-menu {
  display: flex;
  align-items: center;
}

.wrapper-upload-done {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-upload-done {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .items-row-edit-menu-client {
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  }
}


@media (max-width: 1024px) {
  .item-row-add-menu-item {
    column-gap: 20px;
    row-gap: 10px;
  }
}
