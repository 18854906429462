@font-face {
  font-family: arlon;
  src: url(../../../../assets//font/arlon/ArlonSemiBold-DEMO.otf)
    format("truetype");
}

.container-employee-report {
  width: 100%;
  height: 100vh;
}
.wrapper-employee-report {
  padding-inline: 32px;
  padding-block: 12px;
}
.item-top-employee-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-left-employee-report {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex: 1;
  cursor: pointer;
}
.item-left-employee-report > span {
  font-family: arlon;
  font-size: 16px;
}
.item-mid-employee-report {
  flex: 1;
  display: flex;
  justify-content: center;
}

.item-mid-employee-report > span {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
}
.item-right-employee-report {
  flex: 1;
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
}
.item-right-employee-report {
  display: flex;
  column-gap: 20px;
}
.item-right-employee-report > span {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}
.item-right-employee-report > button {
  text-transform: none;
}
.date-picker-shown-employee-report {
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  /* background-color: red; */
  /* align-items: center; */
  /* width: 300px; */
}
.wrapper-date-picker-shown-employee-report {
  display: flex;
  /* background-color: red; */
  padding-top: 40px;
  align-items: center;
  column-gap: 20px;
}

.company-employee-report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    /* background-color: red; */
    /* width: 500px; */
    padding-top: 24px;
    padding-bottom: 10px;
  }
  .title-company-employee-report {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 354px;
  }
  .title-company-employee-report > label {
    font-size: 12px;
    font-weight: 500;
    color: #616161;
    text-align: center;
  }

.desc-item-employee-report {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  padding-top: 24px;
}
.mini-item-left-employee-report {
  width: 152px;
  height: 66px;

  display: flex;
  flex-direction: column;

  row-gap: 5px;
}
.mini-item-left-employee-report > label {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}
.mini-item-left-employee-report > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}
.mini-item-middle-employee-report {
  width: 152px;
  height: 66px;
  display: flex;

  flex-direction: column;
  row-gap: 5px;
}
.mini-item-middle-employee-report > label {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}
.mini-item-middle-employee-report > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}
.mini-item-right-employee-report {
  width: 180px;
  height: 66px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.mini-item-right-employee-report > label {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}
.mini-item-right-employee-report > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.table-employee-report {
    padding-top: 44px;
}
