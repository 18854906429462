.container-payslip-detail {
  width: 100%;
  height: 100vh;
}

.wrapper-payslip-detail {
  padding-top: 32px;
}
.item-top-payslip-detail-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}
.payslip-detail-left {
  display: flex;
  flex: 1;
}

.desc-payslip-left {
  display: flex;
  width: 366px;
  height: 87px;
}
.text-item-detail-left {
  padding-left: 24px;
}
.text-item-detail-left > h1 {
  font-size: 24px;
  font-weight: 500;
  color: #0a0a0a;
}
.text-item-detail-left > span {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
}
.text-item-detail-left > h3 {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
  padding-top: 5px;
}
.payslip-detail-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.payslip-detail-item {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}
.payslip-detail-item > label {
  font-size: 16px;
  font-weight: 500;
  color: #757575;
}
.payslip-detail-item > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}
.container-payment-history {
  padding-top: 24px;
}
.wrapper-payment-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-payment-history > span {
  font-size: 20px;
  font-weight: 500;
  color: #404040;
}

.rangepicker-payment {
  width: 110px;
  height: 37px;
}
.item-payment-years {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 78px;
  row-gap: 20px;
}
.wrapper-item-payment-years {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.card-item-payment {
  width: 144px;
  height: 120px;
  background: #f3f5f7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .card-item-payment > img {
    width: 100%;
    height: auto;
} */
.card-item-text {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.card-item-text > label {
    font-size: 16px;
    font-weight: 400;
    color: #0A0A0A;
}
/* .card-item-text > button {
    font-size: 16px;
    font-weight: 500;
    color: #1571DE;
    text
} */