@font-face {
  font-family: arlon;
  src: url(../../../assets/font/arlon/ArlonSemiBold-DEMO.otf) format("truetype");
}

.Body {
  width: 100%;
  height: 100%;
}

.Header {
  display: flex;
  padding: 22px 30px 22px 48px;
  align-items: center;
  gap: 20px;
  position: sticky;
  top: 0;
  z-index: 10;

  border-bottom: 1px solid #d9d9d9;
  background: #fff;

  img {
    width: 45px;
    height: 31px;
  }
  span {
    font-family: arlon;
    font-size: 16px;
    font-weight: 700;
  }
}

.Content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 41px;
  padding-inline: 221px;
  gap: 24px;

  .Heading {
    width: 100%;
    span {
      font-size: 32px;
      font-weight: 600;
      color: #0a0a0a;
    }
  }

  .Paragraph {
    width: 100%;
    span {
      color: #404040;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .Chapter {
    width: 100%;
    // padding-inline: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #404040;
    }

    .WrapperChapter {
      display: flex;
      flex-direction: column;

      label {
        color: #404040;
        font-size: 14px;
        font-weight: 400;
      }

      .SubDesc {
        padding-top: 24px;
        span {
          color: #404040;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .HeadDesc {
        padding-bottom: 24px;
        span {
          color: #404040;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    ul {
      padding-inline: 15px;
      li {
        color: #404040;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .MoreInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
      color: #404040;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .Footer {
    width: 100%;
    span {
      color: #404040;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

@media (width <= 1024px) {
  .Content {
    .MoreInfo {
      gap: 5px;
    }
  }
}

@media (width <= 768px) {
  .Content {
    padding-inline: 50px;
  }
}

@media (width <= 400px) {
  .Content {
    .Heading {
      span {
        font-size: 24px;
      }
    }
  }
}
