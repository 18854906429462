.Container {
  width: 100%;
  height: 100vh;
}

.Header {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  margin-bottom: 25px;
}

.Searchbar {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.AddButton {
  width: 139px !important;
  height: 40px !important;
  background: #1571de !important;
  border-radius: 8px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff !important;
  text-transform: capitalize !important;
}

.SettingMenuLeft {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.WrapperCard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 15px;
  row-gap: 15px;
}

.Card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 16px;
  gap: 16px;
  min-height: 184px;
  /* row-gap: 8px; */
}

.CardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  /* margin-bottom: 16px; */
}

.CardHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(208, 227, 248, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #1571de;
  font-size: 20px;
}

.CardDetail {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.CardDetail > span:first-child {
  color: #9e9e9e;
}

.CardHeaderTitle {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.CardFooter {
  margin-top: auto;
}

.CardBody {
  display: flex;
}

.CardBody > span {
  color: #9e9e9e;
  font-weight: 400;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CardFooterSetting {
  display: flex;
  align-items: center;
  color: #1571de;
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  /* padding-top: auto; */
}
