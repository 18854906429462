.loan__details-container {
  padding-top: 32px;
}
.loan__details-profile {
  display: flex;
}

/* .loan__details-desc {
  padding: 20px;
} */

.details__profile-desc {
  flex: 1;
  display: flex;
  column-gap: 10px;
  align-items: center;
  /* background-color: blue; */
}

.details__profile-desc > img {
  width: 79px;
  height: 79px;
}

.details__data {
  display: flex;
  flex-direction: column;
}

.details__data > label {
  color: #0a0a0a;
  font-size: 24px;
  font-weight: 500;
}

.details__data > h6 {
  color: #757575;
  font-size: 20px;
  font-weight: 500;
}

.details__data > span {
  color: #404040;
  font-size: 20px;
  font-weight: 500;
}
.details__status {
  flex: 1;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loan__box-status {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 103px;
  height: 38px;
  background: #498e0f;
  border-radius: 50px;
}

.loan__box-status > span {
  color: #fafafa;
}

.loan_details-desc-box {
  padding-top: 24px;
}

.label__loan-text > span {
  color: #0a0a0a;
  font-weight: 500;
  font-size: 20px;
}

.details__loan-box {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 8px;
}

.details__loan-info {
  display: flex;
  align-items: center;
}

.details__loan-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.details__loan-text > label {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 500;
}

.details__loan-text > span:nth-child(2) {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
}

.details__loan-text > span:nth-child(3) {
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.details__loan-text > h5 {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
}

.details__loan-img {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 120px;
}

.details__submission {
  padding-top: 20px;
}

.wrapper__date-submission {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date__submission {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 250px;
  /* background-color: blue; */
}

.date__submission > label {
  color: #616161;
  font-size: 18px;
  font-weight: 500;
}

.date__submission > span {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 400;
}

.details__history {
  padding-top: 24px;
}

.items__detail-history {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.item__payment-history {
  width: 186px;
  height: 116px;
  background: #fafafa;
  border-radius: 8px;
  padding: 10px;
}

.item__payment-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 186px;
  height: 116px;
}

.item__payment-wrapper > label {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.item__payment-wrapper > span {
  color: #404040;
  font-size: 20px;
  font-weight: 500;
}
.items__payment-icon {
  color: #255bfc;
  font-size: 14px;
  font-weight: 500;
}

.button-helper-loan-data-details {
  padding-top: 40px;
}

.button-helper-loan-data-details > button {
  text-transform: none;
  width: 127px;
  height: 40px;
  border-radius: 4px;
  color: white;
}
