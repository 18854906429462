.Body {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.Address {
  font-size: 13px;
  font-weight: 400;
  color: "#404040";
  text-transform: capitalize;
}

.Address > p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
