.ButtonPrimary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;
  background: var(--Primary-Main, #1571de);
  cursor: pointer;
  &.ButtonPrimaryDisabled {
    border-radius: 8px;
    border: 1px solid var(--Neutral-50, #c2c2c2);
    background: var(--Neutral-40, #e0e0e0);
    > span {
      color: var(--Neutral-60, #9e9e9e);
    }
    &:hover {
      background-color: #e0e0e0;
      cursor: not-allowed;
    }
  }

  > div {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > span {
    color: #fff;

    /* Text-M/medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  &:hover {
    background-color: #125eb9;
  }
}

.ButtonPrimaryOutlined {
  border-radius: 8px;
  border: 1px solid var(--Primary-Main, #1571de);
  background: var(--Neutral-10, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  &.ButtonPrimaryOutlinedDisabled {
    // border-radius: 8px;
    border: 1px solid var(--Neutral-50, #c2c2c2);
    background: var(--Neutral-40, #e0e0e0);
    cursor: not-allowed;
    > span {
      color: var(--Neutral-60, #9e9e9e);
    }
    > div {
      .icon {
        color: #9e9e9e;
      }
    }
    &:hover {
      border: 1px solid #c2c2c2;
      > div {
        .icon {
          color: #9e9e9e;
        }
      }
      > span {
        color: #9e9e9e;
      }
    }
  }

  > div {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      color: var(--Primary-Main, #1571de);
    }
  }

  > span {
    color: var(--Primary-Main, #1571de);

    /* Text-M/medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  &:hover {
    border: 1px solid #125eb9;
    background-color: #f5f5f5;
    > div {
      .icon {
        color: #125eb9;
      }
    }
    > span {
      color: #125eb9;
    }
  }
}

.ButtonDangerOutlined {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--Danger-Main, #d42701);
  background: var(--Neutral-10, #fff);
  cursor: pointer;
  &.ButtonDangerOutlinedDisabled {
    // border-radius: 8px;
    border: 1px solid var(--Neutral-50, #c2c2c2);
    background: var(--Neutral-40, #e0e0e0);
    cursor: not-allowed;
    > span {
      color: var(--Neutral-60, #9e9e9e);
    }
    > div {
      .icon {
        color: #9e9e9e;
      }
    }
    &:hover {
      border: 1px solid #c2c2c2;
      > div {
        .icon {
          color: #9e9e9e;
        }
      }
      > span {
        color: #9e9e9e;
      }
    }
  }
  > div {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      color: #d42701;
    }
  }

  > span {
    color: var(--Danger-Main, #d42701);

    /* Text-M/medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  &:hover {
    border: 1px solid #98280e;
    > div {
      .icon {
        color: #98280e;
      }
    }
    > span {
      color: #98280e;
    }
  }
}
.ButtonPrimaryOutlined {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #1571DE;
  background: var(--Neutral-10, #fff);
  cursor: pointer;
  &.ButtonDangerOutlinedDisabled {
    // border-radius: 8px;
    border: 1px solid var(--Neutral-50, #c2c2c2);
    background: var(--Neutral-40, #e0e0e0);
    cursor: not-allowed;
    > span {
      color: var(--Neutral-60, #9e9e9e);
    }
    > div {
      .icon {
        color: #9e9e9e;
      }
    }
    &:hover {
      border: 1px solid #c2c2c2;
      > div {
        .icon {
          color: #9e9e9e;
        }
      }
      > span {
        color: #9e9e9e;
      }
    }
  }
  > div {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      color: #1571DE;
    }
  }

  > span {
    color: var(--Danger-Main, #1571DE);

    /* Text-M/medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  &:hover {
    border: 1px solid #3980d7;
    > div {
      .icon {
        color: #418ce7;
      }
    }
    > span {
      color: #3f87df;
    }
  }
}
