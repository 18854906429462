.container {
  height: calc(100vh - 130px);
  overflow: auto;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    background: linear-gradient(91deg, #1571DE 0.08%, #328EFA 99.92%);
    border-radius: 16px;
    color: #fff;
    display: flex;
    align-items: flex-end;
    gap: 8px;

    .left {
      overflow: clip;
      display: flex;
      flex-direction: column;
      gap: 3px;
      position: relative;
      height: 120px;
      padding: 27px 29px;
      flex-grow: 1;


      >h3 {
        font-size: 32px;
        line-height: 125%;
        font-weight: 700;
      }

      .dot {
        position: absolute;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: white;
        opacity: .2;
        filter: blur(5px);

        &.d1 {
          width: 173px;
          top: 10%;
          left: -3%;
        }

        &.d2 {
          width: 107px;
          right: 20%;
          top: -50%;
        }

        &.d3 {
          width: 140px;
          right: 1%;
          bottom: -50%;
        }
      }
    }

    .right {
      height: 120px;
      padding: 27px 29px;
      position: relative;
      overflow: visible;
      width: 200px;

      >img {
        position: absolute;
        bottom: 0;
        right: 29px;
        width: 171px;
        object-fit: contain;
      }


    }


  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .actions {
      display: flex;
      align-items: center;
      gap: 8px;

      >h4 {
        font-size: 20px;
        line-height: 130%;
        font-weight: 500;
        flex-grow: 1;
      }
    }

    .main {

      .rowActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        >button {
          background-color: transparent;
          outline: none;
          border: none;
        }
      }
    }
  }
}