.client-user-settings-menu {
  width: 100%;
  height: 100%;
}
.wrapper-user-settings-menu {
  /* width: 943px; */
  height: auto;
  background: #fafafa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  position: relative;
}
.title-user-settings-menu {
  padding-bottom: 10px;
}
.title-user-settings-menu > span {
  font-size: 24px;
  font-weight: 500;
  color: #404040;
}
.wrapper-items-user-menu-settings {
  padding-inline: 150px;
}
.container-client-items-menu-settings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.button-user-settings {
  position: absolute;
  right: 15px;
  bottom: 10px;
}
.button-user-settings > button {
  width: 59px;
  height: 40px;
  background: #1571de;
  border-radius: 4px;
  color: white;
}
@media (max-width: 1024px) {
  .wrapper-items-user-menu-settings {
    padding-inline: 0px;
  }
}
