
.TableWrapper {
    display: flex;
    flex-direction: column;
}

.TableContainer {
    min-width: 600;
    background-color: #ffffff;
    border-radius: 5px 5px 0px 0px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: 1px solid #E0E0E0;
    /* overflow: hidden; */
    border-bottom: 0;
}

.Table {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
}

.CenterEmptyDataWrapper{
    display: flex;
    width: 100%;
    padding: 24px;
    border: 1px solid #D9D9D9;
    background-color: #FFFFFF;
}

.EmptyDataWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    padding-top: 12px;
}