.Container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
  
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .Header > button {
    text-transform: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
  
    border-radius: 4px !important;
    background:#1571de !important;
    color: #ffffff !important;
    height: 40px;
  }
  
  .TableWrapper {
    padding-top: 24px;
  }
  
  .TableContainer {
    min-width: 600;
    background-color: "#ffffff";
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    border-bottom: 0;
  }
  
  .Table {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
  }
  
  .Published {
    background-color: #F4FFF2;
    color: #22970F !important;
    border: 1px solid #22970F;
    padding: 2px 5px;
    border-radius: 5px;
  }
  
  .Draft {
    background-color: #DEFEFF;
    color: #1571DE !important;
    border: 1px solid #1571DE;
    padding: 2px 5px;
    border-radius: 5px;
  }
  
  .Loading {
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .LoadingWidth {
    width: 200px;
  }
  
  .ClientCell >  button {
    background-color: #1571DE;
    padding: 0;
    width: 40px;
    height: 28px;
    font-size: 14px !important;
    color: #fff;
    border-radius: 20px;
    margin-left: 10px;
  }
  
  .PopoverDiv {
    width: 200px;
    padding: 8px;
  }
  
  .PopoverDiv  > span {
    font-size: 12px;
    color: #0a0a0a;
  }
  
  .Plus {
    cursor: pointer;
    color: #1571DE;
    margin-left: 12px;
    font-size: 12px!important;
  }
  