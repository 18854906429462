@font-face {
  font-family: arlon;
  src: url(../../../../assets//font/arlon/ArlonSemiBold-DEMO.otf)
    format("truetype");
}

.container-bpjs-report {
  width: 100%;
  height: 100vh;
}
.wrapper-bpjs-report {
  padding-inline: 32px;
  padding-block: 12px;
}

.item-top-bpjs-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-left-bpjs-report {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  flex: 1;
}
.item-left-bpjs-report > span {
  font-family: arlon;
  font-size: 16px;
}
.item-mid-bpjs-report {
  flex: 1;
  display: flex;
  justify-content: center;
}

.item-mid-bpjs-report > span {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
}
.item-right-bpjs-report {
  flex: 1;
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
}
.item-right-bpjs-report {
  display: flex;
  column-gap: 20px;
}
.item-right-bpjs-report > span {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}
.item-right-bpjs-report > button {
  text-transform: none;
}
.datepicker-bpjs-report {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}
.company-bpjs-report {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  /* background-color: red; */
  /* width: 500px; */
  padding-top: 24px;
  padding-bottom: 24px;
}
.title-company-bpjs-report {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  /* text-align: center; */
  /* background-color: red; */
  width: 354px;
  /* height: 40px; */
}
.title-company-bpjs-report > label {
  font-size: 12px;
  font-weight: 500;
  color: #616161;
  text-align: center;
}
.desc-item-bpjs-report {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  padding-top: 24px;
}

.mini-item-left-bpjs {
    width: 152px;
    height: 66px;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    /* padding-top: 10px; */
    row-gap: 10px;
  }
  .mini-item-left-bpjs > label {
    font-size: 16px;
    font-weight: 400;
    color: #0a0a0a;
  }
  .mini-item-left-bpjs > span {
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
  }
  .mini-item-middle-bpjs {
    width: 152px;
    height: 66px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* padding-top: 10px; */
    row-gap: 10px;

    /* background-color: blue; */
  }
  .mini-item-middle-bpjs > label {
    font-size: 16px;
    font-weight: 400;
    color: #0a0a0a;
  }
  .mini-item-middle-bpjs > span {
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
  }
