.container__mini-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-inline: 30px;
}

.container__mini-screen > h1 {
  font-size: 20px;
  font-weight: 400;
}
