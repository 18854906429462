.SidebarLinkFMCG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  list-style: none;
  /* height: 20px; */
  text-decoration: none;
  transition-duration: 0.5s;
  z-index: 999;
  position: relative;
  background-color: white;
}

.WrapperSubNavItem {
  position: relative;
}

.Wrapper {
  border-left: 2px solid #DBDBDB;
  margin-left: 40px;
  transform: translateY(-19px);
  /* zIndex: 1, */
  padding-bottom: 2px;
  transition: 0.2;
}

.WrapperSubNavItem {
  transform: translateY(19px);
}

.WrapperSubNavItem > hr {
  position: absolute;
  width: 10px;
  border: "1px solid #DBDBDB";
  top: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.SidebarLabelFMCG {
  font-size: 12px;
  font-weight: 500;
}
.BorderLeftItem {
  border-left: 2px solid #dbdbdb;
  margin-left: 40px;
  /* height: calc(200px - 50px); */
}

.DropdownLinkFMCG {
  height: 60px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
}



.SubnavItemInnerFMCG {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  transition: color 0.3s ease 0;
  column-gap: 10px;
}

.SubnavItemInnerFMCG > i {
  font-size: 18px;
  font-weight: 500;
}

.SubnavItemInnerFMCG > span {
  padding-top: 2px;
  font-size: 16px;
  font-weight: 500;
}

.SubnavItemInnerFMCG.active {
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 25px;
  background-color: #6CABF8;
  /* background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  ); */
  color: var(--txt-white);
}
