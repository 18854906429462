.container-data-payslip {
  padding-top: 32px;
}

.data-payslip-divider {
  color: #c2c2c2;
}
.item-main-data-payslip > span {
  color: #0a0a0a;
  font-size: 20px;
  font-weight: 500;
}
.text-main-data-payslip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
}

.text-main-data-payslip > span {
  color: #616161;
  font-size: 24px;
  font-weight: 500;
}

.item-main-data-payslip {
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-right: 150px;
}

.wrapper-item-main {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.wrapper-item-main > label {
  color: #616161;
  font-size: 16px;
  font-weight: 500;
}

.wrapper-item-main > span {
  color: #0a0a0a;
  font-size: 18px;
  font-weight: 500;
}

.wrapper-item-justify {
  display: flex;
  justify-content: space-between;
}

.item-justify > span {
  color: #0a0a0a;
  font-size: 18px;
  font-weight: 400;
}

.button-helper-payslip-data {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-helper-payslip-data > button {
  text-transform: none;
  width: 127px;
  height: 40px;
  border-radius: 4px;
  color: white;
}
