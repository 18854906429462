.averageSA-container {
  width: 100%;
  height: auto;
}
.wrapper__averageSA {
  background-color: #fafafa;
  height: 200px;
  /* width: 943px; */
  padding: 10px;
}
.title__averageSA {
  padding-bottom: 20px;
}
.title__averageSA > span {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}

.wrapper__items-averageSA {
  display: flex;
  justify-content: space-between;
}
.box__items-averageSA {
  width: 285px;
  height: 108px;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px;
}
.title__items-averageSA {
  display: flex;
}
.title__items-averageSA > span {
  font-size: 14px;
  font-weight: 400;
  color: #0a0a0a;
  padding-left: 10px;
  padding-bottom: 15px;
}

.box__blue {
  width: 20px;
  height: 20px;
  background-color: #1571de;
  border-radius: 4px;
}
.box__green {
  background-color: #498e0f;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.box__red{
  background-color: #D1421A;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.text__inside-averageSA {
  /* display: flex;
     */
}
.text__inside-averageSA > label {
  font-size: 20px;
  font-weight: 400;
  color: #616161;
}
.text__sub-inside {
  display: flex;
  align-items: center;
}
.text__sub-inside > span {
  font-size: 14px;
  font-weight: 400;
  color: #1b45d9;
  padding-right: 10px;
}
.text__sub-inside > label {
  font-size: 14px;
  font-weight: 400;
  color: #9e9e9e;
}

@media (max-width: 1024px) {
  .wrapper__averageSA {
    height: 300px;
  }
  .wrapper__items-averageSA {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
