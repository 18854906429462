.Container {
    padding: 20px;
    margin: 5px 8px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.Wrapper>span {
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    display: block;
    margin-bottom: 20px;
    width: 100%;
}

.BodyWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

}

.BodyWrapper>span:first-child {
    width: 20%
}

.BodyWrapper>span {
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    display: block;
    width: 100%;
}

.BoxForm {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    row-gap: 10px
}

.BoxForm>span:first-child {
    width: 100%;
    font-size: 14px;
    color: #404040;
    font-weight: 400;
    text-align: start;
}

.BoxForm>span {
    font-size: 14px;
    width: 100%;
    text-align: end;
    font-weight: 500;
    color: #0a0a0a;
}

.BoxForm2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    row-gap: 10px
}

.BoxForm2>span:first-child {
    font-size: 14px;
    color: #404040;
    font-weight: 400;
    text-align: start;
}

.Desc {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 10px;
    row-gap: 10px;
}

.Desc>span:first-child {
    width: 100%;
    font-size: 14px;
    color: #404040;
    font-weight: 400;
    text-align: start;
}

.Desc>span:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    text-align: justify;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #0a0a0a;
}

.ShiftWrapper>span {
    color: #1571de;
    font-size: 14px;
}

.BoxInput {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.BoxInput>input {
    width: 42px;
    height: 36px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.BoxInput>input[readonly] {
    width: 42px;
    height: 28px !important;
    background: #d9d9d9;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
}

.BoxInput>input:disabled {
    width: 42px;
    height: 36px;
    background: #e0e0e0;
    border: 1px solid #e0e0e0;
    color: #9e9e9e;
    border-radius: 8px;
}

.BoxInputWrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.BoxInputWrapper>span {
    font-size: 14px;
    color: #0a0a0a;
}

.ClockIcon {
    position: absolute;
    margin-left: 40px;
}

.ClockIcon2 {
    position: absolute;
    margin-left: 50px;
}

.Duration {
    width: 70px !important;
    padding-left: 10px;
}

.Duration2 {
    width: 80px !important;
    padding-left: 10px;
}

.OvertimeWrapper {
    font-size: 14px;
    color: #404040;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
}

.CutOff {
    padding: 5px;
    width: 28px !important;
    height: 28px !important;
    text-align: center;
    background: #d9d9d9;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
}