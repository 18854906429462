.Container {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    gap: 24px;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ProfileWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.TextProfileWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.TextProfileWrapper>span:first-child{
    font-weight: 500;
    font-size: 24px;
    color: #0A0A0A;
}

.TextProfileWrapper>span:nth-child(2){
    font-weight: 500;
    font-size: 12px;
    color: #757575;
}

.TextProfileWrapper>span:nth-child(3){
    font-weight: 500;
    font-size: 16px;
    color: #404040;
    padding-top: 8px;
}

.TextInformationWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.TextInformationWrapper>span:first-child{
    font-weight: 500;
    font-size: 16px;
    color: #0A0A0A;
}

.TextInformationWrapper>span:nth-child(2){
    font-weight: 400;
    font-size: 12px;
    color: #757575;
    padding-top: 4px;
}

.TextInformationWrapper>span:nth-child(3){
    font-weight: 400;
    font-size: 14px;
    color: #404040;
    padding-top: 8px;
}

.Body{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.Content{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.TitleContent>span{
    font-size: 20px;
    color: #0A0A0A;
    font-weight: 500;
    line-height: 26px;
}

.Card {
    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 16px 24px;
    gap: 24px;
}

.InformationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.CardContentWrapper{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 24px;
}

.CardContentInstallmentWrapper{
    display: grid;
    grid-template-columns: auto auto auto auto;
    row-gap: 24px;
    column-gap: 14px;
}

.CardContent {
    display: flex;
    flex-direction: column;
    gap: 14px;
    text-align: left;
}

.CardContent>span:first-child{
    color: #616161;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.CardContent>span:nth-child(2){
    color: #0A0A0A;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
}

.PaymentStatusPaidText{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    color: #255BFC;
    font-weight: 500;
    font-size: 14px;
}

.PaymentStatusNotPaidText{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    color: #D1421A;
    font-weight: 500;
    font-size: 14px;
}

.Empty{
    color: #616161;
    font-size: 14px;
    font-weight: 500;
}

.APPROVED {
    background-color: #22970F;
    color: #FFFFFF;
    border: 1px solid #22970F;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 50px;
}

.FINISHED {
    background-color: #1571DE;
    color: #FFFFFF;
    border: 1px solid #1571DE;
    padding: 8px;
    font-size: 14px;
    border-radius: 50px;
}

.PENDING {
    background-color: #E9B62F;
    color: #FFFFFF;
    border: 1px solid #E9B62F;
    padding: 8px;
    font-size: 14px;
    border-radius: 50px;
}

.REJECTED {
    background-color: #D42701;
    color: #FFFFFF;
    border: 1px solid #D42701;
    padding: 8px;
    border-radius: 50px;
    font-size: 14px;
}

.Divider{
    background-color: #C2C2C2;
    height: 1px;
    width: 100%;
}

.RoundIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F5F7;
    border-radius: 50px;
    color: #1571DE;
    padding: 8px;
    width: 40px;
    height: 40px;
}

.ViewButton{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1571DE;
    cursor: pointer;
}

.LeftBill{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.TextBillWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.TextBillWrapper>span:first-child{
    color: #000000;
    font-weight: 400;
    font-size: 14px;
}

.TextBillWrapper>span:last-child{
    color: #1571DE;
    font-weight: 500;
    font-size: 13px;
}

.BillWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}