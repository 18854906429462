.container-ACIEL {
  width: 100%;
  height: 100vh;
}

.wrapper-items-selecting-loan {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;
}

.items-box-selecting {
  width: 298px;
  height: 170px;
  background: #fafafa;
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 8px;
}

.item-header-selecting {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-header-selecting > span {
  font-size: 18px;
  font-weight: 500;
  color: #404040;
}

.item-main-selecting {
  display: flex;
  width: 150px;
}

.item-main-selecting >span {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.item-footer-selecting {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding-top: 30px;
}
.item-footer-selecting > button {
  display: flex;
  align-items: center !important;
  text-transform: none;
  margin-top: auto;
  justify-content: space-between;
  cursor: pointer;
}

.wrapper-ACIEL {
  padding-top: 37px;
}
.title-ACIEL {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.items-ACIEL {
  padding-top: 24px;
}

.items-row-ACIEL {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;
}

.box-settings-ACIEL {
  width: 330px;
  height: auto;
  background: #fafafa;
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 8px;
  row-gap: 8px;
  display: flex;
  flex-direction: column;
}

.item-ACIEL-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 3rem; */
}

.item-ACIEL-top > span {
  font-size: 18px;
  font-weight: 500;
  color: #404040;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap;  */
  height: inherit;
  overflow: hidden;
  line-height: 1.5rem;
}

.item-ACIEL-main {
  display: flex;
  /* width: 150px; */
}

.item-ACIEL-main > span {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.button-bottom-ACIEL {
  padding-top: 56px;
}
