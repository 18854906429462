.container-help-center {
  width: 100%;
  height: 100vh;
}
.box-item-help-center {
  display: flex;
}

.title-help-center {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  /* padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px; */

}
.title-help-center > span {
  font-size: 20px;
  font-weight: 500;
  color: #404040;
}

.title-help-center > label {
  font-size: 24px;
  font-weight: 500;
  color: #616161;
}
.accordion-box {
  padding-top: 12px;
}
.wrapper-item-accordion {
  display: flex;
  flex-direction: column;
  background-color: #fafafa
}
