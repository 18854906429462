.LayoutFMCG_content {
  padding-left: 281px;
  height: 100vh;
}

.LayoutFMCG_content-main {
  padding: 20px;
  background-color: #fafafafa;
}

.mini__screen-layout {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .LayoutFMCG_content {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .LayoutFMCG_content {
    padding-left: 0;
  }
}

@media screen and (max-width: 1000px) {
  .LayoutFMCG {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .mini__screen-layout {
    display: none;
  }
}

/* @media (max-width: 1024px) {
    .LayoutFMCG_content {
      padding-left: 0;
    }
  } */

/* @media (min-width: 1024px) {
    .LayoutFMCG_content {
      padding-left: 0;
    }
  } */
