.Container {
  width: 100%;
  min-height: 100vh;
}

.Wrapper {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 10px;
}

/* .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    gap: 10px;
} */

.Searchbar {
  position: relative;
  height: 40px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.AutoComplete {
  width: 210px;
  background-color: var(--main-bg);
}

.SubHeader {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.EditIndicator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.RequestEdit {
  display: flex;
  align-items: center;
  gap: 8px;
}

.RequestEdit>div {
    background-color: #FFF9E9;
    border: 1px solid #E9B62F;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.RequestEdit span {
    font-weight: 400;
    font-size: 14px!important;
    line-height: 20px;
    color: #757575!important;
}


.Resign {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Resign>div {
    background: #FFF4F2;
    border: 1px solid #D52701;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.Resign span {
    font-weight: 400;
    font-size: 14px!important;
    line-height: 20px;
    color: #757575!important;
}

/* .EditIndicator>div {
    background-color: #FFF9E9;
    border: 1px solid #E9B62F;
    width: 20px;
    height: 20px;
    border-radius: 50%;
} */

/* .EditIndicator span {
    font-weight: 400;
    font-size: 14px!important;
    line-height: 20px;
    color: #757575!important;
} */

.SubHeader span {
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}

.Avatar {
  background: #404040 !important;
  font-size: 14px !important;
  width: 30px !important;
  height: 30px !important;
}

.TableWrapper {
  margin-top: 16px;
  /* background-color: #fafafa; */
}

.TableContainer {
  min-width: 600px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  border-bottom: 0;
}

.Table {
  font-size: 20px !important;
}

.EditIcon {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.TablePagination {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

.SelectBox {
  height: 40px;
}

.Select {
  height: 40px;
  border-radius: 8px !important;
  min-width: 132px;
  background-color: var(--main-bg);
}
