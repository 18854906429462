.container-add-clients-id-menu {
  width: 100%;
  height: 100vh;
}

.wrapper-add-client-id-menu {
  padding-top: 32px;
}

.title-add-client-id-menu {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.title-add-client-id-menu > span {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.title-add-client-id-menu > input {
  width: 286px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 8px;
  padding-left: 5px;
}

.info-add-client-id-menu {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 24px;
  padding-top: 24px;
}

.box-settings-add-clients-id-menu {
  width: 330px;
  height: auto;
  background: #fafafa;
  border-radius: 8px;
  padding-inline: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  row-gap: 8px;
  display: flex;
  flex-direction: column;
}

.item-ADI-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-ADI-top > span {
  font-size: 18px;
  font-weight: 500;
  color: #404040;
}

.item-ADI-main {
  display: flex;
  width: 150px;
}

.item-ADI-main > span {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.item-ADI-status {
  font-size: 14px;
  font-weight: 500;
  color: #d1421a;
}

.item-ADI-footer {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-top: auto;
}

.item-ADI-footer > button {
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
  background: #fafafa;
}

.button-bottom-ADI {
  padding-top: 32px;
  display: flex;
  column-gap: 12px;
  justify-content: flex-end;
}
.button-bottom-ADI > button {
  text-transform: none;
}

.btn-ADI-add {
  width: 97px;
  height: 40px;
  background: #1571de;
  border-radius: 4px;
}

@media (max-width: 1024px) {
  .info-add-client-id-menu {
    column-gap: 55px;
  }
}
