.container {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;

  input {
    width: 100%;
    font-size: 18px !important;
    font-weight: 600;
    text-align: center;
    border-radius: 8px;
    padding: 12px;
    height: 62px !important;
    border: 1px solid #D9D9D9;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    flex: 1;

    &:focus {
      outline: none !important;
      border-color: #1571DE;
      border-width: 1px;
      border-style: solid;
    }

    &.alert {
      border-color: salmon !important;
      -webkit-box-shadow: 0 0 5px salmon !important;
      -moz-box-shadow: 0 0 5px salmon !important;
      box-shadow: 0 0 5px salmon !important;
    }
  }

  .line {
    width: 30px;
    border-top: 1px solid #1571DE;
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .container {
    gap: 8px;

    input {
      height: 48px !important;
    }
  }
}
