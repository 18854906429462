.container-GT {
  width: 100%;
  height: 100vh;
}

.box-wrapper-GT {
  background: #fafafa;
  padding-left: 16px;
  padding-top: 24px;
}
.container-item-GT {
  display: flex;
  flex-direction: column;
}
.container-item-GT > span {
  font-size: 24px;
  font-weight: 500;
  color: #0a0a0a;
}

.container-display-settings {
  padding-left: 37px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.container-display-settings > span {
  font-size: 20px;
  font-weight: 400;
  color: #616161;
  font: unset;
}
.item-row-settings-GT {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  justify-content: space-between;
  padding-right: 62px;
}

.display-column-GT {
  display: flex;
  flex-wrap: wrap;
  width: 387px;
  height: 208px;
  background: #ffffff;
  padding-inline: 16px;
  padding-top: 16px;
}

.wrapper-box-column-GT {
  display: flex;
  column-gap: 10px;
}
.box-column-rec-GT {
  width: 96px;
  height: 146px;
  background: #f5f5f5;
  border-radius: 8px;
}
.title-wrapper-GT {
  padding-left: 160px;
}
.title-wrapper-GT > span {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a0a;
}
.display-list-GT {
  display: flex;
  /* flex-wrap: wrap; */
  width: 433px;
  height: 208px;
  background: #ffffff;
  padding-inline: 16px;
  padding-top: 16px;
}

.wrapper-box-list-GT {
  display: flex;
  flex-wrap: wrap;
  /* row-gap: 2px; */
}

.box-list-rec-GT {
  width: 359px;
  height: 42px;
  background: #f5f5f5;
  border-radius: 8px;
}
.container-switch-GT {
  padding-left: 32px;
  padding-right: 16px;
  padding-bottom: 167px;
}
.item-switch-GT {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
}
.title-wrapper-list-GT {
    padding-left: 150px;
}

.item-switch-GT > span {
  font-size: 20px;
  font-weight: 400;
  color: #616161;
}

@media only screen and (max-width: 1024px) {
    .item-row-settings-GT {
        row-gap: 20px;
    }
    .display-list-GT {
        width: 387px;
    }
    .box-list-rec-GT {
        width: 310px;
    }
    .title-wrapper-list-GT {
        padding-left: 135px;
    }
}
