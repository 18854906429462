.Background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.Container {
    width: 564px;
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
}

.CardHeader {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 24px;
}

.CardHeader img {
    width: 25px;
    height: 25px;
}

.CardHeader span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.FormContainer,
.FormControl {
    width: 100%;
}

.BoxFormWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px !important;
}

.BoxFormWrapper label {
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}
.BoxFormWrapper > span {
    font-size: 16px;
    font-weight: 600;
    color: 0A0A0A;
}

.SelectWrapper {
    width: 100%;
    position: relative;
}

.Select,
input, select {
    width: 100%;
    height: 40px;
    border-radius: 8px !important;
}

select {
    padding-inline: 10px;
}

input {
    border: 1px solid #d9d9d9;
    padding-inline: 10px;
}

.ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 8px;
}

.ButtonsWrapper button {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    text-transform: capitalize;
    font-size: 14px;
}