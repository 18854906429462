.item-top-client-dir {
  padding-top: 48px;
  display: flex;
  column-gap: 24px;
  align-items: center;
}

.wrapper-table-client-dir {
  padding-top: 48px;
}
.item-main-client-dir {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 16px;
}

.line-cross {
  width: 66px;
  height: 0;
  border: 2px solid #ededed;
  transform: rotate(-90deg);
}
.rec-item-client-dir {
  height: 90px;
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}
.rec-item-client-dir-left {
  /* flex: 1; */
  display: flex;
  width: 300px;
  align-items: center;
  column-gap: 8px;
  /* background-color: antiquewhite; */
}
.rec-item-client-dir-left > img {
  width: 56px;
  height: 56px;
}
.profile-desc-dir-left > label {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 500;
}
.profile-desc-dir-left > h3 {
  font-size: 12px;
  font-weight: 400;
  color: #404040;
}
.profile-desc-dir-left > span {
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
}

.rec-item-client-dir-left-2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: aliceblue; */
  /* justify-content: space-between; */
}
/* .wrapper-item-inside-client-dir-left-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
} */
.wrapper-item-CDM {
    display: flex;
    justify-content: space-between;
}
.rec-item-inside-left {
  display: flex;
  flex-direction: column;
  /* background-color: burlywood; */
}
.rec-item-product-division {
  width: 200px;
  /* background-color: blanchedalmond; */
  /* text-align: center; */
}

.rec-item-product-division > label {
  font-size: 14px;
  font-weight: 500;
  color: #616161;
}
/* .rec-item-inside-left > label {
  font-size: 14px;
  font-weight: 500;
  color: #616161;
} */
.rec-item-inside-left > span {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
}
/* .rec-item-inside-left > img {
    padding-top: 4px;
} */
.rec-item-inside-right {
  display: flex;
  flex-direction: column;
  /* background-color: blueviolet; */
  /* padding-top: 20px; */
  /* align-items: flex-end; */
}
.rec-item-inside-right > label {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
}

.rec-item-client-dir-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  /* background-color: aquamarine; */
}
.rec-item-client-dir-right > label {
  font-size: 14px;
  font-weight: 500;
  color: #616161;
}
.rec-item-inside-right > span {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
}

.rec-item-client-dir-right-2 {
  flex: 1;
  display: flex;
  /* background-color: blanchedalmond; */
}
.rec-item-client-dir-right-2 > span {
  font-size: 20px;
  font-weight: 500;
  color: #757575;
}

@media (max-width: 1024px) {
  .rec-item-client-dir-left {
    width: 200px;
  }
  /* .rec-item-client-dir-left-2 {

  } */
  .profile-desc-dir-left > span {
    font-size: 10px;
    font-weight: 400;
    color: #757575;
  }
  /* .rec-item-inside-right {
    padding-top: 42px;
  } */
  /* .rec-item-inside-left > label {
    font-size: 12px;
  } */
}
