@font-face {
  font-family: arlon;
  src: url(../../../../assets/font/arlon/ArlonSemiBold-DEMO.otf)
    format("truetype");
}

.store__report-container {
  width: 100%;
  height: 100vh;
}

.store__report-wrapper {
  padding-inline: 32px;
  padding-block: 12px;
}
.store__report-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store__report-item-left {
  display: flex;
  align-items: center;
  column-gap: 10px;
  /* flex: 1; */
  cursor: pointer;
}

.store__report-item-left > span {
  font-family: arlon;
  font-size: 16px;
}

.store__report-item > span {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
}

.store__report-date-picker {
  display: flex;
  justify-content: center;
}
.wrapper__date-picker {
  display: flex;
  padding-top: 40px;
  align-items: center;
  column-gap: 20px;
}

.store__report-company-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

.company__name-title {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 354px;
  text-align: center;
}

.company__name-title > label {
  font-size: 16px;
  font-weight: 500;
  color: #616161;
}

.company__name-title > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.store__report-featured {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 24px;
  padding-top: 24px;
}

.featured__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 286px;
  height: 109.34px;
  background: #f3f5f7;
  border-radius: 12px;
  padding: 16px;
}

.featured__item > label {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.featured__item > span {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

.store__report-container-table {
    padding-top: 50px;
}
