.not-found-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.not-found-text-container {
  display: flex;
  align-items: center;
  align-items: flex-start;
  /* gap: 2rem; */
  flex-direction: column;
  /* background-color: red; */
}

.not-found-image > img {
  width: 100%;
  height: auto;
}

.not-found-text-container :first-child > span {
  font-size: 96px;
  font-weight: 700;
  color: #000000;
}

.not-found-text-container :nth-child(2) > span {
  color: #000000;
  font-size: 28px;
  font-weight: 500;
}

.not-found-text-container :nth-child(3) {
  width: 280px;
  padding-top: 10px;
}

.not-found-text-container :nth-child(3) > span {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}

.button-go-back {
  padding-top: 31px;
}

.button-go-back > button {
  width: 159px;
  height: 51px;
  background: #1571de;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 30.5px;
  color: white;
  text-transform: none;
}
