.Container {
    width: 100%;
    min-height: 100vh;
    margin: 24px 8px;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.Header>span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
}

.HeaderButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.HeaderButtonWrapper button img {
    width: 15px;
    height: 18px;
    object-fit: cover;
}

.DeleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D52701;
    border-radius: 8px;
}

.DeleteButton span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #D52701;
}

.EditButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #1571DE;
    border-radius: 8px;
}

.EditButton span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1571DE;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.BoxForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.BoxFormMultiple {
    display: flex;
    justify-content: space-between;
}

.BoxForm label,
.BoxFormMultiple label {
    width: 50%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
}

.BoxFormMultiple label {
    margin-top: 10px;
}

.BoxForm input,
.BoxFormMultiple input {
    width: 50%;
}

.BoxForm input:disabled {
    background: #E0E0E0 !important;
    color: #9E9E9E !important;
    border: 1px solid #C2C2C2 !important;
    box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05) !important;
}

.AutocompleteDisabled {
    background: #E0E0E0 !important;
    color: #9E9E9E !important;
    /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05) !important; */
    border-radius: 8px;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.CancelButton {
    width: 80px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #1571DE;
    border-radius: 8px;
    color: #1571DE;
}

.SaveButton {
    width: 80px;
    height: 40px;
    left: 1176px;
    top: 560px;
    background: #1571DE;
    border-radius: 8px;
    color: #fff;
}