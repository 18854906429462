.product__external-container {
  padding-top: 24px;
}

.product__external-box {
  width: 100%;
  background: #fafafa;
  padding: 24px;
}
/* .product__external-desc {
  display: flex;
  flex-direction: row;
} */

.product__external-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 100px;
  row-gap: 20px;
}

.product__external-text-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .product__external-wrapper {
    column-gap: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .product__external-wrapper {
    flex-wrap: wrap;
  }
}
