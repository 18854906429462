.wrapperModalSuccessReturn {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 327px;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;
  > h4 {
    color: #0f161f;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }
  > img {
    width: 100px;
    height: 100px;
  }
}

.containerModalAddNewBatch {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: #fff;
  border-radius: 8px;
  width: 502px;

  .header {
    padding: 24px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: #0a0a0a;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }
  }
  .verifySection {
    padding-inline: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .desc {
      color: #adb5bd;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .inputVerify {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      > label {
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
      .viewRow {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        .wrapperInput {
          display: flex;
          gap: 4px;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--Neutral-50, #ced4da);
          background: var(--Neutral-20, #f8f9fa);
          padding: 12px 16px;
          width: 310px;
          height: 48px;
          flex-shrink: 0;
          > input {
            background: var(--Neutral-20, #f8f9fa);
            border: none;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
  .mainSection {
    .statusVerification {
      padding: 12px 24px;
      display: flex;
      gap: 12px;
      align-items: center;
      > span {
        color: #000;
        font-size: 13px;
        font-weight: 500;
        line-height: 140%;
      }
      &.success {
        background-color: #f4fff2;
      }
      &.error {
        background-color: #fff4f2;
      }
    }
    display: flex;
    flex-direction: column;
    row-gap: 0;
    .dropdown {
      width: 100%;
      display: flex;
      border: 1px solid #d1d1d1;
      border-right: none;
      border-left: none;
      > div {
        width: 100%;
        display: flex;
        padding-block: 1rem;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        > span {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          padding-left: 24px;
        }
      }
    }
    .listNumber {
      display: flex;
      flex-direction: column;
      height: 358px;
      overflow-y: auto;
      .between {
        padding-inline: 24px;
        border-bottom: 1px solid #e7e7e7;
        display: flex;
        justify-content: space-between;
        padding-block: 24px;
        align-items: center;
        &.borderNone {
          border: none;
        }
        > div {
          display: flex;
          gap: 0;
          align-items: center;
          > span {
            color: #000;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .emptyHandler {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        > img {
          width: 60px;
          height: 100px;
          object-fit: contain;
        }
        > span {
          color: #adb5bd;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
    .divider {
      background-color: #d1d1d1;
      height: 1px;
      width: 100%;
    }
    .button {
      padding: 24px;
      width: 100%;
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.closeIcon {
  color: #adb5bd;
  cursor: pointer;
  flex-shrink: 0;
  &:hover {
    color: #212121;
  }
}
