.Background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  width: 500px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
  margin-bottom: 16px;
}

.Header > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #0a0a0a;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.BoxForm {
  height: 40px;
}

.ButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 8px;
}

.CancelButton {
  width: 100px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1571de;
  border-radius: 8px;
  color: #1571de;
}

.SaveButton {
  width: 100px;
  height: 40px;
  background: #1571de;
  border-radius: 8px;
  color: #fff;
}

.Error {
  background-color: #fee;
  border: 1px solid #edd;
  color: #a66;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 14px;
}

.FormDetailHeader {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
}

.FormDetailHeader > span:first-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
}

.ContainerPKS {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.HeadingPKS > span {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.HeadingPKSHidden  {
  display: none;
}

.PKSValue {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.PKSValue > label {
    text-transform: capitalize;
}
