.WrapperRecentProductPromo {
  flex: 1;
  /* padding: 15px; */
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 237px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.HeaderRecentProductPromo {
  padding-top: 15px;
  padding-inline: 15px;
}

.HeaderRecentProductPromo > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.TableRecentProductPromo {
    padding-top: 18px;
}
