.container {
  display: flex;
  justify-content: center;
  // min-height: calc(100vh - 130px);
  // overflow: auto;
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    gap: 16px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > div {
        max-width: 170px;
        width: 100%;
      }
      > span {
        color: #000;
        width: 100%;

        /* Heading 2/medium */
        // font-family: Poppins;
        font-size: calc(24px + (32 - 24) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 125% */
      }
    }

    .cardBox {
      display: flex;
      gap: 16px;
      width: 100%;
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 29px;
        border-radius: 16px;
        background: #fff;
        box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
        padding: 16px;
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          > div:first-child {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > span:first-child {
              color: #202224;

              /* Text-M/regular */
              // font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
            > span:last-child {
              color: #202224;

              /* Heading 3/medium */
              // font-family: Poppins;
              font-size: calc(
                20px + (24 - 20) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 500;
              line-height: 30px; /* 125% */
              > span {
                color: var(--Neutral-50, #c2c2c2);

                /* Text-XS/regular */
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px; /* 140% */
              }
            }
          }

          > div:last-child {
            width: 40px;
            height: 40px;

            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .bottom {
          width: 100%;
          .percentage {
            display: flex;
            align-items: center;
            gap: 4px;
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
            }
            > span {
              > span {
                color: #00b69b;
                &.red {
                  color: #f93c65;
                }
              }
              color: #606060;
              // font-family: "Nunito Sans";
              font-size: calc(
                14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }

    .chartBox {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
      width: 100%;
      // margin-bottom: 16px;
      > span {
        color: #202224;

        /* Heading 3/medium */
        // font-family: Poppins;
        font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 125% */
        padding: 16px 16px 0px 16px;
      }
      > div {
        width: 100%;
      }
    }
  }
}

.tooltipBox {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);

  padding: 8px;
  > span {
    color: #fff;

    /* Caption 1/Reguler */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  > div {
    > div {
      color: #fff;

      /* Caption 1/Reguler */
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      > span {
        color: #fff;

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    fill: #cdd2db;
  }
  50% {
    fill: #dee2e7;
  }
  100% {
    fill: #edeff2;
  }
}
.barLoading {
  fill: #cdd2db; // Warna saat loading
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate;
}
