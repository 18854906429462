.ContainerTableProducts {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.HeaderTableProducts {
  display: flex;
  justify-content: space-between;
}

.ContainerContentTableProducts {
  padding-top: 30px;
}

.WrapperContentTableProducts {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  max-height: 810px;
  height: 100vh;
}

.SidebarTableProducts {
  flex: 0.4;
  max-width: 290px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 25px 15px;
  border-right: 1px solid #e9e9e9;
  height: 100%;
  overflow: scroll;
}

.HeaderSidebarTableProducts {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.HeaderSidebarTableProducts > span {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 600;
}

.SearchbarTableProducts {
  display: flex;
}

.ListProductsItems {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.ItemsProductsButton {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  width: 100%;
}

.ItemsProductsButton > button {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px;
  height: 48px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.ItemsProductsButton > button.active {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px;
  height: 48px;
  background: #f7faff;
  border: 1px solid #1571de;
  border-radius: 8px;
  color: #1571de;
}

.ItemsProductsButton > i.active {
  color: #1571de;
}

.ItemsProductsButton > span {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
}

/*  */
.ContentListTableProducts {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
