.Background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Container {
    border-radius: 8px;
    width: 615px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #e5e5e5;
}

.Header>span:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}

.Content {
    padding: 10px 24px 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.BoxContent {
    display: flex;
    align-items: flex-start;
}

.BoxLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 30%;
}

.BoxLeft>span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #333333;
}

.IconImg {
    width: 14px;
    height: 14px;
    object-fit: contain;
}

.Icon {
    color: #616161 !important;
    width: 16px !important;
    height: 16px !important;
}


.BoxRight {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.BoxRight>span {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #333333;
}

.AbsenceImageContainer {
    width: 200px;
    height : 200px;
    /* height: 158px; */
    /* width: 100%; */
    /* overflow-y: scroll; */
    position: relative;
}

.AbsenceImage {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
    margin-top: 10px;
}

.LocationHeader {
    display: flex;
    align-items: center;
    gap: 9px;
    margin-bottom: 10px;
}

.WorkplaceIcon {
    color: #1571de !important;
    width: 22px !important;
    height: 22px !important;
}

.LocationHeader>span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #333333;
}