.container {
  min-height: calc(100vh - 90px);
  .filterWrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    > .dateFilter {
      justify-items: flex-end;
      flex: 1;
    }
  }

  .pagination {
    border: none !important;
    background-color: transparent !important;
    padding-inline: 0px;
  }

  .cardWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 6px;
    margin-bottom: 8px;
    .emptyHandlerBox {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      margin-block: 24px;
      .images {
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 231.048px;
          height: 213.493px;
          object-fit: cover;
        }
      }
      > span:nth-child(2) {
        color: var(--Neutral-100, #0a0a0a);
        text-align: center;
    
        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
    
      > span:last-child {
        color: var(--Neutral-100, #0a0a0a);
    
        /* Text-M/regular */
        // font-family: Poppins;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #fff;
    box-shadow: 6px 6px 56px 0px rgba(0, 0, 0, 0.05);
    .cardHeader {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      .paymentStatusWrapper {
        display: flex;
        gap: 12px;
        align-items: center;
        > :first-child {
          color: #757575;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px; /* 140% */
        }
        .paymentStatus {
          display: flex;
          padding: 2px 8px;
          border-radius: 6px;
          border: 1px solid;

          > p {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px; /* 133.333% */
            text-transform: capitalize;
          }
        }
        .paymentStatus[is-waiting="true"] {
          border-color: #e9b62f;
          background-color: #fff9e9;
          color: #e9b62f;
        }
        .paymentStatus[is-overdue="true"] {
          border-color: #d42701;
          background-color: #fff4f2;
          color: #d42701;
        }
        .paymentStatus[is-accepted="true"] {
          border-color: #22970f;
          background-color: #f4fff2;
          color: #22970f;
        }
        .paymentStatus[is-due="true"] {
          border-color: #1571de;
          background: var(--Primary-Surface, rgba(208, 227, 248, 0.3));
          color: #1571de;
        }
      }
    }

    .cardInfo {
      display: flex;
      gap: 6px;
      align-items: center;
      .divider {
        color: #c2c2c2;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      .cardID {
        color: #1571de;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    .cardPromotor {
      display: flex;
      align-items: center;
      gap: 6px;
      > p {
        color: #757575;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px; /* 140% */
      }
      .avatarPromotor {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #0a0a0a;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .cardDate {
      display: flex;
      gap: 8px;
      color: #9e9e9e;
      align-items: center;
      > :first-child {
        font-size: 20px;
      }
      > :last-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }

    .cardID {
      color: #1571de;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .cardBody {
      display: flex;
      gap: 24px;
      padding: 12px 16px;
      border-block: 1px solid #ededed;
      > div {
        flex: 1;
      }
      > :last-child {
        flex: 1.5;
      }
      .labelWrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      .label {
        overflow: hidden;
        color: #3e4856;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      .value {
        overflow: hidden;
        color: #424242;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        span {
          color: #757575;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
        .dueDate {
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .dueDate[is-overdue="true"] {
          color: #d42701 !important;
          > span {
            color: #d42701 !important;
          }
        }
        .link {
          color: #1571de;

          /* Text-S/regular */
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          text-decoration: underline;
          text-decoration-style: solid;
          text-underline-position: from-font;
          cursor: pointer;
        }
      }
      .labelWrapper > .phoneNumber {
        display: flex;
        gap: 16px;
        align-items: center;
        .copy {
          display: flex;
          gap: 4px;
          color: #1571de;
          align-items: center;
          cursor: pointer;
          > :first-child {
            font-size: 16px;
          }
          > :last-child {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
        }
      }
    }
    .cardFooter {
      display: flex;
      justify-content: space-between;
      padding: 12px;
      > .priceWrapper {
        display: flex;
        align-items: center;
        padding: 12px;
        border-radius: 6px;
        background: #fafafa;
        width: 256px;
        > :first-child {
          font-size: 20px;
          color: #1571de;
        }
        .label {
          color: #0a0a0a;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          margin-left: 2px;
          white-space: nowrap;
        }
        > :last-child {
          flex: 1;
          text-align: right;
          color: #1571de;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
      > .buttonWrapper {
        display: flex;
        gap: 12px;

        .buttonWithIcon {
          > :first-child {
            text-transform: capitalize !important;
            height: 100% !important;
            border-radius: 8px;
          }
        }
        .buttonWithEndIcon {
          align-items: center;
          display: flex;
          gap: 8px;
          border-radius: 8px;
          background: #1571de;
          padding: 10px;
          color: #fff;
          cursor: pointer;
          user-select: none;
          height: 100%;
          &:hover {
            background: #0a66d8;
          }

          > :first-child {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
          > :last-child {
            font-size: 20px;
          }
        }
      }
    }
  }

  .confirmPaymentModal {
    display: flex;
    flex-direction: column;
    gap: 26px;
    .modalBody {
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: center;
      > :first-child {
        color: var(--Neutral-100, #0a0a0a);
        font-size: 24px;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        text-align: center;
      }
      > :nth-child(2) {
        color: #c2c2c2;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
      }
      > :last-child {
        color: #1571de;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px; /* 130% */
      }
    }
    .modalAction {
      display: flex;
      gap: 10px;
      > * {
        flex: 1;
      }
    }
  }
  .changePaymentModalIcon {
    color: var(--Primary-Main, #1571de);
    font-family: Poppins;
    font-size: 110.909px;
    font-weight: 700;
    line-height: 140%; /* 155.273px */
    text-align: center;
  }
  .changePaymentModal {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .modalBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      > :first-child {
        color: #1571de;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
      }
      > :last-child {
        color: #333;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
      }
    }
    .modalAction {
      display: flex;
      gap: 10px;
      > * {
        flex: 1;
      }
    }
  }
  .successPaymentModal {
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    align-items: center;
    > img {
      width: 160px;
    }
    > p {
      color: #0f161f;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    > :last-child {
      width: 340px;
    }
  }
  .uploadModalContainer {
    border-radius: 8px;
    background: var(--Neutral-10, #fff);
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 536px;
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      > :first-child {
        color: #0a0a0a;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
    }
    .modalBody {
      width: 100%;
    }
    .modalAction {
      display: flex;
      gap: 10px;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
.receiptModalContainer {
  width: 680px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > :first-child {
      color: #0a0a0a;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
    > :last-child {
      cursor: pointer;
    }
  }
  > img {
    width: 100%;
    max-height: 550px;
    object-fit: contain;
  }
}
