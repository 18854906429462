.app__header-featured {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}

.app__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 221px;
  height: 102px;
  background-color: #f3f5f7;
  opacity: 0.7;
  border-radius: 8px;
  padding: 10px;
}

.featured-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}

.featured-title > label {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.featured-title > span {
  font-size: 16px;
  color: black;
  font-weight: 600;
}

/* .featured-icon {
} */

.featured-icon > img {
  width: 51px;
}
