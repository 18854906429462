.item-helper-bottom {
  display: flex;
  align-items: center;
  padding-top: 30px;
}
.button-helper-add-clientId {
  display: flex;
  align-items: center;
}

.button-helper-add-clientId > button {
  background: #fafafa;
  padding-left: 80px;
}
.item-button-helper {
  display: flex;
  align-items: center !important;
  margin-top: auto;
  justify-content: space-between;
  cursor: pointer;
}
