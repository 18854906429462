.text__tabs {
  font-size: 24px;
  font-weight: 500;
  /* color: #1571de; */
  /* color: black; */
  text-transform: none;
}
/* .text__tabs active {
  font-size: 24px;
  font-weight: 500;
  color: #1571de;
  text-transform: none;
} */
.product__tabs {
  padding: 0 !important;
  padding-top: 32px !important;
}
