.Background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  border-radius: 8px;
  width: 615px;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
}



.Body {
  width: 664px;
  background-color: #ffffff;
  border-radius: 8px;
}

.Heading {
  padding: 32px;
  height: 82px;
  display: flex;
  justify-content: space-between;
  /* background-color: #fafafa; */
}

.Heading > span {
  font-size: 24px;
  font-weight: 500;
  color: #0a0a0a;
}

.Heading > span > label {
  color: #1571de;
  font-size: 24px;
  font-weight: 500;
}

.Desc {
  padding-inline: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 32px;
}

.ValueControl {
  width: 100%;
}

.ValueControl > span {
  color: #9e9e9e;
  font-size: 12px;
  font-weight: 400;
}

.ValueControl > span > label {
  color: #0a0a0a;
  font-size: 12px;
  font-weight: 600;
}

.BoxDesc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 8px;
  gap: 16px;
  height: 120px;
  overflow-y: auto;
}

.BoxItem {
    width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ItemLeft {
width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ItemLeft > span {
    font-size: 14px;
    font-weight: 500;
    color: #0A0A0A;
}

.ItemLeft > img {
    width: 24px;
    height: 24px;
}

.ItemCenter {
    width: 100%;
}

.ItemCenter > span {
    font-size: 12px;
    color: #C2C2C2;
    font-weight: 400;
}

.ItemRight {
    width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ItemRight > span  {
    color: #0A0A0A;
    font-size: 12px;
    font-weight: 400;
}

.Download {
  width: 100%;
}

/* .Download > button {
  text-transform: none;
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: 1px solid #1571de;
  border-radius: 8px;
  font-size: 13px;
  color: #1571de;
  font-weight: 500;
} */

.Download  > .Disabled {
  border: 1px solid grey;
  text-transform: none;
  width: 100%;
  height: 40px;
}


.Download > .ButtonDownload {
  text-transform: none;
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: 1px solid #1571de;
  border-radius: 8px;
  font-size: 13px;
  color: #1571de;
  font-weight: 500;
} 
