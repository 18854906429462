.container-PE {
  width: 100%;
  height: 100vh;
}

.box-wrapper-PE {
  background-color: #fafafa;
  display: flex;
  margin: 20px;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  margin-top: 32px;
}
.box-wrapper-PE-left {
  flex: 0.5;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-wrapper-PE-left > img {
  width: 70%;
  height: auto;
  border-radius: 50%;
  /* margin-bottom: 50px; */
  /* background-color: royalblue; */
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.box-wrapper-PE-right {
  flex: 0.8;
  /* background-color: rosybrown; */
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.item-row-first-PE {
  display: flex;
  justify-content: space-between;
}
.form-group-PE {
  display: flex;
  flex-direction: column;
}

.form-group-PE > label {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.form-group-PE > input {
  width: 543px;
  border: 1.5px solid #d3c9c9;
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
}

.button-helper-PE {
    display: flex;
    column-gap: 24px;
}

@media only screen and (max-width: 1024px) {
    .box-wrapper-PE {
        flex-direction: column;
    }
    .form-group-PE > input {
        font-size: 14px;
      }
    .box-wrapper-PE-left {
        justify-content: center;
        padding-top: 50px;
    }
    .box-wrapper-PE-left > img {
        width: 40%;
      }
      .box-wrapper-PE-right {
        padding-top: 100px;
    }
    
}
