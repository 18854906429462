.Container {
  width: 100%;
  height: 100%;
}

.Header {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  margin-bottom: 24px;
}

.Searchbar {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.AddButton {
  width: 139px !important;
  height: 40px !important;
  background: #1571DE !important;
  border-radius: 8px !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff !important;
  text-transform: capitalize !important;
}


.Button {
  text-transform: none;
  padding-left: 15px;
}

.WrapperCard {
  /* display: flex;
  align-items: center;
  gap: 18px;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* grid-template-columns: repeat(auto-fit, 1fr); */
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  column-gap: 15px;
  row-gap: 15px;
}

.CardContainer {
  /* width: 100%; */
  /* flex: 1; */
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.HeaderCard {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.Icon {
  padding: 8px;
  background-color: #ebf4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.Icon > img {
  width: 24px;
  height: 24px;
}

.Text > span {
  font-weight: 500;
  color: #404040;
  font-size: 20px;
}

.Desc {
  display: flex;
}

.Desc > span {
  font-size: 16px;
  font-weight: 400;
  color: #9e9e9e;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: auto;
}

.HeadingText {
  display: flex;
  align-items: center;
}

.HeadingText > span {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}

.ButtonHelper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.ButtonHelper > img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
