/* General */

.Container {
  width: 100%;
  min-height: 100vh;
}

.Controlbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
}

.BorderBottom {
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #ededed;
  bottom: 0;
}

.Mode {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.Mode span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.ActiveMode {
  border-bottom: 3px solid #1571de !important;
}

.ActiveMode span {
  color: #1571de !important;
}

.ControlOptionsContainer {
  padding-block: 24px;
}

.TableContainer {
  min-width: 600px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  border-bottom: 0;
}

.Avatar {
  background: #404040 !important;
  font-size: 14px !important;
  width: 30px !important;
  height: 30px !important;
}

/* Profile Mode */

.ProfileControlWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ProfileControlLeft {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.ProfileControlRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
}

/* Attendance Mode */

.AttendanceControlWrapper {
  display: flex;
  /* flex-direction: column; */
  /* gap: 16px; */
}

.AttendanceControlTop {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 10px;
}

.C2CControlWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.C2CControlTop {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
/* 
.C2CControlTop > div {
  width: 100% !important;
} */

.C2CControlTop > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.DownloadExcelButton {
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: #757575 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 170px !important;
  height: 40px !important;
  background-color: #ffffff !important;
}

.AttendanceControlBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.AttendanceControlBottomLeft {
  display: flex;
  width: 100%;
  column-gap: 20px;
}

.AttendanceControlBottomRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
  width: 100%;
}

.RangeDate {
  display: flex;
}

.ImageButton {
  width: 20px;
  height: 20px;
}

.SelectBox {
  height: 40px;
}

.Select {
  height: 40px;
  border-radius: 8px !important;
  min-width: 132px;
  background-color: var(--main-bg);
}

.SubHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubHeader  > span {
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}

.EditIndicator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.RequestEdit {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.RequestEdit>div {
    background-color: #FFF9E9;
    border: 1px solid #E9B62F;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.RequestEdit.active > div {
  background-color: #E9B62F !important;
}

.RequestEdit span {
    font-weight: 400;
    font-size: 14px!important;
    line-height: 20px;
    color: #757575!important;
}


.Resign {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.Resign>div {
    background: #FFF4F2;
    border: 1px solid #D52701;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.Resign.active > div {
  background: #D52701;
}

.Resign span {
    font-weight: 400;
    font-size: 14px!important;
    line-height: 20px;
    color: #757575!important;
}
