.container-tenor-form {
  margin-top: 20px;
}

.wrapper-box-tenor {
  display: flex;
  justify-content: space-between;
}
.box-tenor-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background: #fafafa;
  border-radius: 8px;
  padding: 20px;
}

.item-box-tenor {
  display: flex;
  flex-direction: column;
}

.from-box-tenor {
  display: flex;
  align-items: center;
  padding-top: 10px;
  column-gap: 20px;
}
.item-box-tenor > span {
  margin-bottom: 10px;
}

/* .item-box-loan-max {
  width: 200px;
} */

.form-loan-max {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  padding-top: 10px;
}
/* .item-box-date-payment {
  background-color: cornflowerblue;
} */

.form-date-payment {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  padding-top: 10px;
}
/* 
.item-box-date-range {
  padding: 10px;
} */

.wrapper-box-date-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
}

/* .item-box {
  display: flex;
  align-items: center;
  column-gap: 20px;
} */

.date-range-item {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding-top: 10px;
}

.button-delete-range-date {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.btn-add-more-range-date {
  font-size: 20px;
  padding-top: 10px;
}

@media screen and (max-width: 1300px) {
  .label-box-date-range {
    font-size: 14px;
  }
  .date-range-item > span {
    font-size: 13px;
  }
}

@media screen and (max-width: 1200px) {
  .date-range-item > span {
    font-size: 11px;
  }
  .date-range-item {
    column-gap: 10px;
  }
}

@media screen and (max-width: 1050x) {
  .date-range-item {
    column-gap: 5px;
  }
}

@media screen and (max-width: 1024px) {
  .date-range-item {
    column-gap: 20px;
  }
  .date-range-item > span {
    font-size: 14px;
  }
}
