.Container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.Wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.Searchbar {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Selection {
  display: flex;
  width: 100%;
  column-gap: 20px;
}

.Others {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.OthersDownload {
  display: flex;
  gap: 20px;
  /* justify-content: flex-end; */
}

.OthersDownload >button > span {
  font-size: 12px;
}

.OthersRangeDate {
  width: 100%;
  display: flex;
  align-items: center;
  /* column-gap: 20px; */
  gap: 20px;
  /* justify-content: flex-end; */
}

.RangeDate {
  display: flex;
}

.LoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.LoadingWidth {
  width: 200px;
}
