.container-customer-detail {
  padding-top: 30px;
}
.wrapper-customer-detail {
  display: flex;
  column-gap: 20px;
}

.box-left-customer-detail {
  flex: 1;
  max-width: 300px;
  background: #f3f5f7;
  border-radius: 8px;
  height: auto;
  padding: 20px;
}

.image-item-customer-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-item-customer-detail > img {
  border-radius: 50%;
  max-inline-size: 100%;
  block-size: auto;
  width: 150px;
  height: 150px;
}

.customer-profile-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  /* row-gap: 5px; */
}

.customer-name {
  font-size: 1.438rem;
  font-weight: 400;
  color: #0a0a0a;
}

.customer-bod {
  font-size: 1rem;
  font-weight: 500;
  color: #616161;
}

.container-profile-detail {
  /* background-color: red; */
}

.wrapper-item-top-customer-detail {
  display: flex;
  justify-content: space-between;
}

.wrapper-item-icon-profile {
  display: flex;
  column-gap: 10px;
}

.detail-text-right {
  display: flex;
  flex-direction: column;
}

.detail-text-right > label {
  font-size: 0.875rem;
}

.item-container-profile {
  padding-top: 10px;
}

.icon-profile-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border-radius: 8px;
  width: 40px;
  height: 40px;
}

.box-right-customer-detail {
  flex: 1;
  width: 100%;
  background: #f3f5f7;
  border-radius: 8px;
  height: auto;
  padding: 20px;
}

.text-header-customer-detail > span {
  font-size: 1.25rem;
}

.divider-customer-detail {
  margin-top: 10px;
}
.container-items-customer-detail {
  display: flex;
  justify-content: space-between;
  padding-right: 150px;
  padding-top: 15px;
}

.item-left-detail {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.item-left-detail > label {
  font-size: 1rem;
  color: #616161;
  font-weight: 400;
}

.item-left-detail > span {
  font-size: 1rem;
}

.item-right-detail {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.item-right-detail > label {
  font-size: 1rem;
  color: #616161;
  font-weight: 400;
}

.item-right-detail > span {
  font-size: 1rem;
}
