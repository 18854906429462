/* .ContainerAttendanceChart {
} */

.WrapperAttendanceChart {
  flex: 1;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 386px;
  display: flex;
  flex-direction: column;
}

.HeaderAttendanceChart > span {
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.ReportAttendanceChart {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.BorderItemReportAttendance {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.BorderItemReportAttendance :nth-child(1) {
  padding-left: 10px;
}

.ReportAttendanceText {
  display: flex;
  column-gap: 5px;
}

.ReportAttendanceText :nth-of-type(1) > span  {
    color: red;
}

