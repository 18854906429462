.container-published {
  width: 100%;
  height: 100vh;
}
/* .wrapper-published {
    display: flex;
    justify-content: space-between;
} */
.item-top-published {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .item-top-published-left {
} */
.item-top-published-right > button {
  text-transform: none;
  width: 127px;
  height: 40px;
  border-radius: 4px;
  color: white;
}
.wrapper-card-list {
  padding-top: 20px;
}
.row-item-published {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.box-wrapper-card {
  width: 100%;
  /* height: 113px; */
  background: #fafafa;
  border-radius: 8px;
  display: flex;
}
.item-card-list-faq {
  display: flex;
  align-items: center;
  padding: 24px 16px;
  column-gap: 24px;
  width: 100%;
}
/* .wrapper-circle-id {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #599edb;
  border-radius: 50%;
} */
.box-circle-id {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 32px;
  height: 32px;
  background: #599edb;
  border-radius: 50%;
  text-align: center;
}
.item-card-text-list-faq {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-right: auto;
}
.item-card-list-faq > span {
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.item-card-text-list-faq > label {
  cursor: pointer;
  font-size: 16px;
  color: #616161;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 500px; */
  max-width: 350px;
  overflow: hidden;
}
.icon-helper {
  display: flex;
  column-gap: 24px;
}

@media (max-width: 1024px) {
  .item-card-list-faq {
    column-gap: 10px;
  }
  .item-card-text-list-faq  {
    width: 400px;
  }
  .item-card-text-list-faq > label {
    width: 400px;
  }
}
