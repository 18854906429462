.Container {
  width: 100%;
  min-height: 100vh;
  margin-top: 24px;
  /* margin-left: -18px; */
  padding-right: 18px;
  padding-bottom: 18px;
}

.BannerContainer {
  background: url(../../../../assets/images/clientbanner.png) no-repeat;
  background-size: cover;
  width: 100% + 24px;
  height: 200px;
  margin-inline: -43px !important;
  display: flex !important;
  align-items: flex-start;
  justify-content: space-between;
  padding: 48px;
  padding-top: 60px;
}

.ID {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 18px;
}

.IDDesc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.IDDesc > span:first-child {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.005em;
  color: #fff;
}

.BannerButtonWrapper {
  display: flex;
  align-items: center;
  gap: 17px;
}

.BannerButtonWrapper button {
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BannerButtonWrapper img {
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: -56px;
}

.InfoContainer {
  display: flex;
  flex-direction: column;
  background: #fff !important;
  padding: 24px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.InfoProfile > span,
.InfoScheme > span {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
  display: block;
  margin-bottom: 8px;
}

hr {
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.BoxProfile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.BoxLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 30%;
}

.BoxLeft img {
  min-width: 15px !important;
  max-width: 20px !important;
  min-height: 18px !important;
  max-height: 20px !important;
  object-fit: cover;
}

.BoxLeft span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161 !important;
}

.BoxRight {
  text-align: end;
  width: 70%;
}

.BoxRight span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #0a0a0a;
}

.SchemeContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(445px, 1fr));
  gap: 16px;
  min-height: 100%;
}

.SchemeCard {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
}

.CardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.CardHeader > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #404040;
}

.HeaderLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.HeaderLeft div {
  width: 40px;
  height: 40px;
  background-color: #ebf4ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CardLogo img {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.CardLogo i {
  font-size: 25px;
  color: #1571de;
}

.CardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.CardContent > span:first-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9e9e9e;
}

.CardContent > span:last-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}

.LeaveCardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LeaveCardContent > span:first-child {
  font-weight: 400;
  font-size: 16px;
  color: #9e9e9e;
}

.LeaveCardContent > span:last-child {
  font-weight: 500;
  font-size: 16px;
  color: #0a0a0a;
}

.EditPhone {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  /* align-items: center; */
}

.EditPhone > span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

.FormSubmit {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
}

.WACardContent {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  padding-right: 0.5rem;
}

.WACardContent > img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.ItemWA {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ItemWA > span:first-child {
  font-size: 16px;
  font-weight: 400;
  color: #9e9e9e;
}

.ItemWA > span:last-child {
  font-size: 16px;
  color: #0a0a0a;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.ButtonSubmit {
  display: flex;
  align-items: flex-end;
}

.ButtonSubmit > button {
  border-radius: 8px;
  background: #1571de;
  text-transform: none;
  color: #fff;
  width: 100px;
  height: 40px;
}

.ButtonSubmit > button:hover {
  background-color: #1571de;
}

.CardLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CardLink > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1571de;
}

.GadaiDesc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9e9e9e !important;
}

.BMWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
}

.BMWrapper > span,
.EmployeeListWrapper > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px;
  color: #404040;
  display: block;
  margin-bottom: 16px;
}

.BMContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
}

.BMCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 14px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.BMPP {
  width: 100px !important;
  height: 100px !important;
}

.BMID {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.BMID > span:first-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0a0a0a;
  text-transform: capitalize;
}

.BMID > span:nth-child(2) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #616161;
}

.TableWrapper {
  margin-top: 16px;
}

.TableContainer {
  min-width: 600px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  border-bottom: 0;
}

.Table {
  font-size: 20px !important;
}

.Avatar {
  background: #404040 !important;
  font-size: 14px !important;
  width: 30px !important;
  height: 30px !important;
}

.BMController {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.BMController span {
  color: #606060;
}

.ViewMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.ViewMore button {
  color: #1571de;
  background-color: transparent;
}

.LoadingBackground {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
