.container-PMS {
  width: 100%;
  height: 100vh;
}
/* .wrapper-PMS {

} */

.wrapper-box-top-payslip {
  display: flex;
  align-items: center;
  column-gap: 16px;
  justify-content: space-between;
  flex-direction: wrap;
  row-gap: 20px;
}
.button-download-payslip-menu {
  /* width: 100%; */
}
/* .button-download-payslip-menu > button{
  text-transform: none;
  background-color: #1571DE;
  width: 169px;
  height: 40px;
} */

/* .wrapper-table-box-search-payslip-menu {
  padding-top: 24px;
} */

.box-filter-payslip-menu {
  padding-top: 24px;
  position: relative;
}
.wrapper-filter-show-another {
  /* width: 459.5px; */
  height: 76px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  column-gap: 20px;
}
.text-box {
  position: absolute;
  top: 12px;
  left: 25px;
  background-color: #fbfbfb;
  border: none;
}
.box-group-selected > input {
  /* width: 273px; */
  height: 40px;
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 8px;
}

.wrapper-filter-show-another :nth-child(2) {
  /* max-width: 176px; */
}

.wrapper-filter-show-another :nth-child(3) {
  /* justify-content: flex-end; */
  /* margin-left: auto; */
}
