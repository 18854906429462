.containerTabHeader {
  display: flex;
  min-width: max-content;
  width: 100%;
}
.wrapperTab {
  min-width: max-content;
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  color: #0a0a0a;
  text-align: start;
  border-radius: 8px;
}
