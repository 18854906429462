.Body {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.Selection {
  display: flex;
  column-gap: 10px;
}

.WrapperDateSelection {
  display: flex;
  column-gap: 10px;
}

.Other {
  display: flex;
  justify-content: flex-end;
}

.Content {
  padding-top: 24px;
}

.Text {
  font-size: 14px;
  font-weight: 400;
  color: "#0A0A0A";
}

.Store {
  padding: 3px 10px;
  display: inline-block;
  border: 1px solid #d52701;
  border-radius: 6px;
  background: #fff4f2;
  border-radius: 8px;
}
