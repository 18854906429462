.contaier__detail-store {
  width: 100%;
  margin-top: 20px;
}

.wrapper_container-detail-top {
  display: flex;
}

.container__image-detail {
  flex: 1;
}

.wrapper__item-detail {
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  row-gap: 40px;
}
.container__image-detail > img {
  width: 100%;
  height: auto;
}
.container__detail-store {
  flex: 1;
}
.items__detail-store {
  display: flex;
  justify-content: space-evenly;
}

.items__detail-store-2 {
  display: flex;
  justify-content: space-evenly;
  padding-top: 24px;
}
.items__detail-store-3 {
  display: flex;
  justify-content: space-evenly;
  padding-top: 24px;
}

.items__detail {
  display: flex;
  flex-direction: column;
}

.items__detail > label {
  color: #616161;
  font-size: 18px;
  font-weight: 500;
}

.items__detail > input[type="text"] {
  color: #0a0a0a;
  font-size: 18px;
  font-weight: 400;
}
.items__detail > input {
  width: 182px;
  padding-top: 8px;
}

::placeholder {
  color: #0a0a0a;
  opacity: 1;
}

.item__store-button {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

/* .container__detail-store {
  flex: 1;
  padding-left: 40px;
}

.items__detail-store-1 {
  display: flex;
  column-gap: 40px;
} 

.items__detail-1 {
  display: flex;
  flex-direction: column;
}
.items__detail-1 > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}
.items__detail-1 > input {
  padding-top: 8px;
  font-size: 20px;
  font-weight: 400;
  color: #0a0a0a;
  border: 0;
  width: 180px;
  height: 40px;
}
.items__detail-2 {
  display: flex;
  flex-direction: column;
}

.items__detail-2 > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}
.items__detail-2 > input {
  padding-top: 8px;
  font-size: 20px;
  font-weight: 400;
  color: #0a0a0a;
  border: 0;
  width: 180px;
  height: 40px;
}


.items__detail-store-2 {
  padding-top: 24px;
  display: flex;
  column-gap: 40px;
}

.items__detail-3 {
  display: flex;
  flex-direction: column;
}

.items__detail-3 > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}
.items__detail-3 > input {
  padding-top: 8px;
  font-size: 20px;
  font-weight: 400;
  color: #0a0a0a;
  border: 0;
  width: 180px;
  height: 40px;
}

::placeholder {
  color: #0a0a0a;
  opacity: 1; 
}

.items__detail-4 {
  display: flex;
  flex-direction: column;
}

.items__detail-4 > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}
.items__detail-4 > input {
  padding-top: 8px;
  font-size: 20px;
  font-weight: 400;
  color: #0a0a0a;
  width: 180px;
  border: 0;
  height: 40px;
}

.items__detail-store-3 {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}

.items__detail-store-3 > label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
}

.items__detail-store-3 > input {
  padding-top: 8px;
  font-size: 20px;
  font-weight: 400;
  color: #0a0a0a;
  border: 0;
  width: 180px;
  height: 40px;
}
.items__detail-store-4 {
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  column-gap: 40px;
}

.wrapper__button-buttom > button {
  width: 183px;
  height: 36px;
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
}

.wrapper__button-bottom-red > button {
  width: 183px;
  height: 36px;
  border: 1px solid #d1421a;
  border-radius: 4px;
  text-transform: none;
  color: #d1421a;
} */

/* wrapper product sale */
.wrapper__product-sale {
  padding-top: 24px;
}

.product__sale-title {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
  padding-bottom: 15px;
}

.items__rectangle {
  display: flex;
  flex-direction: row;
  column-gap: 35px;
}

/* .items__product-sale {
  display: flex;
    flex-direction: column;
} */

.text__wrapper-product {
  display: flex;
  flex-direction: column;
}
.text__wrapper-product > label {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a0a;
}

.text__wrapper-product > span {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
}

/* dashed box */
.items__dashed-box {
  width: 144px;
  height: 120px;
  border: 2px dashed #c2c2c2;
  border-radius: 4px;
  background: #f3f5f7;
  cursor: pointer;
}
.text__wrapper-product-dashed {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 15px 0;
}

.text__wrapper-product-dashed > span {
  font-size: 18px;
  font-weight: 500;
  color: #3072b8;
}
/* add employee  */
.wrapper__add-employee {
  padding-top: 24px;
}

.add__employee-title {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
  padding-bottom: 15px;
}

.items__rectangle-add-employee {
  display: flex;
  flex-direction: row;
  column-gap: 35px;
}

/* .items__add-employee {
} */

.text__wrapper-employee {
  display: flex;
  flex-direction: column;
}
.text__wrapper-employee > label {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a0a;
}
.text__wrapper-employee > span {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
}

/* store promo bottom */
.container__store-promo {
  padding-top: 24px;
}
.wrapper__title-store-promo {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
  padding-bottom: 15px;
}

.box__store-promo-detail {
  /* width: 947px; */
  height: 142px;
  background: #fafafa;
  padding-inline: 10px;
}

.nav__store-detail-promo {
  display: flex;
  column-gap: 80px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}
.wrapper__product-one {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.item__text-detail {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (min-width: 2000px) {
  .wrapper__item-detail {
    row-gap: 40px;
  }

  .items__detail-store-4 {
    padding-top: 100px;
  }
}
@media screen and (max-width: 1440px) {
  .wrapper__item-detail {
    row-gap: 0;
  }
  .items__detail-store-4 {
    padding-top: 80px;
  }
  .wrapper__item-detail {
    padding-left: 50px;
  }
}

@media screen and (max-width: 1300px) {
  .container__image-detail > img {
    height: 310px;
  }
  .container__detail-store {
    padding-left: 80px;
  }
  .items__detail-store-4 {
    padding-top: 45px;
  }
}

@media screen and (max-width: 1200px) {
  .items__rectangle {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .items__rectangle-add-employee {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .container__detail-store {
    padding-left: 20px;
  }
}
