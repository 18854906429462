.divider-column-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  row-gap: 10px;
  /* flex-wrap: wrap; */
}
.add-more-default-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: left; */
  /* text-align: left; */
  /* margin-right: auto; */
  padding-top: 10px;
}

.add-more-default-time > button {
  color: #1571de;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
}

.box-input {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.box-input > input {
  /* width: 42; */
  height: 36px;
  background: #fbfbfb;
  border: 1.5px solid #e0e0e0;
  border-radius: 4px;
  /* padding-left: 20px; */
}

/* @media (max-width: 1024px) {

} */
