.CalendarHeaderSection {
  display: flex;
}

.CalendarTableHeader:first-child {
  border-left: none;
}

.CalendarTableHeader {
  border: 1px solid #dddddd;
  border-right: none;
  border-bottom: none;
  height: 38px;
  width: calc(100% / 7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CalendarBodySection {
  display: flex;
  flex-wrap: wrap;
}

.CalendarBodyFullDay {
  border-top: 1px solid #dddddd;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.CalendarDateBody {
  border-left: 1px solid #dddddd;
  width: calc(100% / 7);
  height: 100px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  transition: 0.3s;
  flex-direction: column;
  align-items: center;
}

.CalendarLabelImage {
  width: 34px;
  height: 34px;
}

.hoverable {
  cursor: pointer;
}

.weekend {
  background-color: #FFF5F5;
}

.hoverable:hover {
  scale: 110%;
  background-color: white;
  border: 1px solid #dddddd;
  z-index: 100;
}

.CalendarDate {
  position: absolute;
  top: 10px;
  left: 14px;
  font-size: 20px;
  font-weight: 600;
}

.CalendarNoBorder {
  border-left: none;
}

.CalendarDateLabel {
  font-weight: 400;
  font-size: 14px;
  color: #bdbdbd;
}
