.modal-MAL {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container-MAL {
  width: 747px;
  /* height: 700px; */
  background: #ffffff;
  border-radius: 12px;
  padding-inline: 20px;
}

.wrapper-item-top-MAL {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-modal-MAL {
  padding-top: 10px;
}
.title-modal-MAL > span {
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}

.wrapper-item-MAL {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}

/* .body-modal-SEM-row {
  display: flex;
  text-align: left;
  width: 699px;
  height: 62px;
  background: #fafafa;
  border-radius: 8px;
  padding-inline: 50px;
}

.modal-SEM-item-1 {
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: space-between;
}

.timein-SEM {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.timein-SEM > span {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}
.timein-SEM > label {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f70;
}

.breaktime-SEM {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.breaktime-SEM > span {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}
.breaktime-SEM > label {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f70;
}

.totalwork-SEM {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.totalwork-SEM > span {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}
.totalwork-SEM > label {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f70;
} */
