.container-login {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper-container-login {
  display: flex;
  flex-direction: row;
  /* min-height: 100vh; */
}

.login-container-left {
  flex: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f9f9f9;
  padding-bottom: 30px;
  padding-inline: 30px;
  padding-top: 20px;
}

.wrapper-logo-login {
  position: absolute;
  top: 20px;
  left: 30px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.wrapper-image-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 87px;
}

.wrapper-image-login > img {
  max-inline-size: 100%;
  block-size: auto;
  width: 100%;
  /* height: 435px; */
  object-fit: contain;
}

.wrapper-logo-login > span {
  font-size: 18px;
  font-weight: 500;
  color: #02339b;
  line-height: 25.2px;
  font-style: normal;
}

.login-container-right {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-inline: 35px;
  padding-bottom: 90px;
}

.wrapper-text-login {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.wrapper-text-login > h2 {
  font-size: 20px;
  font-weight: 500;
  color: #5d5871;
}

.wrapper-text-login > span {
  font-size: 13px;
  font-weight: 400;
  color: #5d5871;
}

.wrapper-login-form {
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.form-control-login {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  row-gap: 5px;
}

.form-control-login > span {
  font-size: 13px;
  font-weight: 400;
  color: #5d5871;
}

.form-control-button {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.form-control-button > p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #9e9e9e;
}

/* .form-control-button > button {
  text-transform: none;
  background-color: #1571de;
  justify-content: center;
  color: white;
  width: 100%;
  height: 44px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 5;
} */

.form-control-button .default-button {
  text-transform: none;
  background-color: #1571de;
  justify-content: center;
  color: white;
  width: 100%;
  height: 44px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 5;
}

.form-control-button .default-button:hover {
  background-color: #1571de;
}

.form-control-button .disabled-button {
  text-transform: none;
  background-color: #b1d0f4;
  justify-content: center;
  color: white !important;
  width: 100%;
  height: 44px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 5;
}

.container-privacy {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}

.container-privacy > h4 {
  font-size: 14px;
  color: #0a0a0a;
  font-weight: 500;
}
.container-privacy > h4 > span {
  font-size: 14px;
  color: #1571de;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.back-button {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.back-button > svg {
  color: #1571de;
  font-size: 24px;
}

.back-button > span {
  font-weight: 500;
  font-size: 14px;
  color: #1571de;
}

.login-container-right-otp {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-inline: 35px;
  padding-bottom: 90px;
}

/* .back-button {
  padding-top: 10px;
} */

.wrapper-otp-text {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper-otp-text > span:first-child {
  color: #5d5871;
  font-size: 24px;
  font-weight: 600;
}

.wrapper-otp-text > span:last-child {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400;
}

.otp-wrapper {
  padding-top: 28px;
}

.request-text-container {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.request-text {
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
  cursor: pointer;
}

.button-wrapper {
  padding-top: 40px;
}

.invalid-number {
  font-size: 12px;
  color: #d42701;
  font-weight: 400;
}

@media (max-width: 1300px) {
  .login-container-right,
  .login-container-right-otp {
    padding-bottom: 0px;
  }
}

@media (max-width: 1100px) {
  .wrapper-image-login {
    padding-top: 40px;
  }
}

@media (max-width: 768px) {
  .login-container-left {
    display: none;
  }
}

/* @font-face {
  font-family: arlon;
  src: url(../../../assets/font/arlon/ArlonSemiBold-DEMO.otf)
    format("truetype");
}

.container__login {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper__login {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.wrapper__login-left {
  flex: 1.5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding-bottom: 20px;
  padding-inline: 20px;
  padding-top: 20px;
}

.logo__top-left {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.logo__top-left > span {
  font-family: arlon;
  font-size: 16px;
  font-weight: normal;
}

.logo__top-left > img {
  width: 62px;
  height: 52px;
  cursor: pointer;
}

.logo__main-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__main-left > img {
  width: 531px;
  height: 470px;
}

.wrapper__login-right {
  flex: 1.5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text__login-right {
  text-align: center;
  width: 450px;
  padding-bottom: 20px;
}

.wrapper__login-card {
  width: 450px;
  background-color: #fff;
  border-radius: 10px;
  padding-inline: 40px;
  box-shadow: 0 0 20px rgb(89 102 122 / 10%);
}

.form__control {
  width: 100%;
  margin-block: 15px;
  display: flex;
  flex-direction: column;
}

.form__control > span {
  font-size: 18px;
  font-weight: 400;
}

.form__control > input {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  align-items: center;
  padding-inline: 10px;
  border-radius: 24px;
  border-width: 1px;
  border-color: #737373;
  transition: 400ms;
  font-size: 16px;
}

.form__control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.form__control-button > button {
  width: 180px;
  height: 47px;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  background-color: #1571de;

}

.form__control-button > span {
  font-weight: 20px;
  font-weight: 500;
  color: white;
} */
