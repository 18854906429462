.wrapperPageStore {
  display: flex;
  /* flex-direction: row; */
  align-items: center;

}

.wrapperPagesStoreReport {
  display: flex;
  align-items: center;
  width: 108px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
  margin-left: 24px;
  margin-bottom: 40px;
  cursor: pointer;
  padding-inline: 10px;
}

.wrapperPagesStoreReport > span {
text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
}

.wrapperPagesStoreReport > i {
  color: #1571de;
  padding-left: 20px;
}
