.Container {
  width: 100%;
  min-height: 100vh;
}

.CardForm {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.ValueControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.StatusControl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #fafafa;
  border-radius: 8px;
}

.ContentControl {
  width: 100%;
}

.ContentControl > img {
  max-inline-size: 100% !important;
  block-size: auto !important;
  width: 100% !important ;
  height: 372px !important;
  border-radius: 8px;
  block-size: auto !important;
}

.Desc {
  display: flex;
  flex-direction: column;
}

.Desc > span:first-child {
  font-size: 16px;
  font-weight: 600;
  color: #0a0a0a;
}

.Desc > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.CompanyControl {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.CompanyValue {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fafafa;
  border-radius: 8px;
  column-gap: 8px;
}

.CompanyValue > img {
  width: 15px;
  height: 16px;
}

.CompanyValue > span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

.VideoControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Player {
  width: 100%;
}

.YoutubeContainer {
  width: 100%;
  border-radius: 8px;
}

.Player > video {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.Player > iframe {
  width: 100%;
  height: 500px;
  border-radius: 8px;
}

.Content {
  width: 100%;
  /* display: flex;
  padding-block: 11px;
  padding-inline: 16px;
  padding: 10px;
  background: #fafafa;
  border-radius: 8px; */
}

.Content > img {
  width: 100%;
  height: 372px;
  
  /* width: 101px; */
  /* height: 113px; */
}

.DescPlayer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.DescPlayer > p {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}

.Heading {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.Heading > span:first-child {
  font-size: 24px;
  font-weight: 500;
  color: #0a0a0a;
}

.Heading > span:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #757575;
}
