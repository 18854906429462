.Container {
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ContainerTable {
  padding-top: 26px;
}

.TableContainer {
  min-width: 600px;
  background-color: "#ffffff";
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  border-bottom: 0;
  
}

.Loading {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingWidth {
  width: 200px;
}

.HeaderTitle {
  font-weight: 500;
  font-size: 20px;
  color: #0a0a0a;
}

.AddButton {
  border-radius: 8px !important;
  color: #1571de !important;
  border: 1px solid #1571de !important;
  text-transform: capitalize !important;
  min-width: 179px !important;
}

/* .TableBody:where(.Placeholder){
  width: 100%;
} */

.Placeholder {
  display: flex;
  width: 100%;
  height: inherit;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  justify-content: center;
}

.Placeholder > img {
  width: 40px;
  height: 40px;
}

.Placeholder > span {
  color: #404040;
  font-size: 1rem;
  font-weight: 500;
}

.ActionButton {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
