.Body {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.Pathname {
  font-size: 18px;
  color: #6f6f70;
  font-weight: 500;
}

.Pathname:hover {
  color: #6f6f70;
}
.Label {
  font-size: 18px;
  color: #1571de;
  font-weight: 500;
}
