.maintenance-container {
  width: 100%;
  min-height: 100vh;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.maintenance-container-center{
  width: 100%;
  min-height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;

}

.maintenance-main {
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  padding-left: 150px;
}

.maitenance-image {
  display: flex;
}

.maintenance-text {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-left: 220px;
  text-align: left;
  width: 70%;
}

.maintenance-label > span {
  color: #000000;
  font-size: 64px;
  font-weight: 700;
}

.maintenance-text :nth-child(2) {
  padding-top: 10px;
}

.maintenance-text :nth-child(2) > span {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.maintenance-text :last-child {
  padding-top: 25px;
  width: 400px;
}

.maintenance-text :last-child > span {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .maintenance-main {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .maintenance-main {
    padding-left: 50px;
  }
  .maintenance-text {
    padding-left: 150px;
  }
  .maintenance-text :last-child {
    width: 350px;
  }
}

@media screen and (max-width: 1024px) {
  .maintenance-main {
    padding-left: 150px;
  }
  .maintenance-text {
    padding-left: 220px;
  }
}
