.ContainerAreaStore {
  width: 100%;
  max-height: 100%;
  height: 100vh;
}

.HeaderAreaStore {
  display: flex;
  justify-content: space-between;
}

.WrapperContentAreaStore {
  padding-top: 25px;
  display: flex;
  row-gap: 15px;
  column-gap: 11px;
  flex-wrap: wrap;
}

.TextItemAreaStore {
  display: flex;
  align-items: center;
  column-gap: 11px;
  padding-inline: 15px;
  padding-top: 15px;
}

.TextItemAreaStore > span {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

.WrapperItemAreaStore {
  display: flex;
  width: 250px;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}

.TextItemAreaStore > img {
  width: 24px;
  height: 24px;
}

.ButtonActionItemAreaStore {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 33px;
  background: #eaf3ff;
  border-radius: 0px 0px 4px 4px;
  height: 36px;
}

.ButtonActionItemAreaStore > div:first-child {
  border-right: 1px solid #dadada;
}

.ButtonItemAreaStore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.ButtonItemAreaStore > img {
  cursor: pointer;
}
