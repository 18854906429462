.container-data-loan {
  width: 100%;
  height: 100vh;
}
.data-approved-history-menu-right-download > button {
  text-transform: none;
  color: #ffffff;
} 
.button-action-loan-history {
  text-transform: none;
}