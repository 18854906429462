
.Body {
  width: 664px;
  background-color: #ffffff;
}

.Heading {
  padding: 32px;
  height: 82px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
}

.Heading > span {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.Desc {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
}

.ValueControl {
  display: flex;
  align-items: flex-start;
}

.ValueLeft {
  flex: 1;
  display: flex;
  column-gap: 10px;
}

.ValueLeft > span {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
}

.ValueLeft > img {
  width: 24px;
  height: 24px;
}

.ValueRight {
  flex: 1;
  display: flex;
}

.ValueRight > span {
  font-size: 14px;
  font-weight: 400;
  color: #0a0a0a;
}
