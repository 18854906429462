.container {
  min-height: calc(100vh - 130px);
  > :first-child {
    color: #000;
    font-size: 40px;
    font-weight: 500;
    line-height: 50px; /* 125% */
    margin-bottom: 16px;
  }
  .controlWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filterWrapper {
      display: flex;
      gap: 8px;
    }
  }
  .tableWrapper {
    margin-top: 24px;
    .withProfilePic {
      display: flex;
      gap: 4px;
      align-items: center;
      color: #0a0a0a;
      cursor: pointer;
    }
    .img {
      border-radius: 50%;
      object-fit:fill;
      width: 24px;
      height: 24px;
    }
  }
}

.DownloadExcelButton {
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: #757575 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 66px !important;
  height: 40px !important;
  background-color: #ffffff !important;
}
.ImageButton {
  width: 20px;
  height: 20px;
}
