.wrapper__title-top {
    display: flex;
    align-items: center;
}
.wrapper__title-top > span {
  font-size: 20px;
  font-weight: 400;
  color: #255bfc;
}

