.ModalDeleteBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ModalContainerContent {
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
  }
  
  .WrapperItemModalSuccess {
    display: flex;
    align-content: center;
    flex-direction: column;
    gap: 16px;
  }
  
  .Image {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  
  .Image > img {
    width: 86px;
    height: 86px;
  }
  
  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  .Content > span {
    font-size: 20px;
    font-weight: 500;
    color: #0f161f;
  }
  
  /* .Content > label {
    font-size: 20px;
    font-weight: 500;
    color: #0f161f;
  } */
  
  /* .Content > span {
    font-size: 12px;
    font-weight: 400;
    color: #757575;
  } */
  
  .WrapperItemModalDelete {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: -25%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .ItemTopModalDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    background-color: white;
    border: 6px solid rgba(0, 0, 0, 0.5);
    padding-right: 6px;
  }
  
  .ItemTopModalDelete > img {
    width: 60px;
    height: 60px;
  }
  
  .ContentModalDelete {
    margin-top: 50px;
  }
  
  .TextModalDelete {
    display: flex;
    text-align: center;
    flex-direction: column;
    row-gap: 15px;
  }
  
  .TextModalDelete > span:nth-of-type(1) {
    color: #f02c01;
    font-size: 26px;
    font-weight: 600;
  }
  
  .TextModalDelete > span:nth-of-type(2) {
    color: #333333;
    font-size: 13px;
    font-weight: 400;
  }
  
  .WrapperButtonModalDelete {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-top: 50px;
  }
  