.SummaryCardContainer {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.SCHeader {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
}

.ItemLeft {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ItemLeft > span:first-child {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #404040;
}

.ItemLeft > span:last-child {
  font-weight: 400;
  font-size: 13px;
  color: #404040;
}

.SCHeader button {
  background: #1571de;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: #fff !important;
}

/* Content */

.SCContent {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.CalendarCard {
  padding: 0;
  margin: 0;
}

.CalendarHeader {
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
}

.CalendarHeader > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #404040;
}

.Daysbar,
.AbsencesContainer {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 1px;
  background-color: #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.EachDay {
  padding: 8px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6f6f70;
  text-transform: capitalize;
  background-color: #fff;
}

.AbsenceBox {
  min-height: 108px;
  height: auto;
  background-color: #fff;
  padding: 8px;
  cursor: pointer !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Weekend {
  background-color: #fff4f2 !important;
}

.Weekend > span {
  color: #d42701 !important;
}

.Weekend .ABTopLeft > span:first-child {
  color: #d42701 !important;
}

.Closing {
  width: 100% !important;
  height: 50px !important;
}

.ABTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.ABTopLeft {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.CancelText {
  color: #d42701;
  font-size: 10px;
  font-weight: 400;
  cursor: pointer;
}

.CancelTextFiller {
  font-size: 0px;
}

.ABTopLeft > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #0a0a0a;
}

.ABTopLeft > span:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
}

.ABTop > span {
  font-size: 22px !important;
}

.BottomDivider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 53%;
}

.ABCenter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.ABCenterImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ABCenterImage > img {
  width: 24px;
  height: 24px;
}

.ABCenter > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}

.ClockIcon {
  width: 19px !important;
  height: 19px !important;
  color: #acacac !important;
}

.ABBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 18px !important;
}

.ABBottomImage {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 18px !important;
}

.ABBottomImage > span {
  color: #9e9e9e;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Checkbox {
  width: 100%;
  display: flex;
  align-items: center;
}

.Checkbox > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #0a0a0a;
}

.TotalOT {
  width: 100%;
}

.TotalOT > span {
  font-size: 10px;
  font-weight: 400;
  color: #0a0a0a;
}

.LoadingContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
  
  .LoadingWidth {
    width: 200px;
  }
