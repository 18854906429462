.contaner-PT {
  width: 100%;
  height: 100vh;
}
.box-wrapper-PT {
  background-color: #fafafa;
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}
.box-wrapper-PT-left {
  flex: 0.6;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.box-wrapper-PT-left > img {
  width: 70%;
  height: auto;
  border-radius: 50%;
  /* margin-bottom: 50px; */
  /* background-color: royalblue; */
}
.box-wrapper-PT-right {
  flex: 1;
  /* background-color: rosybrown; */
}
.item-row-first-PT {
  display: flex;
  justify-content: space-between;
}
.form-group-PT {
  display: flex;
  flex-direction: column;
}
.form-group-PT > label {
  font-size: 12px;
  font-weight: 500;
  color: #616161;
}

.form-group-PT > input {
  border: none;
  background-color: #fafafafa;
  font-size: 18px;
  font-weight: 400;
  color: #0a0a0a;
  /* width: 100%; */
  width: 250px;
}
.form-group-PT > textarea {
  border: none;
  background-color: #fafafafa;
  font-size: 18px;
  font-weight: 400;
  color: #0a0a0a;
  width: 250px;
}
.edit-button-PT {
  padding-top: 32px;
}

.edit-button-PT > button {
  width: 183px;
  height: 36px;
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
}

@media only screen and (max-width: 1024px) {
  .box-wrapper-PT {
    flex-direction: column;
  }
  /* .item-row-first-PT {
    justify-content: unset;
  } */
  .form-group-PT > input {
    font-size: 14px;
  }
  .form-group-PT > textarea {
    font-size: 14px;
  }
  .box-wrapper-PT-left {
      justify-content: center;
      padding-top: 50px;
  }
  .box-wrapper-PT-left > img {
    width: 40%;
  }
  .box-wrapper-PT-right {
      padding-top: 100px;
  }
}
