.ContainerDetailsSPGStore {
  width: 100%;
  height: 100vh;
}

.ContainerMapStoreSPG {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  /* position: relative; */
}
