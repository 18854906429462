.container-add-user-menu {
  width: 100%;
  height: 100vh;
}
.wrapper-search-menu {
  display: flex;
  padding-top: 20px;
}

.add-user__menu-left {
  position: relative;
  height: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}
.add-user__menu-left > input {
  height: 40px;
  width: 395px;
  padding: 22px 60px 22px 20px;
  border-radius: 15px;
  color: var(--txt-color);
  background-color: var(--main-bg);
}

.add-user__menu-left > i {
  font-size: 18px;
  position: absolute;
  right: 20px;
}

.button-add-user-menu {
  background-color: #1571de;
  width: 53px;
  height: 36px;
  /* text-transform: none; */
}

.container__table-add-user {
  padding-top: 24px;
}

.wrapper__table-add-user {
  background-color: #fafafa;
  border-radius: 8px;
}
