.Background {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.Container {
  width: 500px;
  background: #ffffff;
  border-radius: 8px;
  padding-bottom: 28px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  position: relative;
  padding: 32px;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
}

.Header > img {
  width: 120px;
  height: 120px;
}

.Header > span:first-child {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.Header > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.FormValue {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 16px;
}

.Value {
  display: flex;
  width: 100%;
}

.ValueControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.ValueControl > label {
  color: #616161;
  font-size: 14px;
  font-weight: 500;
}

.InputContainer {
  display: flex;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 10px;
}

.InputAdornment {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputAdornment > span {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
  padding-right: 5px;
}

.InputContainer > input {
  width: 100%;
  border: 0;
  outline: none;
  padding-left: 5px;
  padding-right: 10px;
  /* padding-inline: 10px; */
  padding-block: 10px;
  height: 30px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.ActionButton {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
}

.ActionButton > button:first-child {
  text-transform: none;
  background: #ffffff;
  border: 1px solid #1571de;
  border-radius: 8px;
  color: #1571de;
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
}

.ActionButton > button:last-child {
  text-transform: none;
  background: #1571de;
  border-radius: 8px;
  color: #ffffff;
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
}
