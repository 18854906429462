.Background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.Container {
    width: 564px;
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
}

.ModalHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 24px;
}

.ModalHeader>span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.BoxFormWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}


.BoxFormWrapper label {
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}

.ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 8px;
}

.ButtonsWrapper button {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    text-transform: capitalize;
    font-size: 14px;
}