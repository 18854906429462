.ContainerMDStore {
  width: 100%;
  /* height: 100%; */
  min-height: calc(100vh-130px);
}

.HeaderMDStore {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
}

.ContainerTableMDStore {
  padding-top: 25px;
}
