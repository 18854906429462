.LayoutSA_content {
  padding-left: 281px;
  /* min-height: 100vh; */
}

.LayoutSA_content-main {
  padding: 20px;
  background-color: #fafafafa;
  /* height: 100vh; */

  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: 100vh;
  /* min-height: calc(100vh - 130px); */
}

.mini__screen-layout {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .LayoutSA_content {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .LayoutSA_content {
    padding-left: 0;
  }
}

@media screen and (max-width: 1000px) {
  .LayoutSA {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .mini__screen-layout {
    display: none;
  }
}

/* @media (max-width: 1024px) {
  .LayoutSA_content {
    padding-left: 0;
  }
} */

/* @media (min-width: 1024px) {
  .LayoutSA_content {
    padding-left: 0;
  }
} */