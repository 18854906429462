.Container {
  width: 100%;
  min-height: 100vh;
  padding-top: 0;
}

.Wrapper {
  display: flex;
  margin-top: 24px;
  min-height: 100vh;
}

.Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 42px;
  gap: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
  align-self: flex-start;
}

.Left > span {
  margin: 24px;
}

.MainAvatar {
  width: 150px !important;
  height: 150px !important;
}

.Edit {
  display: flex;
  gap: 6.5px;
  padding: 0 !important;
}

.Edit span {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.Edit img {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}

.Right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.ID {
  background-color: #fff;
  padding: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.IDHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.IDHeader span:first-child {
  font-size: 20px;
  font-weight: 500;
  color: #404040;
  margin-bottom: 0;
}

.RequestWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
}

.RequestWrapper span:first-child {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
  color: #0a0a0a;
}

.RequestWrapper > span:last-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 10px;
  border-radius: 50px;
}

.NoRequest {
  border: 1px solid #0a0a0a;
}

.Pending {
  color: #fff;
  background-color: #e9b62f;
}

.Success {
  color: #fff;
  background-color: #22970f;
}

hr {
  margin-block: 16px;
  background-color: #dedede !important;
  height: 1px;
  border: none;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.BoxContent {
  display: flex;
}

.BoxContent > span {
  font-size: 14px;
  color: #404040;
}

.BoxContent span:first-child {
  width: 30%;
  font-size: 14px;
  color: #616161;
  font-weight: 400;
}

.Files,
.EpaySlips {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Files span,
.EpaySlipsHeader span,
.Teams span {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}

.AnnualLeave {
  background-color: #fff;
  /* padding: 24px; */
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  max-height: 450px;
  overflow-y: auto;
}

.Setter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid#EDEDED;
  position: sticky;
  top: 0;
  background-color: #fff;
}

.AnnualLeaveInfo {
  display: flex;
  flex: 1;
  align-items: center;
}

.AnnualLeaveInfo:not(.SectionText) {
  gap: 8px;
}

.SectionText {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 16px;
}

.SectionText > p {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
}

.LeaveBox {
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  border-radius: 8px;
  background-color: #d0e3f84d;
  gap: 5px;
}

.LeaveBox > span:first-child {
  font-size: 0.75rem;
  font-weight: 400;
  color: #9e9e9e;
}

.LeaveBox > span:last-child {
  font-size: 0.85rem;
  font-weight: 400;
  color: #000;
}

.CreatedAt {
  font-size: 0.75rem;
  color: #9e9e9e;
  font-weight: 400;
}

.ButtonWrapper {
  display: flex;
  gap: 0.5rem;
}

.ButtonAction {
  display: flex;
  /* flex: 1; */
  /* justify-content: flex-end; */
}

.ButtonAction > button {
  background-color: #1571de;
  color: #fff;
  text-transform: none;
  font-size: 0.85rem;
  padding: 8px;
  font-weight: 400;
  border-radius: 8px;
}

.ButtonAction > button:hover {
  background-color: #1571de;
}

.Description {
  width: 100%;
  font-size: 1rem;
  color: #404040;
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AnnualLeaveContent {
  /* padding-top: 1rem; */
  /* padding-inline:24px; */
  padding-bottom: 2rem;
  max-height: 328px;
}

.HaveAnnual {
  display: flex;
  flex-direction: column;
}

.ContainerEachAnnual {
  border-bottom: 1px solid#EDEDED;
}

.EachAnnual {
  display: flex;
  gap: 0.5rem;
  /* border-bottom: 1px solid#EDEDED; */
  padding-inline: 2rem;
  padding-block: 1rem;
}

.Icon {
  width: 2.35rem;
  height: 2.35rem;
  border-radius: 8px;
  padding: 8px;
  background-color: #d0e3f84d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Icon > img {
  width: 1.25rem;
  height: 1.25rem;
}

.TitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Title {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}

.TitleText {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}

.TotalBox {
  background-color: #d42701;
  border-radius: 8px;
  padding: 4px 8px;

  font-size: 0.75rem;
  color: #fff;
  font-weight: 400;
}

.TotalBoxDefault {
  background-color: #ededed;
  border-radius: 8px;
  padding: 4px 8px;

  font-size: 0.75rem;
  color: #0a0a0a;
  font-weight: 400;
}

.Date {
  padding-top: 5px;
}

.Date > span {
  font-size: 1rem;
  color: #404040;
  font-weight: 400;
}

/* .ValueLeave {
    width: 100%;
  }
  
  .ValueLeave > span { 
    color: #9E9E9E;
    font-size: 0.75rem;
    font-weight: 400;
  } */

.Read {
  font-size: 0.875rem;
  color: #1571de;
  font-weight: 500;
  cursor: pointer;
}

.Hide {
  font-size: 0px;
}

.WithoutAnnual {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.625rem;
  padding-block: 1rem;
}

.WithoutAnnual > img {
  width: 2.5rem;
  height: 2.5rem;
}

.WithoutAnnual :where(span) {
  font-size: 1rem;
  font-weight: 500;
  color: #404040;
}

.Organizers,
.Subordinates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  column-gap: 8px;
  row-gap: 8px;
}

.OrganizersWrapper {
  margin-top: 16px;
  margin-bottom: 18px;
}

.TeamsSubheader,
.SubordinatesSubheader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 8px;
}

.TeamsSubheader span,
.SubordinatesSubheader span {
  font-size: 14px;
  font-weight: 500;
  color: #616161;
}

.TeamsSubheader img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  cursor: pointer;
}

.TeamsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  min-height: 192px;
  padding: 20px 10px;
}

.TeamsCardImg {
  width: 100px !important;
  height: 100px !important;
  margin-bottom: 16px;
}

.TeamsCard > span:first-child {
  text-align: center;
  font-size: 16px;
  font-weight: 500px;
  margin-bottom: 4px;
  color: #0a0a0a;
}

.TeamsCard > span:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #616161;
  text-align: center;
}

.EpaySlipsHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.YearSelect {
  /* sx={{ width: "100%", height: '30px', backgroundColor: '#fff' }} */
  width: 100%;
  height: 30px;
  background-color: #fff;
}

.CardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  column-gap: 8px;
  row-gap: 8px;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 72px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.Card img {
  width: 16px;
  height: 20px;
  object-fit: cover;
}

.Card span {
  font-size: 16px;
}

.Pointer {
  cursor: pointer;
}

.NoSlip {
  color: #606060;
  font-size: 14px;
}

.Pending {
  background: #e9b62f !important;
  color: #fff !important;
  border: 1px solid #e9b62f !important;
}

.StaffController {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.StaffController span {
  color: #606060;
}

.ViewMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 20px;
}

.ViewMore button {
  color: #1571de;
  background-color: transparent;
}

.Capitalized {
  text-transform: capitalize !important;
}

.LoanInfo {
  display: flex;
  flex-direction: column !important;
  gap: 8px;
}

.LoanInfoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LoanInfoHeader > span:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
}

.LoanStatus {
  display: block;
  border-radius: 50px;
  padding: 8px 10px;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.LoanInfoCard {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px 24px 18px 24px;
}

.LICHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
}

.LICHeaderLeft {
  display: flex;
  flex-direction: column !important;
}

.LICHeaderLeft > span :first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
  margin-bottom: 2px;
}

.LICHeaderLeft > span:nth-child(2) {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
  margin-bottom: 6px;
}

.LICHeaderLeft > span:nth-child(3) {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
}

.LICHeaderRight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LICHeaderRight > img {
  width: 69px !important;
  height: 69px !important;
  object-fit: contain;
}

.LICContent {
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-top: 16px;
}

.LICBoxContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.LICBLeft {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.LICBRight {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.LICBLeft > span:first-child,
.LICBRight > span:first-child {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
}

.LICBLeft > span:nth-child(2),
.LICBRight > span:nth-child(2) {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #0a0a0a;
}

.InstallmentInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.InstallmentCardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  column-gap: 24px;
  row-gap: 8px;
}

.InstallmentCard {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.InstallmentCard > span:first-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  margin-bottom: 8px;
}

.InstallmentCard > span:nth-child(2) {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #404040;
  margin-bottom: 16px;
}

.StatusPayment {
  display: flex;
  align-items: center;
  gap: 9px;
}

.StatusPayment > span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
}

.Paid {
  color: #255bfc !important;
}

.Unpaid {
  color: #d1421a !important;
}
