.container-data-loan {
  width: 100%;
  height: 100vh;
}
.wrapper__setting-data-loan {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 24px;
}
.setting-menu-left-data-loan {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  overflow: hidden;
}
.data-loan-menu-right {
  display: flex;
  align-items: center;
  column-gap: 5px;
  /* background-color: red; */
}
/* .wrapper-table-data-loan {
  padding-top: 24px;
} */

/* .data-loan-menu-right-download {
  margin-left: auto;
} */

.data-loan-menu-right-download > button {
  text-transform: none;
  color: #ffffff;
}
.wrapper-box-show-another {
  padding-top: 24px;
  position: relative;
}
.box-show-another {
  width: 459.5px;
  height: 76px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  column-gap: 20px;
}
.text-box {
  position: absolute;
  top: 12px;
  left: 25px;
  background-color: #fbfbfb;
  border: none;
}
.box-group-selected > input {
  /* width: 273px; */
  height: 40px;
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 8px;
}

.status__styleSA {
  border-radius: 8px;
  padding: 3px 10px;
  display: inline-block;
  border: 1px solid;
  border-radius: 6px;
}

@media screen and (max-width: 1300px) {
  .wrapper__setting-data-loan {
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: unset;
    column-gap: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .wrapper__setting-data-loan {
    row-gap: 20px;
    column-gap: 20px;
  }
  .data-attendance-menu-right {
    padding-left: 0;
  }
}
@media (max-width: 1024px) {
  .data-loan-menu-right {
    column-gap: 10px;
  }
  .wrapper__setting-data-loan {
    justify-content: unset;
    row-gap: 20px;
    column-gap: 10px;
  }
  .data-attendance-menu-right-download {
    margin-left: 0;
    /* padding-left: 32px; */
  }
}
