@keyframes fadeOut {
  0% {
    background-color: #cdd2db;
  }
  50% {
    background-color: #dee2e7;
  }
  100% {
    background-color: #edeff2;
  }
}

.wrapperLoadSkeleton {
  width: 100%;
  aspect-ratio: 10/2;
  background-color: #cdd2db;
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate;
  border-radius: 4px;
}

.circleSkeleton {
  aspect-ratio: 1/1;
  background-color: #cdd2db;
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate-reverse;
  border-radius: 50%;
}

.boxSkeleton {
  aspect-ratio: 1/1;
  background-color: #cdd2db;
  cursor: progress;
  animation: fadeOut infinite 0.8s linear alternate-reverse;
  border-radius: 4px;
}
