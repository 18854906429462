.TopNav {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  border-bottom: 0.5px solid #e7e7e7;
  position: sticky;
  top: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
}

.TopNavRight {
  display: flex;
  align-items: center;
}

.TopNavRightItem ~ .TopNavRightItem {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.TopNavRightUser {
  display: flex;
  align-items: center;
}

.Image {
    width: 50px;
    height: 50px;
    /* border-radius: 50%; */
    overflow: hidden;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

.TopNavRightUserName {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.TopNavRightUserName > span:first-child {
  font-size: 14px;
  font-weight: 500;
}

.TopNavRightUserName > span:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #00000099;
}

.Image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 10px;
}

.Select {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px !important;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6) !important;
}

.Autocomplete {
  padding-left: 15px;
}

@media screen and (max-width: 1200px) {
  .TopNav {
    justify-content: unset;
  }

  .TopNavRight {
      margin-left: auto;
  }
}


@media (min-width:1201px) {
    .TopNavIcon{
      display: none;
    }
  }
