.Container {
    width: 100%;
    min-height: 100vh;
    padding-top: 24px;
    padding-inline: 8px;
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.ProfileCard {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

.ProfileLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.ProfilePicture {
    width: 84px !important;
    height: 84px !important;
}

.ProfileDesc {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.ProfileDesc>span:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #404040;
}

.ProfileDesc>span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #B1B1B1;
}

.ProfileDesc>span:nth-child(3)   {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #B1B1B1;
}

.ProfileDesc>span:nth-child(3)  >  label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #0A0A0A;
}

.ProfileRight {
    /* height: 100%;
    width: 50%;
    background: #F4F4F4;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: space-between; */
    display: flex;
    align-items: center;
    gap: 8px;
}

.BoxAmount {
    width: 100%;
    height: 100%;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    border-radius: 4px;
    background: #F8F8F8;
}

.AmountIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}

.AmountIcon > span {
    font-size: 14px;
    font-weight: 500;
    color: #989898;
}

.AmountIcon > img {
    width: 16px;
    height: 16px;
}

.AmountDesc {
    display: flex;
    align-items: center;
    gap: 5px;
}

.AmountDesc > img {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

/* .BoxAmount img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.AmountDesc {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.AmountDesc>span:first-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #989898;
}

.AmountDesc>span:nth-child(2) {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #333333;
} */

/* SUMMARY CARD */

.SummaryCard {
    width: 100%;
    height: 100%;
}