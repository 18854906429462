/* .container-customer-external {

} */

.new-customer-table-bottom {
  padding-top: 24px;
}

.wrapper-item-top-customer {
  display: flex;
  justify-content: space-between;
}
.table-customer-bottom {
  padding-top: 24px;
}
