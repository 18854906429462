.PageWrapper {
    display: flex;
    align-items: center;
}

.PageHeader {
    margin-bottom: 40px;
    text-transform: capitalize;
    font-weight: 500;
    color: #0a0a0a;
    font-size: 40px;

}