.Container {
  padding-top: 32px;
  width: 100%;
  min-height: 100vh;
}
.Profile {
  display: flex;
}

.ProfileDescWrapper {
  flex: 1;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.ProfileDescWrapper > img {
  width: 79px;
  height: 79px;
}

.ProfileDesc {
  display: flex;
  flex-direction: column;
}

.ProfileDesc > label {
  color: #0a0a0a;
  font-size: 24px;
  font-weight: 500;
}

.ProfileDesc > h6 {
  color: #757575;
  font-size: 20px;
  font-weight: 500;
}

.ProfileDesc > span {
  color: #404040;
  font-size: 20px;
  font-weight: 500;
}
.Status {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Box {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 103px;
  height: 38px;
  background: #498e0f;
  border-radius: 50px;
}

.Box > span {
  color: #fafafa;
}

.LoanDetails {
  padding-top: 24px;
}

.Label > span {
  color: #0a0a0a;
  font-weight: 500;
  font-size: 20px;
}

.DetailLoanBox {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 8px;
}

.LoanInfo {
  display: flex;
  align-items: center;
}

.LoanInfoText {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.LoanInfoText > label {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 500;
}

.LoanInfoText > span:nth-child(2) {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
}

.LoanInfoText > span:nth-child(3) {
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.LoanInfoText > h5 {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
}

.Logo {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 120px;
}

.Divider {
  width: 100%;
  padding-top: 24px;
}

.DateSubmission {
  padding-top: 20px;
}

.WrapperDateSubmission {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DateDetails {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 250px;
  /* background-color: blue; */
}

.DateDetails > label {
  color: #616161;
  font-size: 18px;
  font-weight: 500;
}

.DateDetails > span {
  color: #0a0a0a;
  font-size: 20px;
  font-weight: 400;
}

.DetailHistory {
  padding-top: 24px;
}

.ItemsDetailHistory {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.ItemPaymentHistory {
  width: 186px;
  height: 116px;
  background: #fafafa;
  border-radius: 8px;
  padding: 10px;
}

.ItemPaymentWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 186px;
  height: 116px;
}

.ItemPaymentWrapper > label {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.ItemPaymentWrapper > span {
  color: #404040;
  font-size: 20px;
  font-weight: 500;
}


