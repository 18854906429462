/* .rdw-editor-toolbar {
  background: red !important;
} */

.rdw-image-modal {
  left: -200px !important;
}

.rdw-emoji-modal {
  left: -200px !important;
}

.rdw-embedded-modal {
  left: -200px !important;
  height: 210px !important;
}

.rdw-embedded-wrapper {
  display: none !important;
}

.rdw-emoji-wrapper {
  display: none !important;
}

.rdw-image-wrapper {
  display: none !important;
}
