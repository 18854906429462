$PRIMARY-MAIN: #1571de;

@mixin flex($gap: 0) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}
@mixin flexCol($rowGap: 0) {
  display: flex;
  flex-direction: column;
  gap: $rowGap;
}

.wrapperDetailPromotorAdminIndosat {
  @include flexCol(12px);
  .profiles {
    @include flex(20px);
    justify-content: space-between;
    .profilePicture {
      @include flexCol(10px);
      align-items: center;
      img {
        border-radius: 50%;
        width: 114px;
        height: 114px;
        object-fit: contain;
      }
      > div {
        @include flex(4px);
        align-items: center;
        > span {
          color: $PRIMARY-MAIN;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        > .icon {
          cursor: pointer;
          flex-shrink: 0;
        }
      }
    }
  }
  .name {
    @include flexCol();
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #d9d9d9;
    background: #fff;
    > h2 {
      color: #404040;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
    }
    .divider {
      background-color: #ededed;
      height: 1px;
      width: 100%;
      margin-block: 1rem;
    }
    .labelValue {
      @include flex();
      .label {
        width: 122px;
        @include flexCol(1rem);
        span {
          color: #616161;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .value {
        width: 164px;
        @include flexCol(1rem);
        justify-content: flex-end;
        align-items: flex-end;
        span {
          color: #404040;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .totalCardOnHand {
    @include flexCol();
    padding: 1rem;
    border-radius: 16px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
    min-width: 201px;
    width: 100%;
    .wrapSimCard {
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      background-color: #ffe5a8c9;
      @include flex();
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: 4px;

      .icon {
        flex-shrink: 0;
      }
    }
    > span {
      color: #202224;
      font-size: 14px;
      opacity: 0.7;
      > span {
        font-style: italic;
      }
    }
    > h1 {
      color: #202224;
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      &.isSales {
        margin-top: 24px;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }
  .table {
    @include flexCol(12px);
    .head {
      width: 100%;
      @include flex();
      justify-content: space-between;
      align-items: center;
      h4 {
        color: #0a0a0a;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
      }
      .wrappBtnDropdown {
        width: fit-content;
        position: relative;
        .dropdown {
          z-index: 999;
          width: 100%;
          position: absolute;
          left: 0;
          top: 2.5rem;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
          ul li {
            list-style: none;
            padding: 10px 8px;
            color: #404040;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            &:hover {
              background-color: #cbe3ff6e;
              &:last-child {
                border-top-right-radius: unset;
                border-top-left-radius: unset;
              }
            }
          }
        }
      }
    }
  }
}
