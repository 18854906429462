.Modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalContainer {
    display: flex;
    align-items: center;
    width: 600px;
    height: 700px;
    border-radius: 12px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    overflow: auto;
}

.ModalBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.Title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 8px;
    background-color: #E0E0E0;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
}

.Body{
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.Component{
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.MockContainer{
    display: flex;
    flex-direction: column;
    padding: 26px 12px;
    gap: 10px;
    width: 100%;
}

.MockHeader{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.MockHeader>span{
    color: #000000;
    font-weight: 530;
    font-size: 12px;
    align-self: center;
    text-align: center;
    width: 100%;
}

.MockBack{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background-color: #ededed;
    border-radius: 50px;
    font-size: 12px;
}

.MockBanner{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.MockTitle{
    display: flex;
    flex-direction: column;
    gap: 1px;
    justify-content: flex-start;
    align-items: flex-start;
}

.MockTitle>span:first-child{
    color: #000000;
    font-weight: 510;
    font-size: 12px;
}

.MockTitle>span:last-child{
    color: #868788;
    font-weight: 400;
    font-size: 10px;
}

.MockContent{
    width: inherit;
    display: flex;
    flex-direction: column;
    gap: 1px;
    justify-content: flex-start;
    align-items: flex-start;
}

.MockContent>span:first-child{
    color: #000000;
    font-weight: 510;
    font-size: 12px;
}

.MockContent>p{
    color: #000000;
    font-weight: 400;
    font-size: 10px;
    text-overflow: ellipsis;
}

.MockButtonLayout{
    width: 100%;
    padding: 8px 12px 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    bottom: 0;
    position: absolute;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.MockButtonContained{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    color: #fff;
    background-color: #1571de;
    border-radius: 50px;
    padding: 4px;
}

.MockButtonOutlined{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    color: #1571de;
    background-color: #fff; 
    border: 1px solid #1571de;
    border-radius: 50px;
    padding: 4px;
}

.MockButtonContained>span,
.MockButtonOutlined>span{
    font-size: 10px;
    font-weight: 400;
}

.MockDivider{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.Divider{
    height: 1px;
    width: 14px;
    border: 1px solid #c8c8c8;
}

.MockDivider>span{
    color: #c8c8c8;
    font-size: 10px;
    font-weight: 400;
}

.MockNotification{
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 150px;
}

.MockNotificationCard{
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background-color: rgba(144, 167, 188, 0.9);
    gap: 8px;
}

.MockNotifText{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.MockNotifTitle{
    display: flex;
    justify-content: space-between;
}

.MockNotifTitle>span:first-child{
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}

.MockNotifTitle>span:last-child{
    font-size: 10px;
    font-weight: 500;
    color: #5b6272;
}

.MockNotifDesc{
    display: inline-block;
    width: 170px;
    white-space: nowrap;       
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    color: #dee8eb;
    font-weight: 300;
}

