.modal-MAS {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container-MAS {
  width: 747px;
  height: 700px;
  background: #ffffff;
  border-radius: 12px;
  padding-inline: 20px;
}
