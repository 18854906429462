.Container {
    width: 100%;
    min-height: 100vh;
}


.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    height: 40px;
}

.HeaderLeft {
    display: flex;
    align-items: center;
    gap: 16px;
}

.Searchbar {
    position: relative;
    height: 40px;
    background-color: var(--main-bg);
    display: flex;
    align-items: center;
    overflow: hidden;
}

.Select {
    height: 40px;
    border-radius: 8px !important;
    min-width: 128px;
    background-color: var(--main-bg);
}

.AutoComplete {
    width: 210px;
    background-color: var(--main-bg);
}

.UserButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.EditButton {
    border-radius: 8px !important;
    color: #1571DE !important;
    border: 1px solid #1571DE !important;
    text-transform: capitalize !important;
    min-width: 46px;
    padding: 0;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.AddButton {
    border-radius: 8px !important;
    color: #fff !important;
    background-color: #1571DE !important;
    text-transform: capitalize !important;
    min-width: 112px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
}

.TableContainer {
    min-width: 600px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    border-bottom: 0;
}

.Table {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
}

.Plus {
    cursor: pointer;
    color: #1571DE;
    font-size: 12px !important;
    margin-left: 6px;
}

.PopoverDiv {
    max-width: 200px;
    padding: 5px 8px;
}

.PopoverDiv span {
    font-size: 12px;
    color: #0a0a0a;
    text-transform: capitalize !important;
}

.TableSortLabel {
    color: #0a0a0a !important;
    font-weight: 500;
    font-size: 14px;
}

.TableCell {
    font-size: 14px;
    font-weight: 400;
    color: #0a0a0a;
}

.MWS {
    display: block;
    text-align: center;
    padding-inline: 8px;
    width: 85px;
    height: 20px;
    background: rgba(208, 227, 248, 0.3);
    border: 1px solid #1571DE;
    border-radius: 6px;
    color: #1571DE;
    font-size: 12px !important;
}

.NonMWS {
    display: block;
    text-align: center;
    width: 85px;
    height: 20px;
    background: #FFF9E9;
    border: 1px solid #E9B62F;
    border-radius: 6px;
    color: #E9B62F;
    font-size: 12px !important;
}

.DeleteCell img {
    height: 17px;
    width: 15px;
    object-fit: cover;
    cursor: pointer;
}

.Name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
    text-transform: capitalize;
}

.Avatar {
    width: 30px !important;
    height: 30px !important;
}