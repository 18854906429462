.Container {
    width: 100%;
    height: 100vh;
}

.Wrapper {
    padding-left: 32px;
    padding-right: 8px;
    padding-top: 23px;
    padding-bottom: 24px;
}

.WrapperBox {
    display: flex;
    flex-direction: column;
}

.ItemBox {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    width: 50%;
}

.ItemBoxSpan {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.ItemBoxTitle {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    width: 100%;
}

.ItemBoxTitle span {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.ItemBoxInput {
    width: 100%;
    height: 40px;
    background: #fff;
    border: 1px solid #d3c9c9;
    border-radius: 8px;
    padding-left: 10px;
}

.ItemBoxInput:disabled{
    width: 100%;
    height: 40px;
    background: #E0E0E0;
    border: 1px solid #C2C2C2;
    border-radius: 8px;
    padding-left: 10px;
    color: #C2C2C2;
}

.ItemBoxContent {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    padding-top: 24px;
}

.ItemBoxContent span {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.ItemBoxContent textarea {
    height: 178px;
    background: #fff;
    border: 1px solid #d3c9c9;
    border-radius: 8px;
    padding-inline: 10px;
    padding-block: 10px;
}

.ItemBoxContactInformation {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    padding-top: 24px;
}

.ItemBoxContactInformation span {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.ContactInformationWrapper{
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
}

.CardContactInformation{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    box-shadow: 0px 0px 4px 2px #00000040;
    background-color: #FFFFFF;
    padding: 12px;
    border-radius: 8px;
}

.TopContactInformation{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.TitleContactInformation{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: start;
}

.ItemBoxSetupPushNotification{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 24px;
}

.TitleSetupPushNotification{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.FillPushNotification{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
}

.BoxPushNotification{
    display: flex;
    flex-direction: row;
    gap: 16px;
    box-shadow: 0px 0px 4px 2px #00000040;
    border-radius: 8px;
    align-items: center;
    padding: 8px 12px;
}

.ComponentNotification{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;
}

.ComponentNotification>span{
    color: #757575;
    font-size: 12px;
    font-weight: 500;
}
.ComponentNotification>p{
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    justify-content: flex-start;
    align-items: center;
}

.ActionLayout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.CenterEmptySetupPushNotification{
    display: flex;
    width: 100%;
    padding: 24px;
    border: 1px solid #D9D9D9;
    background-color: #FFFFFF;
}

.EmptySetupPushNotification{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    padding-top: 12px;
}

.ContainerUploadImage {
    margin-block: 20px;
    display: flex;
    row-gap: 24px;
    /* justify-content: center; */
}

.WrapperUploadImage {
    text-align: center;
    width: 100%;
    height: 190px;
    background: rgba(211, 226, 255, 0.1);
    border: 1px dashed #9e9e9e;
    border-radius: 8px;
    padding: 16px;
}

.BoxInputImage {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.BoxInputImage>span {
    font-size: 20px;
    font-weight: 400;
    color: #616161;
}

.BoxInputFile span {
    font-size: 16px;
    font-weight: 500;
    color: #c2c2c2;
}

.BoxInputOr span {
    font-size: 20px;
    font-weight: 500;
    color: #616161;
    padding-bottom: 16px;
}

.ContainerInputImage {
    position: absolute;
    top: 10px;
    left: 250px;
    z-index: 1;
}

.SetupPushNotificationButtonWrapper{
    display: flex;
    align-items: center;
    gap: 8px;
}

.BoxInputButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.BoxInputButton>button {
    width: 126px;
    height: 40px;
    border: 1px solid #1571de;
    border-radius: 8px;
    text-transform: none;
}

.ButtonHelper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 10px;
    padding-top: 24px;
}

.ButtonHelper>button {
    text-transform: none;
}

.ButtonHelper>span {
    text-transform: none;
    color: #1571de;
    margin-left: 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1571DE;
}

.ClientsChipContainer {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    margin-block: 8px;
    padding-right: 5px;
}

.ClientsChip {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 4px 12px;
}

.ClientsChip span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #404040;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CancelIcon {
    font-size: 10px !important;
    color: #404040;
    cursor: pointer;
}

.ClientOption {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 24px;
    color: #0A0A0A !important;
}