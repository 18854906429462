/* .container__user-detail-menu {
  width: 100%;
  height: 100vh;
}

.wrapper-user-detail-menu-download {
  display: flex;
  padding-top: 20px;
  align-items: center;
}

.user__detail-left {
  position: relative;
  height: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  overflow: hidden;
}
.user__detail-left > input {
  height: 40px;
  width: 395px;
  padding: 22px 60px 22px 20px;
  border-radius: 15px;
  color: var(--txt-color);
  background-color: var(--main-bg);
} */
/* .user__detail-left > i {
  font-size: 18px;
  position: absolute;
  right: 20px;
}
.data-attendance-menu-right {
  display: flex;
} */
.dropdown-btn-user-detail {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow: hidden;
}

.box-dropdown-user-detail-menu {
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 8px;
  width: 146px;
  height: 40px;
  position: relative;
  user-select: none;
  cursor: pointer;
}
.box-dropdown-user-detail-menu .dropdown-content-user-detail {
  position: absolute;
  background: #ffffff;
  padding: 5px;
  color: #9e9e9e;
  top: 110%;
  width: 95%;
  z-index: 1;
}
.box-dropdown-user-detail-menu
  .dropdown-content-user-detail
  .dropdown-item-user-detail {
  padding: 10px;
  cursor: pointer;
}
.box-dropdown-user-detail-menu
  .dropdown-content-user-detail
  .dropdown-item-user-detail:hover {
  background-color: #fafafa;
}

.button__add-user-userDetail {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.button__add-user-userDetail > button {
  text-transform: none;
  width: 142px;
  height: 40px;
  border-radius: 4px;
  color: white;
}

/* .wrapper-table-user-detail-menu {
  padding-top: 24px;
}
.status__style-user-detail {
  background-color: grey;
  color: white;
  border-radius: 8px;
  padding: 3px 10px;
  display: inline-block;
} */
