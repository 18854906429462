.Container {
  /* background: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 24px;
}

.Image {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 40vh; */
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Wrapper  > div:nth-child(2) {
  padding-top: 10px;
}

.TextLabel {
  font-size: 1.5rem;
  color: #0a0a0a;
  font-weight: 500;
}

.TextSpan {
  font-size: 1rem;
  color: #0a0a0a;
  font-weight: 500;
}

.Button {
  margin-top: 10px;
  width: 67px;
  height: 44px;
  background-color: #1571de;
  border-radius: 12px;
  text-transform: none;
  text-align: center;
  display: flex;
  align-items: center;
}


@media (max-width: 480px) {
  .Wrapper  > div:nth-child(2) {
    text-align: center;
  }
}

