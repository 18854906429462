.ModalControl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}

.WrapperItemModalSuccess {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.Content > label {
  font-size: 20px;
  font-weight: 500;
  color: #0f161f;
}

.Content > span {
  font-size: 12px;
  font-weight: 400;
  color: #757575;
}

.WrapperItemModal {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: -25%;
  left: 50%;
  transform: translateX(-50%);
}

.ItemTopModal > img {
  width: 154px;
  height: 154px;
}

.ContentModal {
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.WrapperButtonModal{
    margin-top: 24px;
}

.TextModal {
  display: flex;
  text-align: center;
  flex-direction: column;
  row-gap: 15px;
}

.TextModal > span:nth-of-type(1) {
  color: #0a0a0a;
  font-size: 26px;
  font-weight: 600;
}

.TextModal > span:nth-of-type(2) {
  color: #757575;
  font-size: 13px;
  font-weight: 400;
}
