.Container {
    width: 100%;
    min-height: 100vh;
    margin-top: 24px;
}

.CardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(445px, 1fr));
    gap: 16px;
    margin-bottom: 24px;
}

.Card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 16px;
}

.CardHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.CardHeader div {
    width: 40px;
    height: 40px;
    background-color: #EBF4FF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CardHeader>span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #404040;
}

.CardLogo img {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.CardContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    /* margin-bottom: 24px; */
}

.CardContent span {
    font-size: 16px;
    font-weight: 400;
    color: #9e9e9e;
    width: 95%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CardContent input {
    width: 5%;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.SaveButton {
    padding: 10px;
    gap: 10px;
    width: 100px;
    height: 40px;
    background: #1571DE;
    border-radius: 8px;
    color: #fff;
}

.CancelButton {
    padding: 10px;
    gap: 10px;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #1571DE;
    color: #1571DE;
    border-radius: 8px;
}

.CardFooter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: auto;
}

.CardFooter span {
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1571DE;
}