.container-workspace-settings {
  padding-top: 32px;
}

.heading-workspace-settings {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.heading-workspace-settings > span:nth-of-type(1) {
  font-size: 18px;
  color: #0a0a0a;
  font-weight: 400;
}

.heading-workspace-settings > span:nth-of-type(2) {
  font-size: 18px;
  color: #0a0a0a;
  font-weight: 400;
}

.workspace-datas {
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  row-gap: 15px;
}

.box-switch-data {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px 14px;
}
.box-switch-data > img {
  width: 37px;
  height: 37px;
}

.box-switch-data > span {
  font-size: 18px;
  color: #0a0a0a;
  font-weight: 500;
  padding-left: 34px;
}

.box-switch-data > div {
  margin-left: auto;
}

.button-workspaces {
  padding-top: 30px;
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.button-workspaces > button:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #1571DE;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.button-workspaces > button:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 10px;
  border: 1px solid #1571DE;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: #1571DE;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
}
