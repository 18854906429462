.notification {
  position: fixed;
  top: -100px; /* start above the viewport */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--Success-Main, #27bb83);
  color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

  /* Text M/Medium */
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding: 8px 12px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: top 0.5s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &.notificationRed {
    border: 1px solid var(--Danger-Main, #d42701);
    background: var(--Danger-Surface, #fff4f2);
    color: var(--Danger-Main, #d42701);
  }

  &.show {
    top: 40px;
  }
}
