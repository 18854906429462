/* .ContainerSalesOverview {
} */

.WrapperSalesOverview {
  flex: 1;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  /* max-height: 386px; */
  height: 386px;
  display: flex;
  flex-direction: column;
}

.HeaderSalesOverview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderSalesOverview > span {
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.ReportSalesOverview {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  margin-top: auto;
}

.SalesOverviewText {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 400;
}

.SalesOveriewSales {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.SalesOveriewSales > :nth-child(1) > span {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.SalesOveriewSales > :nth-child(2) {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.SalesOveriewSales > :nth-child(2) .IconSalesOverview {
  transform: translateY(-2px);
}

.SalesOveriewSales > :nth-child(2) > span {
  color: #1b45d9;
  font-size: 14px;
  font-weight: 400;
  transform: translateY(2px);
}

.SalesOveriewSales > :nth-child(3) > span {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .SalesOveriewSales > :nth-child(1) > span {
    font-size: 18px;
  }
}

@media (max-width: 1100px) {
  .SalesOveriewSales > :nth-child(1) > span {
    font-size: 12px;
  }
}

@media (max-width: 1160px) {
  .SalesOveriewSales > :nth-child(1) > span {
    font-size: 12px;
  }
}
