.wrapper-ACIEM {
  padding-top: 40px;
}

.box-form-ACIEM {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 20px;
}
.box-form-ACIEM > input {
  border: 1.5px solid #d3c9c9;
  background: #fbfbfb;
  border-radius: 8px;
  width: 299px;
  height: 40px;
}

.box-form-ACIEM-desc {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.box-form-ACIEM-desc > input {
  border: 1.5px solid #d3c9c9;
  background: #fbfbfb;
  border-radius: 8px;
  width: 699px;
  height: 40px;
}
.main-edit-scheme-ACIEM {
  padding-top: 20px;
}
.wrapper-edit-scheme-ACIEM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.wrapper-edit-scheme-ACIEM  > span {
  font-size: 20px;
  color: #616161;
  font-weight: 400;
}

.button-edit-scheme-menu-ACIEM {
  display: flex;
  column-gap: 20px;
}

@media (max-width: 1024px) {
  .box-form-ACIEM-desc > input {
    width: 400px;
  }
}
