.Background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Container {
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;
    width: 372px;
}

.ImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 108px;
    border-radius: 50%;
    background-color: #fafafa;
    margin-bottom: 16px;
}

.ImageWrapper > img {
    width: 60px;
    height: 60px;
}

.TitleWrapper {
    clear: left;
    text-align: center;
    margin-bottom: 10px;
}

.TitleWrapper span {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #1571DE;
}

.ContentWrapper {
    text-align: center;
    margin-bottom: 24px;
}

.ContentWrapper span {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: #333333;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.ButtonWrapper button {
    width: 148px;
    text-transform: capitalize;
    border-radius: 8px;
}

.CancelButton {
    border: 1px solid #1571DE !important;
    color: #1571DE !important;
}

.SaveButton {
    background-color: #1571DE !important;
}