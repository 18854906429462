.ContainerReportsTab {
  width: 100%;
}

.WrapperReportsTab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.ItemReportsTab {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 250px;
  height: 113px;
}

.WrapperItemReports {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.WrapperBorder {
  background-color: #1571de;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.WrapperBorder > img {
  width: 22px;
  height: 22px;
}

.WrapperItemReports > i {
  width: 22px;
  height: 22px;
}

.WrapperItemReports > span {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}

.TotalReportsTab {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.TotalReportsTab > span {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
