.employee__manual-container {
  padding-top: 32px;
}

.employee__manual-box {
  background: #fafafa;
  padding: 20px;
}

.employee__external-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 100px;
  row-gap: 20px;
}

.employee__external-text-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.employee__external-text-input > label {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.add__employee-manual-btn {
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding-top: 24px;
}

.add__employee-manual-btn > button {
  text-transform: none;
}

@media screen and (max-width: 1300px) {
  .employee__external-wrapper {
      column-gap: 50px;
  }
}

@media screen and (max-width: 1200px) {
    .employee__external-wrapper {
        flex-wrap: wrap;
    }
  }
