.Background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.Container {
    width: 700px;
    max-height: 700px;
    background: transparent;
    border-radius: 5px;
    padding: 0;
}

.Container span {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 25px;
    height: 25px;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    margin-left: 665px;
    margin-top: 10px;
    /* margin-top: 5px;
    margin-left: px; */
}

.Container span:before,
.Container span:after {
    position: absolute;
    left: 12px;
    content: " ";
    height: 15px;
    width: 2px;
    background-color: #404040;
}

.Container span:before {
    transform: rotate(45deg);
}

.Container span:after {
    transform: rotate(-45deg);
}

.Container img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.EmptyContainer {
    background-color: #fff;
    width: 700px;
    height: 130px;
    border-radius: 8px;
    display: flex;
}

.EmptyContainer div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}