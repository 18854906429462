.box-settings-ACIW {
  width: 330px;
  height: auto;
  background: #fafafa;
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 8px;
  row-gap: 24px;
  column-gap: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.add-more-workspace-schema {
  width: 330px;
  height: 106px;
  background: #fafafa;
  border: 1px solid #1571de;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 24px; */
  column-gap: 10px;
  cursor: pointer;
  color: #1571DE;
  font-weight: 500;
  font-size: 20px;
}

.button-workspaces-settings {
  display: flex;
  /* column-gap: 8px; */
  justify-content: space-between;
  margin-top: auto;
}

.button-workspaces-settings > button:nth-of-type(1) {
  text-transform: none;
  outline: none;
  padding: 0;
  margin: 0;
}

.button-workspaces-settings > button:nth-of-type(2) {
  text-transform: none;
  outline: none;
  padding: 0;
  margin: 0;
}
