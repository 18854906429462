.ContainerWrapper {
    margin-block: 24px;
    margin-left: 32px;
}

.ContainerWrapper>span {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
}

.Container {
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-top: 16px;
}

.BoxHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
}

.BoxHeader>span {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
}

.BoxHeader img {
    width: 18px;
    height: 18px;
}

.BoxForm {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}

.BoxForm>span:first-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
}

.BoxForm>span:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}