@font-face {
    font-family: arlon;
    src: url(../../../assets/font/arlon/ArlonSemiBold-DEMO.otf) format("truetype");
  }
  
  .sidebar {
    min-width: var(--sidebar-width);
    width: 281px;
    /* height: 100vh; */
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--main-bg);
    background-color: #ffffff;
    /* box-shadow: var(--box-shadow); */
    overflow-x: auto;
    padding-bottom: 20px;
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
  }
  .sidebar::-webkit-scrollbar {
    display: none;
  }
  
  .sidebar__logo {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 30px;
    cursor: pointer;
    column-gap: 20px;
  }
  .sidebar__logo > span {
    font-family: arlon;
    font-size: 16px;
    font-weight: normal;
  }
  
  .sidebar__logo > img {
    width: 62px;
    height: 52px;
    cursor: pointer;
  }
  
  .sidebar__item {
    padding: 0 20px;
  }
  
  .sidebar__item-inner {
    padding: 10px 15px;
    /* padding: 15px; */
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: color 0.3s ease 0;
    column-gap: 10px;
    width: 100%;
  }
  
  .sidebar__item-inner > i {
    font-size: 18px;
    font-weight: 500;
  }
  
  .sidebar__item-inner > span {
    padding-top: 2px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .sidebar__item-inner.active {
    border-radius: 8px;
    /* border-radius: var(--border-radius); */
    padding: 10px;
    background-color: #6cabf8;
    /* background-image: linear-gradient(
      to right,
      var(--main-color),
      var(--second-color)
    ); */
    color: var(--txt-white);
  }
  
  /* @media (max-width: 768px) {
      .sidebar {
           display: none;
      }
  } */
  
  .wrapper__sidebar {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: 100%;
    gap: 20px;
  }
  
  .helpdesk__container {
    padding: 16px 16px 24px 16px;
    border-top: 1px solid #ededed;
    background: #f1f7fd;
    /* height: 130px; */
  }
  
  .helpdesk__wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .helpdesk__image-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  
  .helpdesk__image-wrapper > img {
    width: 171px;
    height: 71px;
  }
  
  .helpdesk__item-content {
    display: flex;
    background-color: #1571de;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
  }
  
  .helpdesk__container-box {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .helpdesk__circle-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    padding: 8px;
  }
  
  .helpdesk__circle-box > img {
    width: 24px;
    height: 24px;
  }
  
  .helpdesk__content-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .helpdesk__content-text > span:first-child{
    font-size: 17px;
    font-weight: 400;
    color: #FFF;
  }
  
  .helpdesk__content-text > span:last-child{
    font-size: 12px;
    font-weight: 400;
    color: #FFF;
  }
  
  .SidebarLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 20px;
    text-decoration: none;
    transition-duration: 0.5s;
  }
  
  .SidebarLabel {
    /* margin-left: 16px; */
    font-size: 16px;
    font-weight: 500;
  }
  
  .DropdownLink {
    height: 60px;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;
  }
  
  /* @media screen and (max-width: 1024px) {
    .sidebar {
      z-index: 100;
    }
  } */
  