.container-IU {
  width: 100%;
  /* height: 100vh; */
}
.wrapper-IU {
  /* width: 943px; */
  height: 182px;
  background: #fafafa;
  padding: 10px;
  /* padding-top: 20px; */
}
.box-item-IU {
  /* padding-top: 20px; */
  /* width: 919px; */
  height: 98px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  padding-inline: 10px;
  /* padding-block: 10px; */
}

.title-blue-IU {
    /* padding-bottom: 10px; */
}
.title-blue-IU > span {
  font-size: 12px;
  font-weight: 400;
  color: #599edb;
}

.item-main-IU {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.item-left-IU {
    /* padding-bottom: 10px; */
  /* padding-top: 8px; */
}
.item-left-IU > span {
  font-size: 16px;
  font-weight: 500;
}
/* .item-right-IU {

} */
/* .item-right-IU {

} */

.item-bottom-IU > span {
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
  /* padding-bottom: 20px; */
}
