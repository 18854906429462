.ContainerAttendancePerDay {
  width: 100%;
  height: 100%;
}

.ContainerBoxAttendancePerDay {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.ItemHeaderBoxAttendancePerDay {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ItemLeftHeaderBoxAttendancePerDay {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.ItemInfoHeaderBoxAttendancePerDay {
  display: flex;
  flex-direction: column;
}

.ItemInfoHeaderBoxAttendancePerDay > span:first-child {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.ItemInfoHeaderBoxAttendancePerDay > span:last-child {
  color: #b1b1b1;
  font-size: 13px;
  font-weight: 400;
}

.ItemRightHeaderBoxAttendancePerDay {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.ItemRightHeaderBoxAttendancePerDay > span {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

.WrapperInputInfoAttendancePerDay {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-top: 30px;
}

.ItemsInfoAttendancePerDay {
  display: flex;
  align-items: center;
  column-gap: 90px;
}

.ItemLeftInfoAttendancePerDay {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 200px;
}

.ItemLeftInfoAttendancePerDay > img {
  width: 16px;
  height: 16px;
}

.ItemLeftInfoAttendancePerDay > span {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

.ItemRightInfoAttendancePerDay {
  display: flex;
  flex-direction: column;
}

.ItemRightInfoAttendancePerDay > img {
  width: 150px;
  height: 110px;
}

.ItemRightInfoAttendancePerDay > span {
  color: #333333;
  font-size: 13px;
  font-weight: 400;
}
