@media (min-width:1201px) {
  .topnav__icon{
    display: none;
  }
}

/* @media (min-width: 1025px) {
  .topnav__icon {
    display: none;
  }
} */
