.Container {
  width: 100%;
  min-height: calc(100vh - 130px);
  /* height: 100vh; */
}

.Tab {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-style: normal;
}

