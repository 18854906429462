.WrapperBestSPG {
  flex: 1;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 276px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.HeaderBestSPG > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.ContentBestSPG {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.WrapperContentBestSPG {
  display: flex;
  column-gap: 12px;
  padding-top: 10px;
}

.ContentTextBestSPG {
  display: flex;
  flex-direction: column;
}

.ContentTextBestSPG > span:first-child {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.ContentTextBestSPG > span:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #7d7d7d;
}
