$PRIMARY-MAIN: #1571de;

@mixin flex($gap: 0) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}
@mixin flexCol($rowGap: 0) {
  display: flex;
  flex-direction: column;
  gap: $rowGap;
}

$BLACK-PRIMARY: #0a0a0a;

.wrapperModalDetailBatchAdminIndosat {
  height: 98vh;
  overflow-y: auto;
  width: 98%;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  @include flexCol(14px);
  .header {
    width: 100%;
    @include flexCol(12px);
    .between {
      @include flex();
      justify-content: space-between;
      align-items: center;
      > h3 {
        color: $BLACK-PRIMARY;
        font-size: 16px;
        font-weight: 600;
      }
      .closeIcon {
        cursor: pointer;
        flex-shrink: 0;
        color: #adb5bd;
        &:hover {
          color: $BLACK-PRIMARY;
        }
      }
    }
    .cardWrapper {
      @include flex(8px);
      width: 100%;
      .outCard {
        @include flexCol(4px);
        width: 100%;
        justify-content: center;
        h1 {
          color: #1571de;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
        }
        > span {
          color: #adb5bd;
          font-family: "Noto Sans", Arial, Helvetica, sans-serif;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .card {
        padding: 12px;
        @include flexCol(4px);
        min-width: 200px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #dee2e6;
        background-color: #f8f9fa;
        > span {
          color: #adb5bd;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          > strong {
            color: #000;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }
  }
  .tableWrapper {
    @include flexCol(12px);
    .headerBetween {
      @include flex();
      justify-content: space-between;
      align-items: center;
      > h1 {
        color: $BLACK-PRIMARY;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
      }
      > .filtering {
        @include flex(12px);
        align-items: center;
        .inputSearch {
          font-size: 14px !important;
          color: #000000 !important;
        }
      }
    }
    .table {
      .status {
        width: 98px;
        padding: 2px 8px;
        border-radius: 6px;
        border: 1px solid transparent;
        @include flex();
        justify-content: center;
        align-items: center;
        > span {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
        &[status="available"] {
          border-color: #22970f;
          background-color: #f4fff2;
          color: #22970f;
        }
        &[status="void"] {
          border-color: #d42701;
          background-color: #fff4f2;
          color: #d42701;
        }
        &[status="return"] {
          border-color: #1571de;
          color: #1571de;
          background-color: rgba(208, 227, 248, 0.3);
        }
        &[status="sold"] {
          border-color: #9e9e9e;
          background-color: #ededed;
          color: #9e9e9e;
        }
      }
    }
  }
}
