.ContainerDetailsProductFMCG {
  width: 100%;
  height: 100vh;
}

.WrapperContentDetailsProductFMCG {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
}

.WrapperItemHeaderDetailsProductFMCG {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.BorderImageDetailsProductFMCG {
  width: 84px;
  height: 84px;
  background-color: #e3f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.BorderImageDetailsProductFMCG > img {
  width: 32px;
  height: 32px;
}

.WrapperItemsInputFormDetailsProduct {
  padding-top: 32px;
}

.WrapperItemsInputDetailsProduct {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.ItemsInputDetailsProduct {
  display: flex;
  column-gap: 15px;
}

.ItemInputDetailsProduct {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  width: 100%;
}

.ButtonActionDetailsDetailsProduct {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
  padding-top: 30px;
}
