.container {
  background: #fff;
  width: 370px;
  position: relative;
  border-radius: 8px;
  .headerWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    .header {
      transform: translateY(-50%);
      border: 8px solid rgba(0, 0, 0, 0.5);
      width: 129px;
      height: 129px;
      flex-shrink: 0;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .body{
    margin-top: -45px;
    padding: 30px;
    padding-top: 0;
  }
}
