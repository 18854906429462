.Content {
  width: 100%;
}

.Wrapper {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Wrapper > label  {
    font-size: 16px;
    font-weight: 500;
    color: #757575;
}

.Wrapper > span  {
    font-size: 20px;
    font-weight: 500;
    color: #1571DE;
}
