.Container {
    /* width: 100%;
    height: 100vh; */
    padding: 0;
    margin: 5px 8px;
}

.Wrapper {
    margin-top: 20px;
}


.Divider > span {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    display: block;
    margin-bottom: 12px;
    width: 100%;
}


.Wrapper>span {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    display: block;
    margin-bottom: 12px;
    width: 100%;
}

hr {
    margin-top: 16px;
    margin-bottom: 16px;
    color: #9e9e9e;
}


.BoxForm {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    row-gap: 10px;
}

.BoxForm>span {
    width: 100%;
    font-size: 14px;
    color: #404040;
    font-weight: 400;
}

.BoxForm>input {
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: #616161;
    padding: 8px;
}

.BoxForm>i {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 8px;
}

.BoxForm2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    row-gap: 10px
}

.BoxForm2>span:first-child {
    font-size: 14px;
    color: #404040;
    font-weight: 400;
    text-align: start;
}


.Desc {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    row-gap: 10px;
    padding-bottom: 20px;
}

.Desc>span {
    width: 100%;
    font-size: 14px;
    color: #404040 !important;
    font-weight: 400;
    margin-top: 8px;
}

.Desc>input,
.Desc>textarea {
    border: 1px solid #d9d9d9;
    background: #fbfbfb;
    border-radius: 8px;
    width: 100%;
    height: 80px;
    padding: 8px;
    font-size: 14px;
    color: #616161;
}

.Desc>i {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 8px;
}

.Duration {
    border-radius: 8px !important;
}

.BodyWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    gap: 16px;
}

.BodyWrapper>span:first-child {
    font-size: 20px;
    font-weight: 500;
}

.WorkingHourHeaderWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
}

.WorkingHourHeaderWrapper>span {
    width: 16%;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
}

.ShiftDeleteOuterWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 84%;
}

.ShiftDeleteOuterWrapper img {
    width: 18px;
    height: 20px;
    cursor: pointer;
}

.ShiftDeleteWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}


.BoxRadioShift {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.BoxRadioShift input[type=radio] {
    border: 0px;
    width: 15px;
    height: 15px;
}

.BoxRadioShift label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #404040;
}

.SaveButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SaveButton>button {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #1571de;
    border-radius: 8px;
    color: white;
}

.ClearButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ClearButton>button {
    width: 120px;
    height: 40px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #1571de !important;
    border: none;
    color: #1571de;
}

.BoxInput {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.BoxInputWrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
}

.BoxInputWrapper>span,
.BoxInput>span {
    color: #616161;
}

.BoxInput>input,
.BoxInputWrapper>input
{
    width: 42px;
    height: 36px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.BoxInput>input[readonly] {
    width: 42px;
    height: 28px;
    background: #d9d9d9;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
    /* padding-left: 20px; */
}

.BoxInputWrapper>input:disabled {
    width: 42px;
    height: 36px;
    background: #e0e0e0;
    border: 1px solid #e0e0e0;
    color: #9e9e9e;
    border-radius: 8px;
    /* padding-left: 20px; */
}

.Duration {
    width: 70px!important;
    padding-left: 10px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}
.Duration2 {
    width: 70px!important;
    padding-left: 30px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.ClockIcon {
    position: absolute;
    margin-left: 40px;
}

.ClockIcon2 {
    position: absolute;
    margin-left: 5px;
}

.OvertimeWrapper {
    font-size: 14px;
    color: #404040;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    width: 100%;
}

.CutOff {
    padding: 5px;
    width: 28px !important;
    height: 28px !important;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.ButtonWrapper {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}

@media (max-width: 1024px) {
    .Desc>input {
        width: 599px;
    }
}