.search__combobox{
  position: absolute;
  top: 40px;
  left: 18%;
  transform: translateX(-50%);
  /* width: 100%; */
  max-width: 400px;
  z-index: 10;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.search-mui {
  position: absolute;
  top: 40px;
  left: 22%;
  transform: translateX(-50%);
  z-index: 10;
}

.search__combobox  input {
  border: 1.5px solid #d3c9c9;
  background: #fbfbfb;
  height: 40px;
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  color: var(--txt-color);
}

.gm-style-mtc {
  display: none;
}

/* @media screen and (max-width: 1300px) {
  .search__combobox {
      left: 50%;
  }
} */

/* @media screen and (max-width: 1200px) {
    .search__combobox {
        left: 23%;
    }
}
@media screen and (max-width: 1100px) {
    .search__combobox {
        left: 25%;
    }
}
@media screen and (max-width: 1024px) {
  .search__combobox {
      left: 22%;
  }
} */
