.Background {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.Container {
  width: 570px;
  background: #ffffff;
  border-radius: 8px;
  padding-bottom: 28px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  position: relative;
}

.Top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  padding: 28px;
  margin-bottom: 16px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.Top span {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.Middle {
  background-color: #fafcff;
  padding: 16px;
  padding-bottom: 45px;
  border-radius: 8px;
  margin-inline: 28px;
  border: 1px solid #ebebeb;
  border-bottom-style: dashed;
}

.Section {
  display: flex;
  flex-direction: column;
}

.Section > span:first-child {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.BoxSection,
.LastBoxSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.LastBoxSection {
  margin-bottom: 0;
}

.BoxSection span {
  font-size: 16px;
  font-weight: 400;
}

.BoxSection > span:first-child {
  color: #88888c;
}

.BoxSection > span:nth-child(2) {
  color: #333;
}

.LastBoxSection span {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.Bottom {
  background-color: #fafcff;
  padding: 45px 22px 28px 16px;
  border-radius: 8px;
  margin-inline: 28px;
  border: 1px solid #ebebeb;
  border-top: none;
}

.LeftCropper {
  width: 30px;
  height: 66px;
  border-top-right-radius: 34px;
  border-bottom-right-radius: 34px;
  border: 1px solid #ebebeb;
  border-left: 0;
  background-color: #fff;
  position: absolute;
  margin-top: -80px;
  margin-left: -17px;
}

.RightCropper {
  width: 30px;
  height: 66px;
  border-top-left-radius: 34px;
  border-bottom-left-radius: 34px;
  border: 1px solid #ebebeb;
  border-right: 0;
  background-color: #fff;
  position: absolute;
  margin-top: -80px;
  margin-left: 467px;
}

.Closing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Closing img {
  width: 51px;
  height: 51px;
  object-fit: cover;
}

.Result {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Result > span:first-child {
  font-size: 16px;
  font-weight: 400;
  color: #88888c;
}

.Result > span:last-child {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.Divider {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 8px;
}

.WrapperButton {
  width: 100%;
  display: flex;
  padding-top: 20px;
}

.ButtonDownload {
  width: 100%;
  margin-inline: 28px;
}

.ButtonDownload > button {
  width: 100%;
  background-color: #1571de;
  text-transform: none;
  color: #ffffff;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
}

.ButtonDownload > button:hover {
    background-color: #1571de;
  }
