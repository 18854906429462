.container-edit-draft {
  width: 100%;
  height: 100vh;
}
.wrapper-edit-draft {
  padding-top: 32px;
}
.backdrop-edit-draft {
  /* width: 943px; */
  height: 346px;
  background: #fafafa;
  padding-inline: 37px;
  padding-top: 24px;
}
.input-title-ED {
  display: flex;
  flex-direction: column;
  /* row-gap: 8px; */
  padding-top: 8px;
}
.input-title-ED span {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.input-title-ED input {
  width: 389px;
  height: 40px;
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 4px;
  padding-left: 5px;
  color: #616161;
  font-size: 14px;
}
.input-content-ED {
  display: flex;
  flex-direction: column;
  /* row-gap: 8px; */
  padding-top: 8px;
  margin-top: 28px;
}

.input-content-ED span {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.input-content-ED textarea {
  width: 803px;
  height: 178px;
  background: #fbfbfb;
  border: 1.5px solid #d3c9c9;
  border-radius: 4px;
  padding-left: 5px;
  color: #616161;
  font-size: 14px;
}
.helper-ED {
  display: flex;
  align-items: center;
  padding-top: 24px;
  column-gap: 12px;
}
.helper-ED button {
  text-transform: none;
}
