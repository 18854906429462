.ContainerDetailsPromoFMCG {
  width: 100%;
  height: 100%;
}

.WrapperContentDetailsPromo {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 30px;
}

/* .WrapperFormDetailsPromo {
  display: flex;
} */

.WrapperItemsInputDetailsPromo {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.ItemsInputDetailsPromo {
  display: flex;
  column-gap: 15px;
}

.ItemInputDetailsPromo {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  width: 100%;
}

.ItemInputDetailsPromo > span {
  font-size: 13px;
  font-weight: 500;
}

.WrapperTableDetailsPromo {
  padding-top: 30px;
}

.HeaderTableDetailsPromo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.ButtonActionDetailsPromo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
  padding-top: 30px;
}


