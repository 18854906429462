.HeaderWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    gap: 24px
}

.Header {
    text-transform: capitalize;
    font-weight: 500;
    color: #0A0A0A;
    font-size: 40px;
}

.ButtonWrapper {
    display: flex;
    gap: 16px;
}

.ButtonWrapper button {
    text-transform: capitalize;
    color: #0A0A0A;
    border: 1px solid #d9d9d9;
    font-size: 20px;
    padding-inline: 16px;
    border-radius: 8px!important;
    background-color: transparent;
    height: 40px;
    width: 100%;
}

.ActiveButton {
    color: #1571DE!important;
    background-color: #fff!important;
}