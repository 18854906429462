.Container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.FormControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 8px;
  padding-top: 20px;
  row-gap: 16px;
}

.LabelControl {
  width: 100%;
}

.LabelControl > span:nth-child(1) {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}

.LabelControl {
  width: 100%;
}

.LabelControlHidden > span:nth-child(1) {
  font-size: 0px;
}

.RulesNameLabelControl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ededed;
  border-radius: 8px;
  padding: 10px;
}

.RulesNameLabelControl > span {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.RulesNameLabelControl > img {
  width: 22px;
  height: 22px;
  color: black;
}

.Value {
  width: 100%;
  display: flex;
  gap: 15px;
}

.ValueControl {
  flex: 1;
  display: flex;
  gap: 8px;
}

.ValueControl > label {
  width: 100%;
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.ValueControl > input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 8px;
  padding-block: 10px;
}

.ValueControl > textarea {
  width: 100%;
  resize: vertical;
  padding-inline: 8px;
  padding-block: 10px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  min-height: 100px;
  outline: none;
}

.ValueControl > textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #757575;
}

.ValueControl > select {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 8px;
  padding-block: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 7px;
}

.ValueControl > select::-webkit-input-placeholder,
option::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #757575;
}

/* .ValueControl > input:focus,
option:focus,
select:focus,
textarea:focus {
  border-color: #1571de;
} */

.ValueControl > select:focus {
  outline: none;
}

.InputContainer {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 40px;
  padding-inline: 10px;
}

.InputAdornment {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputAdornment > span {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
  padding-right: 5px;
}

.InputContainer > input {
  width: 100%;
  border: 0;
  outline: none;
  padding-left: 5px;
  padding-right: 10px;
  /* padding-inline: 10px; */
  padding-block: 10px;
  height: 30px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.LineBreak {
  width: 100%;
  padding-top: 16px;
}

.LineBreak > hr {
  border: 1px solid #e0e0e0;
}

.ValueAdminFee {
  width: 100%;
  display: flex;
}

.ValueControlAdminFee {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ValueControlAdminFee > label {
  width: 51%;
  display: flex;
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.InputAdminFee {
  width: 100%;
  display: flex;
  align-items: center;
}

.InputAdminFee {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.InputAdminFee > label {
  width: 33.5%;
  color: #404040;
  font-size: 14px;
  font-weight: 400;
  /* background-color: red; */
}

.InputAdminFee > input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 8px;
  padding-block: 10px;
}

.WrapperInputFee {
  width: 100%;
  display: flex;
  column-gap: 10px;
}

.WrapperInputFee > input {
  width: 100%;
}

.TextAbove {
  width: 100%;
}

.TextAbove > span {
  color: #0a0a0a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 40px;
}

.ButtonAddNewRules {
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d0e3f8;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.ButtonAddNewRules > button {
  text-transform: none;
  font-size: 16px;
  color: #1571de;
  font-weight: 500;
}

.ButtonSave {
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  padding-top: 32px;
}

.ButtonSave > button:first-child {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1571de;
  border-radius: 4px;
}

.ButtonSave > button:last-child {
  color: #1571de;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  border: 1px solid #1571de;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
