.container-location-external {
  width: 100%;
  height: 100%;
}

.wrapper-location-external {
  display: flex;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.wrapper-location-external :nth-child(2) {
  margin-left: auto;
}

.wrapper-location-external :nth-child(2) > button {
  width: 108px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
  /* margin-left: 24px; */
  cursor: pointer;
  /* padding-inline: 10px; */
  padding-right: 20px;
}

.table-wrapper-location-external {
  padding-top: 24px;
}
