.Container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.Wrapper {
  padding-top: 24px;
}

.GroupControl {
  width: 100%;
  display: flex;
}

.GroupControl > .Group:first-child {
  display: flex;
  column-gap: 12px;
  flex: 1;
  align-items: center;
}

.GroupDetail {
  display: flex;
  flex-direction: column;
}

.GroupDetail > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: 500;
  color: #0a0a0a;
}

.GroupDetail > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}

.GroupDetail > span:nth-of-type(3) {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
}

.Breaker {
  width: 100%;
  margin-top: 22px;
  border: 0.5px solid #e0e0e0;
}

.BreakerReceipt {
  margin-top: 10px;
  border: 0.5px solid #e0e0e0;
}

.GroupControl > .Group:last-child {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.StatusControl {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 103px;
  height: 38px;
  background: #498e0f;
  border-radius: 50px;
  color: #ffffff;
}

.Label {
  padding-top: 24px;
  width: 100%;
  display: flex;
}

.Label > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.GroupControlDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.WrapperGroup {
  width: 100%;
  display: flex;
  column-gap: 16px;
  padding-top: 16px;
}

.Heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Profile {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.Profile > span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a0a;
}
.Profile > span:nth-of-type(2) {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}
.Profile > span:nth-of-type(3) {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
}

.Logo {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.Logo > img {
  width: 69px;
  height: 69px;
}

.PawnCodeBox {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: rgba(208, 227, 248, 0.3);
  border-radius: 8px;
}

.ObjectGroup {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}

.Keys {
  width: 100%;
  display: flex;
  flex-direction: flex-start;
}

.Keys > span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.Values {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.Values > span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-align: right;
}

.Details {
  flex: 1;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 24px;
}

.Others {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
}
/* 
  .Disbursed {
    background: #ffffff;
    border-radius: 8px;
    height: 276px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.02);
    --mask:
      radial-gradient(11.66px at 50% calc(100% - 16.00px),#000 99%,#0000 101%) calc(50% - 20px) 0/40px 100%,
      radial-gradient(11.66px at 50% calc(100% + 6.00px),#0000 99%,#000 101%) 50% calc(100% - 10px)/40px 100% repeat-x;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  } */

.Disbursed {
  /* background-color: #ffffff;
    border: 1px solid #d9d9d9 !important;
    border-radius: 8px;
    height: 276px;
    --mask: radial-gradient(
          11.66px at 50% calc(100% - 16px),
          #000 99%,
          #0000 101%
        )
        calc(50% - 20px) 0/40px 100%,
      radial-gradient(11.66px at 50% calc(100% + 6px), #0000 99%, #000 101%) 50%
        calc(100% - 10px) / 40px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask); */

  /* border-image: linear-gradient(#f6b73c, #4d9f0c) 30;
    border-width: 4px;
    border-style: solid; */
  width: 100%;
  /* height: 276px; */
  position: relative;
}

.Disbursed > img {
  width: 550px;
  height: 315px;
}

.LeftCropper {
  width: 20px;
  height: 30px;
  border-radius: 50%;
  /* border-top-right-radius: 34px; */
  /* border-bottom-right-radius: 34px; */
  border: 1px solid #d9d9d9;
  border-left: 0;
  background-color: #fafafa;
  /* position: absolute; */
  margin-top: 120px;
  margin-left: -17px;
}
.RightCropper {
  width: 20px;
  height: 30px;
  border-top-left-radius: 34px;
  border-bottom-left-radius: 34px;
  border: 1px solid #ebebeb;
  border-right: 0;
  background-color: #fafafa;
  /* position: absolute; */
  margin-top: 120px;
  margin-left: 450px;
}

.Divider {
  width: 100%;
  max-width: 450px;
  position: absolute;
  border: 1px dashed #c2c2c2;
  left: 9.5%;
  top: 61.5%;
}

.DisbursedDetail {
  width: 100%;
  max-width: 450px;
  position: absolute;
  top: 24px;
  left: 50px;
}

.DisbursedHeading {
  width: 100%;
}

.Info {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 10px;
}

.InfoTotal {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 35px;
  position: absolute;
  width: 100%;
}

.InfoValue {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InfoValue > span:first-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.InfoValue > span:last-child {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.InfoValueTotal {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.InfoValueTotal > span:first-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.InfoValueTotal > span:last-child {
  font-size: 16px;
  font-weight: 600;
  color: #0a0a0a;
}

.StepperList {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 276px;
  overflow-x: auto;
  padding: 24px;
}

.Itinerary {
  /* padding: 24px; */
  display: flex;
  padding-top: 24px;
}

.Itinerary:first-child {
  padding-top: 0px;
}

/* .Itinerary > .ItineraryProcess > .DashedProgress:last-child {
    border: none;
  } */

.ItineraryProcess {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.DetailPawn {
  display: flex;
  flex-direction: column;
}

.DashedProgress {
  border: 2px dashed #1571de;
  transform: rotate(90deg);
}

.NoDashedProgress {
  border: none;
  transform: rotate(90deg);
}

.ItineraryDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.DetailValue {
  display: flex;
  flex-direction: column;
}

.DetailValue > span:first-child {
  font-size: 14px;
  font-weight: 400;
  color: #858c97;
}

.DetailValue > span:last-child {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}

.DetailStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #deedff;
  width: 66px;
  height: 32px;
  border-radius: 50px;
}

.DetailStatus > span {
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
}

.ActionButton {
  display: flex;
  align-items: center;
  padding-top: 24px;
  column-gap: 16px;
}

.ActionButton > button:first-child {
  flex: 1;
  text-transform: none;
  background: #d52701;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
}

.ActionButton > button:last-child {
  flex: 1;
  text-transform: none;
  background: #1571de;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
}
