.wrapper-date-range-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: 5px;
}
.wrapper-text-date-range-picker {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-date-range-picker {
  height: 35px;
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .wrapper-date-range-picker {
    width: 250px;
  }
}

@media (max-width: 1024px) {
  .wrapper-date-range-picker {
    width: 300px;
  }
}


/* @media (max-width: 1100px) {
  .wrapper-date-range-picker {
    width: 180px;
  }
} */

/* @media (max-width: 1240px) {
  .wrapper-text-date-range-picker {
    width: 200px;
  }
} */
