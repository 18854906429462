.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
}

.HeaderImage {
  background-color: #fafafa;
  width: 108px;
  height: 108px;
  font-size: 50px;
  color: #1571de;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
}

.HeaderTitle {
  font-weight: 500;
  font-size: 20px;
  color: #0a0a0a;
}

.InputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.InputControl > p:first-child {
  color: #616161;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
}

.MultiInput {
  display: flex;
  justify-content: space-between;
}

.MultiLabel {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.MultiLabel > p:first-child {
  color: #616161;
}

.MultiLabel > p:last-child {
  color: #9e9e9e;
}

.SwitchInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.SwitchInput > p {
  font-weight: 400;
  font-size: 14px;
  color: #616161;
}

.WrapperButtonModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}
