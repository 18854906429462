.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 493px;
  height: 363px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  /* margin-top: 10px; */
}

.title > span {
  font-size: 32px;
  font-weight: 500;
  color: #616161;
}
/* 
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
} */

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: row;
  align-items: center;
}
.body {
  display: flex;
  flex-direction: column;
}
.body > img {
  width: 120px;
  height: 120px;
}

.body__items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body__items-wrapper > button {
  margin-top: 10px;
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer > button {
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding-left: 10px;
}
