.Spacer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ButtonAction {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ButtonAction > button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: none;
}

.ButtonAction > button:hover {
  background-color: transparent;
}

.ButtonAction > button:first-child > span {
  font-size: 14px;
  font-weight: 400;
  color: #1571de;
}

.ButtonAction > button:last-child > span {
  font-size: 14px;
  font-weight: 400;
  color: #d1421a;
}

.ButtonAction > button > img {
  width: 17px;
  height: 15px;
}

.Container {
  padding-inline: 10px;
}

.Body {
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #d9d9d9;
  background-color: #fff;
}

.Header {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
  padding-bottom: 0.5rem;
}

.Value {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ValueControl {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.ValueControl > span:first-child {
  font-size: 0.875rem;
  color: #404040;
  font-weight: 400;
}

.ValueControl > span:last-child {
  font-size: 0.875rem;
  color: #0A0A0A;
  font-weight: 400;
}
