.PaginationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 43px;
  background-color: #fff;
  /* border: 1px solid #d9d9d9; */
  border-top: none;
  height: 52px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-inline: 20px;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  background-color: #fff;
  /* border: 1px solid #d9d9d9; */
  border-top: none;
  height: 52px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-inline: 20px;
}

.PaginationContainerIndosat {
  width: 100%;
}

.SetLimit {
  display: flex;
  align-items: center;
  gap: 12px;
}

.SetLimit label,
.SetLimit span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #0a0a0a;
}

.Select {
  font-size: 12px !important;
  width: 70px;
  height: 30px;
  background: #ffffff;
  border-radius: 8px !important;
}

.Option {
  font-size: 12px !important;
}

.ArrowIcon {
  font-size: 13px !important;
}

.Pages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.Pages > span {
  font-size: 12px !important;
}

.Pages ul {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;
}

.Pages li {
  list-style-type: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.Disabled {
  color: #c2c2c2 !important;
  cursor: default !important;
}

.NormalPageNumber {
  color: #c2c2c2;
}

.ActivePageNumber {
  color: #0a0a0a;
}

.PageNumber:hover {
  color: #0a0a0a;
}
