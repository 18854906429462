.layout__content {
    padding-left: 281px;
    min-height: 100vh;
}

.layout__content-main {
    padding: 20px;
    background-color: #fafafafa;
}



  @media screen and (max-width: 1000px) {
    .layout {
      display: none;
    }
  }



@media screen and (max-width: 1200px) {
  .layout__content {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .layout__content {
    padding-left: 0;
  }
}

@media screen and (max-width: 1000px) {
  .layout {
    display: none;
  }
}