.ContainerEmployeesDetailsFMCG {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.HeadingEmployeesDetailFMCG {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 24px;
}


.ButtonRouterBack {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

/* .WrapperBreadCrumbs {
  padding-top: 20px;
} */

.ContentEmployeesDetailsFMCG {
  padding-top: 20px;
  width: 100%;
  position: relative;
}

.ContentEmployeesDetailsFMCG > img {
  width: 100%;
  object-fit: cover;
}

.WrapperContentFloatEmloyeesDetailsFMCG {
  display: flex;
  column-gap: 15px;
  transform: translateY(-100px);
  padding-inline: 20px;
}

.ContentStatusEmployeesDetailsFMCG {
  padding: 20px;
  flex: 0.4;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  height: 248px;
  justify-content: center;
}

.ContentStatusEmployeesDetailsFMCG > img {
  width: 84px;
  height: 84px;
}

.ContentInfoEmployeesDetailsFMCG {
  padding: 20px;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 248px;
  overflow: scroll;
  display: flex;
  justify-content: space-between;
}

.WrapperItemsEmployeesDetailsFMCG {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 6px;
}

.ItemsEmployeesDetailsFMCG {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.WrapperTextEmployeesDetailsFMCG {
  display: flex;
  flex-direction: column;
}

.ItemsEmployeesDetailsFMCGLeft {
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-width: 100px;
}

.ItemsEmployeesDetailsFMCGLeft > img {
  width: 16px;
  height: 16px;
}

.ItemsEmployeesDetailsFMCGLeft > span {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

.ItemsEmployeesDetailsFMCGRight > span {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

/* .ContentItemsRightEmployeesDetails {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
} */

.WrapperTextEmployeesDetailsFMCG > span:nth-of-type(1) {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.WrapperTextEmployeesDetailsFMCG > span:nth-of-type(2) {
  color: #b1b1b1;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.BoxStatusEmployeesDetailsFMCG {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1ac635;
  border-radius: 15.5px;
  width: 72px;
  height: 30px;
}

.BoxStatusEmployeesDetailsFMCG > span {
  color: #1ac635;
  font-size: 13px;
  font-weight: 400;
}

.ButtonActionEmployeesDetailFMCG {
  display: flex;
  column-gap: 10px;
}

.WrapperIconAction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #ecf5ff;
  cursor: pointer;
  border-radius: 5px;
}

.WrapperIconAction > img {
  width: 20px;
  height: 20px;
}

.ButtonActionEmployeesDetailFMCG > img:nth-of-type(1) {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.ButtonActionEmployeesDetailFMCG > img:nth-of-type(2) {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* BreadCrumb Text */
.BreadCrumbsTextGrey {
  font-size: 18px;
  color: #6f6f70;
  font-weight: 500;
}

.BreadCrumbsTextBlue {
  font-size: 18px;
  color: #1571de;
  font-weight: 500;
}
