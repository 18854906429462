.Container {
    width: 100%;
    min-height: 100vh;
    padding: 5px 8px;
    padding-left: 32px;
}

.Wrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding-top: 24px;
}

/* .Wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    column-gap: 24px;
    row-gap: 15px;
} */

.BoxWrapper {
    width: 100%;
    background: #fff !important;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 24px;
}

.BoxHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.BoxHeader>span:first-child {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
}

.BoxHeader img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.Top {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
}

.BoxContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.BoxContent>span:first-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
}

.BoxContent>span:last-child {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0a0a0a;
    text-transform: none;
}

.MapWrapper {
    margin-bottom: 16px;
}

.MapContainer {
    width: 100%;
    /* height: 100px; */
    background-color: #fff;
    /* position: relative; */
    /* display: flex; */
    margin-inline: 0;
}

.MapDetails {
    background: rgba(208, 227, 248, 0.3);
    padding: 8px;
    border-radius: 8px;
}

.CoordinatesWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 8px;
}

.CoordinatesWrapper>div {
    width: 100%;
}

.ActionButtonWrapper {
    display: flex;
    align-items: center;
    gap: 3px;
}

.ActionButtonWrapper button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px;
    background-color: transparent !important;
    width: 30px !important;
    height: 30px !important;
}

.ActionButtonWrapper button img {
    width: 20px !important;
    height: 20px !important;
}

.EmptyBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    height: 50px;
    border-radius: 8px;
}


.TableContainer {
    min-width: 600;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    border-bottom: 0;
}

.Table {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;

}

.TableSortLabel {
    color: #0a0a0a !important;
    font-weight: 500;
    font-size: 14px;
}

/* .TableRow {
    height: 40px!important;
} */

.TableCell {
    font-size: 14px;
    font-weight: 400;
    color: #0a0a0a;
}

.TablePagination {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
}

.SearchMode {
    display: flex;
    align-items: center;
}

.SearchMode input {
    height: 26px;
    border-radius: 5px !important;
    padding-left: 25px;
}

.SearchMode button {
    border: none !important;
}

.DeleteIcon {
    width: 15px;
    height: 17px;
    cursor: pointer;
}

.DeleteCell {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

/* DUPLICATE ?  */
/* .AddMode {
    display: flex;
    align-items: center;
} */


.AddMode {
    display: flex;
    align-items: center;
    gap: 5px;
}

.AddMode input {
    height: 26px;
    border-radius: 5px !important;
    width: 120px;
    padding-left: 25px;
}

.AddMode > button {
    border: none !important;
    padding: 0;
    height: 26px!important;
}

.AddModeSearch, .SearchModeSearch {
    display: flex;
    align-items: center;
}

.AddModeSearch img, .SearchModeSearch img {
    width: 18px;
    height: 18px;
    margin-top: 1px;
    margin-left: 4px;
    display: flex;
    position: absolute !important;
}

.AddMode>button:first-child {
    background-color: #1571DE !important;
    color: #fff;
    height: 26px !important;
    width: 50px !important;
}