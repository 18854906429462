/* .Container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
} */
/* .wrapper-att-detail-menu {
  padding-top: 20px;
} */
.wrapper-date-filter {
  /* width: 500px; */
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.wrapper-date-filter > span {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f70;
}
.container-profile-att-detail-menu {
  padding-top: 30px;
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
}
.contaiener-image-att-detail-menu {
  padding-top: 5px;
}

/* profile left */
.profile-att-detail-menu {
  display: flex;
  flex: 0.5;
  padding-top: 25px;
}
.text-profile-detail-menu {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.wrapper-profile-att-detail-menu {
  display: flex;
}
.text-profile-detail-menu {
  padding-left: 10px;
}
.text-profile-detail-menu > label {
  font-size: 24px;
  font-weight: 500;
  color: #404040;
}
.text-profile-detail-menu > span {
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}
.company-profile-detail-menu {
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.company-profile-detail-menu > span {
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #757575;
}

/* attendance summary  */
.profile-att-summary {
  flex: 1;
  padding-top: 25px;
}
.title-profile-att-summary {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-profile-att-summary > span {
  font-size: 24px;
  font-weight: 500;
  color: #404040;
}

.wrapper-item-row-att-summary {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  padding-top: 10px;
  flex-wrap: wrap;
}
.title-item-presence > span {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}
.item-sub-presence > label {
  font-size: 24px;
  font-weight: 400;
}
.item-sub-presence > span {
  font-size: 14px;
  font-weight: 400;
}
.title-item-off > span {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}
.title-item-leave > span {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}
.title-item-sick > span {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}

/* button */
.button-profile-att-summary {
  flex: 0.5;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* padding-left: 20px; */
}
.button-profile-att-summary > button {
  width: 140px;
  height: 40px;
  /* background: #1571de; */
  border-radius: 4px;
  text-transform: none;
  color: white;
}

/* date detail menu  */
/* .attendance-detail-date {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
}
.rectangle-detail-date {
  width: 90px;
  height: 120px;
  background: #ffffff;
  border-radius: 8px;
}
.detail-date-desc {
  padding-top: 10px;
}
.detail-image-desc {
  text-align: center;
}
.text-detail-desc {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.text-detail-desc > label {
  font-size: 14px;
  font-weight: 500;
}
.text-detail-desc > span {
  font-size: 12px;
  font-weight: 400;
  color: #616161;
}

.rectangle-detail-date-permit {
  background: #fce8d0;
  border-radius: 8px;
  width: 90px;
  height: 120px;
}
.detail-date-des-permit {
  padding-top: 10px;
}
.detail-image-desc-permit {
  text-align: center;
}
.text-detail-desc-permit {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.text-detail-desc-permit > label {
  padding-top: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #d1421a;
}
.text-detail-desc-permit > span {
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
}

.rectangle-detail-date-sick {
  background: #c9fbf8;
  border-radius: 8px;
  width: 90px;
  height: 120px;
}

.detail-date-desc-sick {
  padding-top: 5px;
}
.detail-image-desc-sick {
  text-align: center;
}
.text-detail-desc-sick {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.text-detail-desc-sick > label {
  padding-top: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #d1421a;
}
.text-detail-desc-sick > span {
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
} */


.Container {
  width: 100%;
  min-height: 100vh;
  padding-top: 24px;
  padding-inline: 8px;
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.ProfileCard {
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.ProfileLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.ProfilePicture {
  width: 84px !important;
  height: 84px !important;
}

.ProfileDesc {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.ProfileDesc>span:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #404040;
}

.ProfileDesc>span:nth-child(2) {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: #B1B1B1;
}

.ProfileDesc>span:nth-child(3)   {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: #B1B1B1;
}

.ProfileDesc>span:nth-child(3)  >  label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #0A0A0A;
}

.ProfileRight {
  /* height: 100%;
  width: 50%;
  background: #F4F4F4;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: space-between; */
  display: flex;
  align-items: center;
  gap: 8px;
}

.BoxAmount {
  width: 100%;
  height: 100%;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-radius: 4px;
  background: #F8F8F8;
}

.AmountIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.AmountIcon > span {
  font-size: 14px;
  font-weight: 500;
  color: #989898;
}

.AmountIcon > img {
  width: 16px;
  height: 16px;
}

.AmountDesc {
  display: flex;
  align-items: center;
  gap: 5px;
}

.AmountDesc > img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* .BoxAmount img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.AmountDesc {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.AmountDesc>span:first-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #989898;
}

.AmountDesc>span:nth-child(2) {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
} */

/* SUMMARY CARD */

.SummaryCard {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  /* .container-profile-att-detail-menu {
   flex-direction: column;
   row-gap: 20px;
  } */
  .button-profile-att-summary {
    padding-left: 0;
  }
  /* .profile-att-summary {
    padding-top: 40px;
  } */
  .wrapper-item-row-att-summary {
    justify-content: space-evenly;
  }
}
