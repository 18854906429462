.topnav {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    /* width: 100%; */
    /* background-color: var(--main-bg); */
    /* box-shadow: var(--box-shadow); */
    /* background-color: #fafafa; */
    border-bottom: 0.5px solid #E7E7E7;
    position: sticky;
    top: 0;
    z-index: 5;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(15px);
  }
  
  .topnav__search {
    position: relative;
    height: 50px;
    /* background-color: var(--main-bg); */
    display: flex;
    align-items: center;
    /* box-shadow: var(--box-shadow); */
    border-radius: var(--border-radius);
    overflow: hidden;
  }
  
  .topnav__search > span {
    font-size: 24px;
    font-weight: 600;
    color: #0A0A0A;
  }
  
  .topnav__search > input {
    height: 40px;
    width: 395px;
    padding: 22px 60px 22px 20px;
    border-radius: 15px;
    color: var(--txt-color);
    background-color: var(--main-bg);
  }
  
  .topnav__search > i {
    font-size: 18px;
    position: absolute;
    right: 20px;
    /* color: #fafafa; */
  }
  
  .topnav__right {
    display: flex;
    align-items: center;
  }
  
  .topnav__right-item ~ .topnav__right-item {
    margin-left: 30px;
  }
  
  .notification-item {
    display: flex;
    align-items: center;
    padding: 20px;
  } 
  
  .notification-item:hover {
    background-color: var(--second-bg);
  }
  
  .notification-item > i {
    margin-right: 20px;
    font-size: 1.5rem;
  }
  
  .topnav__right-user {
    display: flex;
    /* align-items: center; */
  }
  
  .topnav__right-user__image {
    width: 50px;
    height: 50px;
    /* border-radius: 50%; */
    overflow: hidden;
    margin-right: 10px;
  }
  
  .topnav__right-user__image > img {
    width: 100%;
  }
  
  .topnav__right-user_name {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .topnav__right-user_name-main {
      font-size: 14px;
      font-weight: 500;
  }
  
  .topnav__right-user_name-sub {
      font-size: 12px;
      font-weight: 400;
      color: #00000099;
      /* opacity: 60%; */
  }
  
  
  /* @media (min-width:1201px) {
    .topnav__icon{
      display: none;
    }
  } */
  
  
  