.Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding-inline: 8px;
    margin-top: 24px;
    gap: 12px;
}

.Wrapper {
    padding: 20px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.StatusHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 16px;
}

/* .StatusDesc {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.StatusDesc>span:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0A0A0A;
}

.StatusDesc>span:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
} */

.ClientsLabelContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
    margin-bottom: 16px;
}

.ClientsLabel {
    background: #fafafa;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10.5px;
}

.ClientsLabel span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
}

.ClientIcon {
    color: #1571DE;
}

.Banner {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.Banner img {
    width: 100%;
    height: 372px;
    object-fit: cover;
    border-radius: 8px;
}

.ArticleHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}

.ArticleHeader>span:first-child {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #0A0A0A;
}

.Content > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0A0A0A;
}

.ItemBoxSetupPushNotification{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 0px 18px;
}

.TitleSetupPushNotification{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.TitleNotification{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    padding: 24px 0px 0px;
}

.BoxInputButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.BoxInputButton>button {
    width: 126px;
    height: 40px;
    border: 1px solid #1571de;
    border-radius: 8px;
    text-transform: none;
}

.SetupPushNotificationButtonWrapper{
    display: flex;
    align-items: center;
    gap: 8px;
}

.FillPushNotification{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 4px;
}

.BoxPushNotification{
    display: flex;
    flex-direction: row;
    gap: 16px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 8px;
    align-items: center;
    padding: 8px 12px;
}

.ComponentNotification{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;
}

.ComponentNotification>span{
    color: #757575;
    font-size: 12px;
    font-weight: 500;
}
.ComponentNotification>p{
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    justify-content: flex-start;
    align-items: center;
}

.CenterEmptySetupPushNotification{
    display: flex;
    width: 100%;
    padding: 24px;
    border: 1px solid #D9D9D9;
    background-color: #FFFFFF;
}

.EmptySetupPushNotification{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    padding-top: 12px;
}

.Published {
    background-color: #F4FFF2;
    color: #22970F !important;
    border: 1px solid #22970F;
    padding: 2px 5px;
    border-radius: 5px;
}

.Waiting {
    background-color: #DEFEFF;
    color: #1571DE !important;
    border: 1px solid #1571DE;
    padding: 2px 5px;
    border-radius: 5px;
}

.ErrorText{
    font-size: 12px;
    color: #df2222;
}

.ButtonLayout{
    display: flex;
    width: 100%;
    gap: 16px;
    padding-top: 12px;
    justify-content: flex-end;
    align-items: center;
    
}

.FeatureWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.FeatureWrapper>span:first-child{
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
}

.FeatureText{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
}

.TextButton{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: underline;
    color: #1571DE;
    cursor: pointer;
}

.ItemBox {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    padding-top: 24px;
}

.ItemBox span {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.ItemBoxFeature {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    gap: 4px;
    padding-top: 24px;
}

.ItemBoxFeature>span:first-child {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.ItemBoxFeature>span:nth-child(2) {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
}

.ItemBoxContent {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    padding-top: 24px;
}

.ItemBoxContent span {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.ItemBoxContent input {
    width: 100%;
    height: 40px;
    background: #fff;
    border: 1px solid #d3c9c9;
    border-radius: 8px;
    padding-left: 10px;
}

.ItemBoxContent input:focus{
    border-color: #1571DE;
    outline-color: #1571DE;
}

.ItemBoxContent input:disabled{
    width: 100%;
    height: 40px;
    background: #E0E0E0;
    border: 1px solid #C2C2C2;
    border-radius: 8px;
    padding-left: 10px;
    color: #C2C2C2;
}

.ItemBoxContent textarea {
    height: 178px;
    background: #fff;
    border: 1px solid #d3c9c9;
    border-radius: 8px;
    padding-inline: 10px;
    padding-block: 10px;
}

.ItemBoxContent textarea:focus{
    border-color: #1571DE;
    outline-color: #1571DE;
}

.Divider{
    width: 100%;
    height: 1px;
    background-color: #EDEDED;
}

.ClientsChipContainer {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    margin-block: 8px;
    padding-right: 5px;
}

.ClientsChip {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 4px 12px;
}

.ClientsChip span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #404040;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CancelIcon {
    font-size: 10px !important;
    color: #404040;
    cursor: pointer;
}

.SelectPreviewWrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 12px;
}