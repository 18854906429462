.ContainerDetailAttendanceFMCG {
  width: 100%;
  height: 100%;
}

.WrapperDetailsInfoAttendanceFMCG {
  margin-top: 30px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.ItemDetailsInfoEmployee {
  display: flex;
  align-items: center;
  flex: 1;
  column-gap: 10px;
}

.ItemDetailsEmployee {
  display: flex;
  flex-direction: column;
}

.ItemDetailsEmployee > span:first-child {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.ItemDetailsEmployee > span:last-child {
  color: #b1b1b1;
  font-size: 13px;
  font-weight: 400;
}

.ItemDetailsInfoEmployeeAttendance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  background: #f4f4f4;
  border-radius: 8px;
  padding: 10px;
}

.InfoEmployeeAttendance {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.InfoEmployeeAttendance > img {
  width: 20px;
  height: 20px;
}

.ContentEmployeeAttendance {
  display: flex;
  flex-direction: column;
}

.ContentEmployeeAttendance > span:first-child {
  color: #989898;
  font-size: 12px;
  font-weight: 400;
}

.ContentEmployeeAttendance > span:last-child {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

.ContentAttendanceRangeDate {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.HeadingAttendanceRangeDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.ItemLeftAttendanceRangeDate {
  display: flex;
  flex-direction: column;
}

.ItemLeftAttendanceRangeDate > span:first-child {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.ItemLeftAttendanceRangeDate > span:last-child {
  color: #814f4f;
  font-size: 13px;
  font-weight: 400;
}
