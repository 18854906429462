.ContainerEmployeesFMCG {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}
.HeaderEmployeesFMCG {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ButtonReportEmployeesFMCG > button {
  text-transform: none;
  font-size: 14px;
  color: #1571de;
  border: 1px solid #1571de;
  border-radius: 4px;
  width: 108px;
  height: 40px;
}

.ItemTopEmployeesFMCG {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  gap: 10px;
}

.ContainerTableEmployeesFMCG {
  padding-top: 25px;
}

.ButtonActionEmployeesFMCG {
  display: flex;
  column-gap: 10px;
}
