.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
  padding: 10px;
}

.HeaderImage {
  background-color: #fafafa;
  width: 108px;
  height: 108px;
  font-size: 50px;
  color: #1571de;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
}

.HeaderTitle {
  font-weight: 500;
  font-size: 20px;
  color: #0a0a0a;
}

.ModalBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.DescriptionControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.DescriptionControl > p:first-child {
  font-weight: 400;
  font-size: 14px;
  color: #616161;
}
.DescriptionControl > p:last-child {
  font-weight: 500;
  font-size: 14px;
  color: #0a0a0a;
}

.WrapperButtonModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
}
