.employee__external-item-top {
  display: flex;
  align-items: center;
}

.employee__item-search {
  padding-right: 20px;
}

/* .employee__item-dropdown {
} */

.employee__item-add {
  margin-left: auto;
}

.employee__external-table {
  padding-top: 24px;
}

.status__store {
  width: 100px;
  height: 26px;
  background: #b22a12;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fbe7b3;
  font-size: 12;
  font-weight: 400;
}
