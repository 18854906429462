.Container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.FormControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 8px;
  padding-top: 20px;
  row-gap: 16px;
}

.Value {
  width: 100%;
  display: flex;
  gap: 0.938rem;
}

.Header {
  padding-inline: 8px;
  width: 100%;
  font-size: 1.25rem;
  color: #000000;
  font-weight: 500;
  padding-top: 1.5rem;
}

.ValueControl {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ValueControl > label {
  width: 100%;
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.ValueControl > input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 8px;
  padding-block: 10px;
}

.ValueControl > select {
  width: 100%;
  background-color: #fff !important;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 8px;
  padding-block: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99.5%;
  background-position-y: 7px;
  color: #000000;
}

/* .ValueControl > select {
  color: #9e9e9e;
} */
option:not(:first-of-type) {
  color: black;
}

.ValueControl > select:focus {
  outline: none;
}

.ValueControl > select::-webkit-input-placeholder,
option::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #757575;
}

.ValueLeave {
  width: 100%;
  display: flex;
  gap: 0.938rem;
}

.ValueLeave
  :where(
    .ValueControlLeave,
    .InputControlLeave,
    .InputControlLeave > label,
    .WrapperInputLeave,
    .InputContainer,
    .InputContainer > input
  ) {
  width: 100%;
}

.ValueControlLeave {
  display: flex;
}

.InputControlLeave {
  display: flex;
  align-items: center;
}

/* .InputControlLeave > label {
} */

.ValueControlLeave > label {
  width: 100%;
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.InputControlLeave > label {
  width: 100%;
  color: #404040;
  font-size: 14px;
  font-weight: 400;
}

.InputControlLeave > input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 8px;
  padding-block: 10px;
}

.WrapperInputLeave {
  display: flex;
  gap: 0.5rem;
}

.InputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.InputContainer > label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #9e9e9e;
}

.InputContainer > span {
  display: flex;
  justify-content: flex-end;
  font-size: 0.875rem;
  font-weight: 500;
  color: #9e9e9e;
}

/* .InputContainer > label:last-child {
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
    font-weight: 500;
    color: #9E9E9E;
} */

.InputContainer > input {
}

.InputContainer > select {
  width: 100%;
  background-color: #fff !important;
  border: 1px solid #d9d9d9;
  /* box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  height: 40px;
  padding-inline: 8px;
  padding-block: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99.5%;
  background-position-y: 7px;
  color: #000000;
}

.InputContainer > select:focus {
  outline: none;
}

.SwitchInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.SwitchInput > p {
  font-weight: 400;
  font-size: 0.875rem;
  color: #616161;
}

.ActionButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.ActionButton > button:first-child {
  width: 120px;
  height: 40px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #1571de !important;
  border: none;
  color: #1571de;
}

.ActionButton > button:last-child {
  width: 120px;
  height: 40px;
  border: none;
  background-color: #1571de;
  border-radius: 8px;
  color: white;
}


