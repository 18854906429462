.ButtonRouteBack {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

/* .WrapperBreadCrumbs {
  padding-top: 20px;
} */

.ContentEmployeeFormFMCG {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
  margin-top: 24pxgit ;
}

.WrapperAddPhotoEmployeeFormFMCG {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.WrapperImageEmployeeFormFMCG {
  background-color: #e3f0ff;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-style: dashed; */
}

.WrapperImageEmployeeFormFMCG > img {
  width: 32px;
  height: 32px;
}

.WrapperInputEmployeeFormFMCG {
  padding-top: 30px;
}

.ItemsInputEmployeeFormFMCG {
  display: flex;
  column-gap: 15px;
}

.WrapperItemsInput {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.ItemInputEmployeeFormFMCG {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}

.ItemInputEmployeeFormFMCG > span {
  font-size: 13px;
  font-weight: 500;
  color: #0a0a0a;
}

.ButtonActionEmployeeFormFMCG {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
  padding-top: 30px;
}
