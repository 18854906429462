.Container {
  width: 100%;
  position: relative;
}

.Background {
  position: absolute;
  width: 100%;
  height: 130px;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: linear-gradient(
    91.1deg,
    #1571de -1.57%,
    rgba(21, 113, 222, 0.44) 103.08%
  );
}

.BoxWrapperResign {
  width: 100%;
  padding-inline: 200px;
  padding-block: 80px;
}

.WrapperResign {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}

.WrapperResignForm {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  z-index: 3;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
}

.WrapperExitForm {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  z-index: 3;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
}

.Heading > span {
  font-size: 33px;
  color: #333333;
  font-weight: 600;
}

.Heading > p {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}

.ValueControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.Value {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Desc {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex: 1;
}

.Desc:last-child {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex: 1;
  justify-content: flex-start;
}

.Desc > img {
  width: 20px;
  height: 20px;
}

.DescItems {
  display: flex;
  row-gap: 4px;
  flex-direction: column;
}

.DescItems > span:first-child {
  font-size: 12px;
  font-weight: 400;
  color: #797a7b;
}

.DescItems > span:last-child {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}

.Extention {
  font-size: 13px;
  font-weight: 500;
  color: #9e9e9e;
}

.StatusController {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff9e9;
  border: 1px solid #22970f;
  border-radius: 8px;
  padding: 16px;
}

.StatusText {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.StatusText > span:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #22970f;
}

.StatusText > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.StatusControllerReject {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff4f2;
  border: 1px solid #d52701;
  border-radius: 8px;
}

.StatusTextReject {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.StatusTextReject > span:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #d52701;
}

.StatusTextReject > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.ContainerPDF {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.HeadingPDF {
  display: flex;
  width: 100%;
}

.HeadingPDF > span {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
}

.BoxPDF {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 13px;
  cursor: pointer;
  transition-duration: 0.5s;
}

.BoxPDF:hover {
  background: #dbe5f1;
}

.PDFItems {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.CircleImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: #f3f5f7;
}

.CircleImage > img {
  width: 27px;
  height: 27px;
}

.PDFItems > span {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.DownloadIcon {
  width: 24px;
  height: 25px;
}

.Tab {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-style: normal;
}

.BodyTab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}

.ValueGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 12px;
}

.InputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.InputGroup > span {
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}

.InputGroup > textarea {
  font-size: 13px;
  font-weight: 400;
  color: #0a0a0a;
  background: #f3f5f7;
  border-radius: 5px;
  padding: 13px;
  resize: vertical;
  max-height: 46px;
}

.Content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  row-gap: 8px;
}

.ContentGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ContentGroup > label {
  color: #0a0a0a;
  font-size: 14px;
  font-weight: 600;
}

.Content > span {
  flex: 1;
  color: #757575;
  font-size: 13px;
  font-weight: 400;
}

.Content > span:last-child {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.ButtonAction {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.ButtonAction > button:first-child {
  width: 100%;
  height: 40px;
  text-transform: none;
  background: #d52701;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.ButtonAction > button:last-child {
  width: 100%;
  height: 40px;
  text-transform: none;
  background: #1571de;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.NoteWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
}

.NoteWrapper > label {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.NoteText {
  display: flex;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.NoteText > span {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}

@media screen and (max-width: 1024px) {
  .BoxWrapperResign {
    padding-inline: 40px;
  }
}

@media screen and (max-width: 768px) {
  /* .BoxWrapperResign {
      padding-inline: 40px;
    } */
}

@media screen and (max-width: 425px) {
  .BoxWrapperResign {
    padding: 20px;
  }

  .Background {
    display: none;
  }

  .WrapperResignForm {
    padding: 18px;
  }

  .WrapperExitForm {
    padding: 18px;
  }

  .Content > span:last-child {
    /* justify-content: unset; */
    text-align: right;
  }
  .PDFItems > span {
    width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Desc {
    align-items: unset;
  }
  .Desc:last-child {
    align-items: unset;
  }
}

@media screen and (max-width: 325px) {
  .PDFItems > span {
    width: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
