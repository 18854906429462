.container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 130px);
  overflow: auto;
  .containerNumberList {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    gap: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    .leftHeader {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .content {
      display: flex;
      flex-direction: column;
    }
  }
}

.numberListRow {
  display: flex;
  flex-direction: column;
  gap: 2px;
  > span:first-child {
    color: var(--Neutral-100, #0a0a0a);

    /* Text-M/regular */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  > span:last-child {
    color: var(--Neutral-60, #9e9e9e);

    /* Text-S/regular */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.locationRow {
  display: flex;
  align-items: center;

  gap: 4px;
  width: 150px;
  > span {
    color: var(--Neutral-100, #0a0a0a);

    /* Text-M/regular */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // width: 100%;
  }
  > div {
    width: 20px;
    height: 20px;

    > img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      border-radius: 24px;
    }
  }
}

.statusRow {
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #22970f;
  background: #f4fff2;
  color: #22970f;
  text-align: center;

  /* Text S/Medium */
  // font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  &.statusSoldRow {
    // border-radius: 6px;
    border: 1px solid var(--Neutral-60, #9e9e9e);
    background: var(--Neutral-30, #ededed);
    color: var(--Neutral-60, #9e9e9e);
  }

  &.statusVoidRow {
    // border-radius: 6px;
    color: var(--Danger-Main, #d42701);
    border: 1px solid var(--Danger-Main, #d42701);
    background: var(--Danger-Surface, #fff4f2);
  }
}

.iconSimcard {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}

// .temp {
//   border-radius: 16px;
//   background: #fff;
//   box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
