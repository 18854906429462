.product__list-item-top {
  display: flex;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
  row-gap: 20px;
}

.product__search {
  margin-right: auto;
}

.product__add > button {
  width: 152px;
  height: 40px;
  background: #1571de;
  border-radius: 4px;
  text-transform: none;
}

.product__list-table {
  padding-top: 24px;
}
.product__upload > button {
  text-transform: none;
  width: 120px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
}

.product__buton-download {
  text-transform: none;
  width: 120px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
}

.status__promo {
  /* width: 105px; */
  height: 26px;
  background: #fbe7b3;
  border: 1px solid #e09f3e;
  border-radius: 8px;
  font-size: 12;
  font-weight: 400;
  color: #A1621F;
  text-align: center;
}
