.Container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Wrapper {
    display: flex;
    flex-direction: column;
    width: 734px;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(9, 30, 66, 0.2),
      0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 5px;
    padding: 30px 40px;
  }

  .StatusController {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff9e9;
    border: 1px solid #22970f;
    border-radius: 8px;
    padding: 16px;
  }
  
  .StatusText {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  
  .StatusText > span:first-child {
    font-size: 16px;
    font-weight: 500;
    color: #22970f;
  }
  
  .StatusText > span:last-child {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
  }
  
  .StatusControllerReject {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #fff4f2;
    border: 1px solid #d52701;
    border-radius: 8px;
  }
  
  .StatusTextReject {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  
  .StatusTextReject > span:first-child {
    font-size: 16px;
    font-weight: 500;
    color: #d52701;
  }
  
  .StatusTextReject > span:last-child {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
  }
  
  /* .heading-approval {
    display: flex;
    flex-direction: column;
  } */
  
  .Heading {
    padding-top: 24px;
  }
  
  .Heading > span {
    font-size: 33px;
    color: #333333;
    font-weight: 600;
  }
  
  .Heading > p {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  
  .ContentItem {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    padding-top: 50px;
  }
  
  .InputGroup {
    display: flex;
    /* align-items: center; */
    column-gap: 10px;
    width: 270px;
  }

  .IconContent {
    height: 24px;
    width: 24px;
  }

  .ColumnGroup {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  
  .ColumnGroup > span {
    font-size: 11px;
    font-weight: 400;
    color: #797a7b;
  }
  
  .ColumnGroup > p {
    font-size: 13px;
    font-weight: 500;
    color: 500;
  }

  .ButtonGroupEmployee {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    padding-top: 60px;
    margin-top: auto;
  }
  
  .ButtonGroupEmployee > button:first-child {
    text-transform: none;
    background: #d52701;
    border-radius: 8px;
    color: #ffffff;
    width: 100%;
    /* max-width: 230px; */
    height: 40px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .ButtonGroupEmployee > button:last-child {
    text-transform: none;
    background: #1571de;
    border-radius: 8px;
    color: #ffffff;
    width: 100%;
    /* max-width: 230px; */
    height: 40px;
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
  
    .Heading {
        padding-top: 0;
    }
  }

  @media (max-width: 480px) {
    /* .container-approval-page {
      height: auto;
    } */
    .Wrapper {
      border-radius: 0px;
      box-shadow: none;
      height: 100vh;
    }
  
    .BoxWrapperResign {
      padding-inline: 15px;
    }
  
    .Background {
      display: none;
    }
  
    .WrapperFormEmployee {
      top: 0;
      border-radius: 0px;
      box-shadow: none;
      padding: 20px;
    }

    .ButtonGroupEmployee {
        padding-bottom: 20px;
    }
  
    .Text > span {
      font-size: 20px;
    }
  
    .Keys > span,
    .Label > span,
    .DescIcon > span,
    .Desc > span,
    .BorderBlueEmployee > span {
      font-size: 12px;
    }
  
    .WrapperResponsive > label {
      font-size: 14px;
    }
  
    .StatusControllerReject {
      padding: 10px;
    }
  
    .StatusTextReject > span:first-child {
      font-size: 14px;
    }
  
    .StatusTextReject > span:last-child {
      font-size: 12px;
    }
  
    .StatusController {
      padding: 10px;
    }
  
    .StatusText > span:first-child {
      font-size: 14px;
    }
  
    .StatusText > span:last-child {
      font-size: 12px;
    }
  
    .TopBG {
      height: 100vh;
    }
  
    .SetPasswordBoxContainer {
      width: 100%;
      gap: 15px;
      padding: 20px;
    }
  
    .Form > button {
      width: 100%;
      margin-top: 15px;
    }
  
    .IconButton {
      position: absolute !important;
      right: 5%;
      top: 0%;
    }
  }

  @media (max-width: 320px) {
    .Keys > span,
    .Label > span,
    .DescIcon > span,
    .Desc > span .BorderBlueEmployee > span {
      font-size: 10px;
    }
  
    .Wrapper {
      padding-right: 30px;
      padding-left: 40px;
      padding-block: 40px;
    }
  
    .IconButton {
      position: absolute !important;
      right: 3%;
      top: 0%;
    }
  }