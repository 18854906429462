.container-payslip-upload-menu {
  padding-top: 20px;
}

.item-top-payslip-upload-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: flex-start;
}

.item-top-payslip-upload-menu :nth-child(2) {
  margin-right: auto;
  /* max-width: 176px; */
}

.container-upload-file-pyaslip-menu {
  padding-top: 32px;
}


.searchbar-payslip-upload-menu {
  display: flex;
  padding-top: 30px;
}

/* RDU */
.input-RDU {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  /* background-color: red; */
  height: 100%;
  padding-block: 16px;
}

.rejected-files-RDU {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

.input-RDU :first-child > span {
  color: #616161;
  font-size: 20px;
  font-weight: 500;
}

.input-RDU :nth-child(2) > span {
  color: #c2c2c2;
  font-size: 16px;
  font-weight: 500;
}

.input-RDU :nth-child(3) > span {
  color: #616161;
  font-size: 20px;
  font-weight: 500;
}

.input-RDU :last-child {
  padding-bottom: 15px;
  padding-top: 5px;
}


.preview-RDU {
  display: flex;
  /* background-color: red; */
  width: 100%;
  padding-inline: 20px;
  padding-top: 20px;
}

.icon-RDU {
  display: flex;
  align-items: center;
}

.text-name-RDU {
  display: flex;
  width: 100%;
  /* background-color: red; */
}

.progress-RDU {
  display: inline-block;
  /* background-color: red; */
  width: 100%;
  padding-inline: 40px;
}

.progress-RDU-done {
  display: flex;
  width: 100%;
  padding-left: 75px;
}

.progress-RDU-done > span {
  color: #1571DE;
  font-size: 12px;
  font-weight: 500;
}

/* .container-table-payslip-menu {
  padding-top: 18px;
} */

/* .progress-RDU {
  display: flex;
  align-items: center;
  padding-top: 5px;
  width: 100%;
} */
