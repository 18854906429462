.Container {
  width: 100%;
  height: 100vh;
}

.Select {
  height: 40px;
  border-radius: 8px !important;
  min-width: 132px;
  background-color: var(--main-bg);
}

.Header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.Selection {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.TableWrapper {
  padding-top: 24px;
}

.TableContainer {
  min-width: 600;
  background-color: "#ffffff";
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  border-bottom: 0;
}

.Table {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
}

.Status {
  border-radius: 8px;
  padding: 3px 10px;
  display: inline-block;
  border: 1px solid;
  border-radius: 6px;
}

.Loading {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.LoadingWidth {
  width: 200px;
}