.body {
  display: flex;
  flex-direction: column;
  gap: 6px;
  > span:first-child {
    color: var(--Neutral-70, #757575);

    /* Caption 1/Medium */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    height: 271px;
    border-radius: 8px;
    border: 1px dashed var(--Neutral-50, #c2c2c2);
    background: var(--Neutral-20, #f5f5f5);
    transition: background-color 0.3s ease;
    cursor: pointer;
    &:hover {
      background: #e0e0e0; /* Lebih gelap dari #f5f5f5, tapi tidak terlalu gelap */
    }
    &.error {
      border: 1px dashed red;
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
    }
    > span:nth-child(3) {
      color: #000;
      text-align: center;

      /* Text S/Medium */
      //   font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 153.846% */
    }
    > span:nth-child(4) {
      color: var(--Neutral-50, #c2c2c2);
      text-align: center;

      /* Text S/Reguler */
      //   font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
    > span:last-child {
      color: var(--Primary-Main, #1571de);
      text-align: center;
      //   font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
  .imageContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    height: 271px;
    border-radius: 8px;
    border: 1px dashed var(--Neutral-50, #c2c2c2);
    background: var(--Neutral-20, #f5f5f5);
    transition: background-color 0.3s ease;
    cursor: pointer;
    >img{
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .filesBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-40, #dee2e6);
    background: var(--Neutral-10, #fff);
    padding: 16px 16px;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      > div {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > span {
        color: var(--Neutral-100, #000);

        /* Heading/H6 */
        //   font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 144.444% */
      }
    }

    .right {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  > span:last-child {
    font-size: 12px;
    color: red;
  }
}
