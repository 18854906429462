@font-face {
  font-family: arlon;
  src: url(../../../../assets/font/arlon/ArlonSemiBold-DEMO.otf)
    format("truetype");
}

.container-payslip-report {
  width: 100%;
  height: 100vh;
}
.wrapper-payslip-report {
  padding-inline: 32px;
  padding-block: 12px;
}

.item-top-payslip-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-left-payslip-report {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  flex: 1;
}
.item-left-payslip-report > span {
  font-family: arlon;
  font-size: 16px;
}
.item-mid-payslip-report {
  flex: 1;
  display: flex;
  justify-content: center;
}

.item-mid-payslip-report > span {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
}
.item-right-payslip-report {
  flex: 1;
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
}
.item-right-payslip-report {
  display: flex;
  column-gap: 20px;
}
.item-right-payslip-report > span {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}
.item-right-payslip-report > button {
  text-transform: none;
}
.datepicker-payslip-report {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}
.company-payslip-report {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  /* background-color: red; */
  /* width: 500px; */
  padding-top: 24px;
  padding-bottom: 24px;
}
.title-company-payslip-report {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  /* text-align: center; */
  /* background-color: red; */
  width: 354px;
  /* height: 40px; */
}
.title-company-payslip-report > label {
    font-size: 12px;
    font-weight: 500;
    color: #616161;
    text-align: center;
  }
