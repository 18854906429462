.add__employee-external-container {
  padding-top: 24px;
}

.add__employee-external-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item__top-add > button {
  text-transform: none;
  width: 133px;
  height: 40px;
  border: 1px solid #1571de;
  border-radius: 4px;
}

/* .employee__external-table {
    
} */
