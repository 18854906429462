.ContainerStoreAccountGroup {
  width: 100%;
  height: 100%;
}

.HeaderStoreAccountGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
}
.ContainerTableStoreAccountGroup {
  padding-top: 25px;
}
