.Container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.Header {
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;
}

.NoActivityText {
  display: flex;
  width: 100%;
  padding-top: 20px;
}

.NoActivityText >span {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.BoxSlider {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 16px;
}

.Profile {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.Profile > img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.ProfileDesc {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.ProfileDesc > span:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.ProfileDesc > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #9e9e9e;
}

.SwipeableBox {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-left: 60px;
  transition: all 250ms linear;
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

.SwipeableBox::-webkit-scrollbar {
  display: none;
}

.Controller {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  cursor: pointer;
  padding-right: 50px;
}

.Album {
  flex: 1;
  display: flex;
  overflow-x: auto;
  height: 100%;
  gap: 8px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.Album > img {
  width: 500px;
  height: 300px;
}

.Likes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 60px;
}

.LikesHeader {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 10px;
}

.LikesHeader > span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.LikesDesc {
  display: flex;
  flex-direction: column;
}

.LikesDesc > span:first-child {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.LikesDesc > span:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
}


