.Container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.HeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    gap: 17px;
}

.EditButton {
    border-radius: 8px !important;
    color: #1571DE !important;
    border: 1px solid #1571DE !important;
    text-transform: capitalize !important;
    min-width: 46px;
    padding: 0;
    width: 100%;
}
.AddButton {
    border-radius: 8px !important;
    color: #1571DE !important;
    border: 1px solid #1571DE !important;
    text-transform: capitalize !important;
    min-width: 160px!important;
}

.TableWrapper {
    padding-top: 16px;
}

.TableContainer {
    min-width: 600;
    background-color: '#ffffff';
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    border-bottom: 0;
}

.Table {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;

}

.TableSortLabel {
    color: #0a0a0a !important;
    font-weight: 500;
    font-size: 14px;
}

.TableCell {
    font-size: 14px;
    font-weight: 400;
    color: #0a0a0a;
}

.DeleteCell button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: transparent;
}


.DeleteCell img {
    height: 17px;
    width: 15px;
    object-fit: cover;
}