.LoadingContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .LoadingWidth {
    width: 200px;
  }