.ContainerSPGStore {
  width: 100%;
  height: 100%;
  /* max-height: 100vh; */
}

.HeaderSPGStore {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
}

.ButtonUploadAction {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-right: auto;
}

.ContainerTableSPGStore {
  padding-top: 25px;
}
