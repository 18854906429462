.wrapperEmployeePage {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  max-width: 1440px;
  justify-self: center;
  height: calc(100vh - 90px);
  .header {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 316px;
    > h1 {
      color: #000;
      font-weight: 500;
      line-height: 50px;
    }
  }
}

.table {
  .withProfilePic {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #0a0a0a;
    cursor: pointer;
  }
  .img {
    border-radius: 50%;
    object-fit: contain;
    width: 24px;
    height: 24px;
  }
  .status {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    width: 100%;
    min-width: 78px;
    font-size: 12px;
    justify-content: center;
    font-weight: 500;
    &.active {
      border: 1px solid #22970f;
      background: #f4fff2;
      color: #22970f;
    }
    border: 1px solid #d42701;
    background: #fff4f2;
    color: #d42701;
  }
}
