.add__products-container {
    padding-top: 20px;
}
.add__products-item-top {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.add__products-table {
    padding-top: 24px;
}
