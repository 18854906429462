.WrapperStoreBestSeller {
  flex: 1;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 237px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.HeaderStoreBestSeller > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.ContentStoreBestSeller {
  display: flex;
  flex-direction: column;
}

.WrapperContentBestSeller {
  display: flex;
  column-gap: 12px;
  padding-top: 10px;
  /* row-gap: 20px; */
}

.WrapperContentBestSeller > :nth-of-type(1) > img {
  width: 68px;
  height: 48px;
  border-radius: 2px;
}

.WrapperContentBestSeller > div > :nth-of-type(2) {
  display: flex;
  flex-direction: column;
}

.TextNameStoreBestSeller {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.TextSalesStoreBestSeller {
  font-size: 12px;
  font-weight: 400;
  color: #7d7d7d;
}
