.modal-wrapper {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop {
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7); */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  position: relative;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height:auto; */
  /* min-height: 30%; */
  /* width: 60%; */
  /* width: 438px;
  height: 414px; */
  min-height: 30%;
  width: 438px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
  /* padding: 40px; */
  padding: 12px 24px 20px 24px;
  background: #ffffff;
  border-radius: 12px;
}

/* ------------------------------------ */
.modal-wrapper-bpjs {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop-bpjs {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-box-bpjs {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height:auto; */
  /* min-height: 30%; */
  /* width: 60%; */
  /* width: 438px;
  height: 414px; */
  min-height: 50%;
  /* width: 438px; */
  width: 644px;
  /* height: 583px; */
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
  /* padding: 40px; */
  padding: 12px 24px 20px 24px;
  background: #ffffff;
  border-radius: 12px;
}
