.Modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalContainer {
    display: flex;
    align-items: center;
    width: 998px;
    height: 830px;
    border-radius: 12px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    display: flex;
    flex-direction: column;
    padding: 24px 24px 8px;
    position: absolute;
    top: 100px;
    overflow: auto;
}

.ModalBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: inherit;
    gap: 10px;
    position: relative;
}

.Title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 20px;
    color: #0A0A0A;
}

.Component {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
}

.Component>span {
    width: 100%;
    font-size: 14px;
    color: #000 !important;
    font-weight: 400;
    margin-top: 8px;
}

.Component>input,
.Component>textarea {
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 8px;
    width: 100%;
    /* height: 80px; */
    padding: 8px;
    font-size: 14px;
    color: #616161;
}

.Component>textarea:focus {
    border: 2px solid #1571DE !important;
    outline: #1571DE !important;
}

.MaxCharacter{
    display: flex;
    width: 100%;
    color: #C2C2C2;
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    justify-content: flex-end;
}

.TitleComponent{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TitleComponent>span{
    width: 100%;
    font-size: 14px;
    color: #000 !important;
    font-weight: 400;
    margin-top: 8px;
}

.TableRowComponent{
    display: flex;
    /* gap: 8px; */
    align-items: center;
}

.CenterEmptySetupPushNotification{
    display: flex;
    width: 100%;
    padding: 24px;
    border: 1px solid #D9D9D9;
    background-color: #FFFFFF;
}

.EmptySetupPushNotification{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    padding-top: 12px;
}   

.ButtonLayout{
    display: flex;
    width: 100%;
    gap: 10px;
    padding-top: 12px;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 24px;
}

.ErrorText{
    font-size: 12px;
    color: #df2222;
}

.Published {
    background-color: #F4FFF2;
    color: #22970F !important;
    border: 1px solid #22970F;
    padding: 2px 5px;
    border-radius: 5px;
}

.Waiting {
    background-color: #DEFEFF;
    color: #1571DE !important;
    border: 1px solid #1571DE;
    padding: 2px 5px;
    border-radius: 5px;
}

.TableContainer{
    display: flex;
    flex-direction: column;
}