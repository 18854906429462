.Container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.LeftHeader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.Searchbar {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.Dropdown {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
}

.ButtonAdd {
    width: 180px !important;
    height: 40px !important;
    background: #1571DE !important;
    border-radius: 8px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff !important;
    text-transform: capitalize !important;
}

.AddText {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.TableWrapper {
    padding-top: 16px;
}

.TableContainer {
    min-width: 600;
    background-color: '#ffffff';
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    border-bottom: 0;
}

.Table {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;

}



.Published {
    background-color: #F4FFF2;
    color: #22970F !important;
    border: 1px solid #22970F;
    padding: 2px 5px;
    border-radius: 5px;
}

.Draft {
    background-color: #DEFEFF;
    color: #1571DE !important;
    border: 1px solid #1571DE;
    padding: 2px 5px;
    border-radius: 5px;
}

.Unpublish {
    background-color: #FFF9E9;
    color: #E9B62F !important;
    border: 1px solid #E9B62F;
    padding: 2px 5px;
    border-radius: 5px;
}

.ClientCell button {
    background-color: #1571DE;
    padding: 0;
    width: 40px;
    height: 28px;
    font-size: 14px !important;
    color: #fff;
    border-radius: 20px;
    margin-left: 10px;
}

.PopoverDiv {
    max-width: 200px;
    padding: 5px 8px;
}

.PopoverDiv span {
    font-size: 12px;
    color: #0a0a0a;
}

.Plus {
    padding: 4px 8px;
    background-color: #1571DE;
    border-radius: 20px;
    cursor: pointer;
    color: #ffffff;
    margin-left: 12px;
    font-size: 14px!important;
    font-weight: 500;
}

.ClientsChipContainer {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 16px;
    margin-bottom: 8px;
    padding-right: 5px;
}

.ClientsChip {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 4px 12px;
}

.ClientsChip span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #404040;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CancelIcon {
    font-size: 10px !important;
    color: #404040;
    cursor: pointer;
}