.WrapperMapFMCG {
  width: 100%;
  position: relative;
  z-index: 1;
}

.WrapperSearchboxMap {
  position: absolute;
  /* transform: translateY(50%); */
  z-index: 3;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  width: 330px;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.ContentMapFMCG {
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
  row-gap: 10px;
  margin-top: 10px;
}

.TextPinLocation {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.TextPinLocation > span:nth-of-type(1) {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
}

.TextPinLocation > span:nth-of-type(2) {
  color: #949494;
  font-size: 12px;
  font-weight: 400;
}

.WrapperAdressMapFMCG {
  background: #f8fbff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.WrapperAdressMapFMCG > span:nth-of-type(1) {
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 400;
}

.WrapperAdressMapFMCG > span:nth-of-type(2) {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}

.WrapperLongLatFMCG {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ContainerLongLat {
  background: #f8fbff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ContainerLongLat > span:nth-of-type(1) {
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 400;
}

.ContainerLongLat > span:nth-of-type(2) {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}

.ContainerSelectGroup {
  display: flex;
  flex-direction: column;
}

.ContainerSelectGroup > span {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}

.ButtonSaveAddNewStore {
  margin-top: auto;
  padding-top: 30px;
}
