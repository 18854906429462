.Container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);
}

.Heading {
  padding-bottom: 24px;
}

.BoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Loading {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingWidth {
  width: 200px;
}

.BoxGroup {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
}

.TextLabel {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.Icon {
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(208, 227, 248, 0.3);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Icon > i {
  font-size: 1.5rem;
  color: #1571de;
}

.TextLabel > span {
  color: #0a0a0a;
  font-size: 1.25rem;
  font-weight: 500;
}

.Details {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.DetailValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DetailValue > span:first-child {
  font-size: 1rem;
  font-weight: 400;
  color: #757575;
}

.DetailValue > span:last-child {
  font-size: 1rem;
  font-weight: 500;
  color: #757575;
}

.WorkTime {
  padding-top: 10px;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header > span {
  font-size: 1.25rem;
  font-weight: 500;
  color: #0a0a0a;
}

.ActionButtonWrapper {
  display: flex;
  column-gap: 0.4rem;
  align-items: center;
}

.ActionButtonWrapper > button {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  background-color: #ffffff;
  padding: 0.438rem;
}

.AddMode {
  display: flex;
  align-items: center;
  gap: 0.313rem;
}

.AddMode > button:nth-child(2) {
  border-radius: 0.25rem;
  background: #1571de;
  text-transform: none;
  color: #ffffff;
  width: 3.063rem;
  height: 1.75rem;
}

.AddMode > button:last-child {
  border: none !important;
  padding: 0;
  height: 1.625 !important;
}

.AddModeSearch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.SearchModeHeader {
  display: flex;
  align-items: center;
  column-gap: 0.313rem;
}

.SearchMode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* column-gap: 0.313rem; */
}

.SearchModeHeader > button {
  display: flex;
  background: none;
}

.SearchMode > input {
  height: 1.813rem;
  border-radius: 0.313rem !important;
  width: 11.188rem;
  padding-right: 1.563rem;
  padding-left: 0.313rem;
}

.SearchMode > button {
  border: none !important;
}

.AddModeSearch img,
.SearchMode img {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.063rem;
  margin-right: 0.25rem;
  display: flex;
  position: absolute !important;
}

.AddModeSearch > input {
  height: 1.813rem;
  border-radius: 0.313rem !important;
  width: 11.188rem;
  padding-right: 1.563rem;
  padding-left: 0.313rem;
}

.SearchIcon {
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
}

.SelectionLeader {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-block: 16px;
}

.SelectionLeader > span {
  font-size: 13px;
  font-weight: 500;
  color: #0a0a0a;
}

.TableWrapper {
  padding-top: 10px;
}

.TableContainer {
  min-width: 600;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  border-bottom: 0;
}

.Table {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
}

.TableSortLabel {
  color: #0a0a0a !important;
  font-weight: 500;
  font-size: 14px;
}

/* .TableRow {
  height: 40px!important;
} */

.TableCell {
  font-size: 14px;
  font-weight: 400;
  color: #0a0a0a;
}

.TablePagination {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.DeleteIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.ContainerLoading {
  padding-top: 90px;
  /* height: 100%; */
  display: flex;
  justify-content: center;
}
