.Container {
    width: 100%;
    height: 100vh;
    padding-top: 0;
}

.Wrapper {
    display: flex;
    margin-top: 24px;
    min-height: 100vh;
}

.Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 42px;
    gap: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 130px;
    align-self: flex-start;
}

.MainAvatar {
    width: 150px !important;
    height: 150px !important;
}

.Right {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
}

.ID {
    background-color: #fff;
    padding: 24px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.IDHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.IDHeader span:first-child {
    font-size: 20px;
    font-weight: 500;
    color: #404040;
    margin-bottom: 0;
    text-transform: capitalize;
}

.DataInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.BoxData {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.BoxTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
}

.BoxTitle img {
    height: 24px;
    width: 24px;
    object-fit: cover;
}

.BoxTitle span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
}

.BoxDesc {
    width: 100%;

}

.BoxDesc span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
    float: right;
    text-align: end;
}

.TDTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575 !important;
    padding-left: 0 !important;
}

.TDData {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
}