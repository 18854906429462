.ContainerProductCompetitor {
  width: 100%;
  height: 100vh;
}

.WrapperContentProductCompetitor {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 30px;
}

.ContainerDetailsProductCompetitor {
  width: 100%;
  height: 100%;
}

.WrapperContentDetailsProductCompetitor {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 30px;
}

/* .WrapperFormDetailsPromo {
    display: flex;
  } */

.WrapperItemsInputDetailsProductCompetitor {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.ItemsInputDetailsProductCompetitor {
  display: flex;
  column-gap: 15px;
}

.ItemInputDetailsProductCompetitor {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  width: 100%;
}

.ItemInputDetailsProductCompetitor > span {
  font-size: 13px;
  font-weight: 500;
}

.WrapperTableDetailsProductCompetitor {
  padding-top: 30px;
}

.HeaderTableDetailsProductCompetitor {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ButtonActionDetailsPromo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
  padding-top: 30px;
}
