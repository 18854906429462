/* .product__list-container {
  padding-top: 32px;
} */

.product__list-search {
  display: flex;
}

.product__list-items {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  column-gap: 40px;
  row-gap: 30px;
  padding-top: 20px;
}
.product__items {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  padding-top: 24px;
  width: 218.25px;
}
.product__items > img {
  width: 100%;
  height: auto;
}

.product__desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
