@font-face {
  font-family: arlon;
  src: url(../../../../assets//font/arlon/ArlonSemiBold-DEMO.otf)
    format("truetype");
}

.container-loan-report {
  width: 100%;
  height: 100vh;
}
.wrapper-loan-report {
  padding-inline: 32px;
  padding-block: 12px;
}
.item-top-loan-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-left-loan-report {
  display: flex;
  align-items: center;
  column-gap: 10px;
  /* background-color: cadetblue; */
  flex: 1;
  cursor: pointer;
}
.item-left-loan-report > span {
  font-family: arlon;
  font-size: 16px;
}
.item-mid-loan-report {
  flex: 1;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
}

.item-mid-loan-report > span {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
}
.item-right-loan-report {
  flex: 1;
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
}
.item-right-loan-report {
  display: flex;
  column-gap: 20px;
}
.item-right-loan-report > span {
  font-size: 16px;
  font-weight: 500;
  color: #1571de;
}
.item-right-loan-report > button {
  text-transform: none;
}
.date-picker-shown {
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  /* background-color: red; */
  /* align-items: center; */
  /* width: 300px; */
}
.wrapper-date-picker-shown {
  display: flex;
  /* background-color: red; */
  padding-top: 40px;
  align-items: center;
  column-gap: 20px;
}
.company-loan-report {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  /* background-color: red; */
  /* width: 500px; */
  padding-top: 24px;
  padding-bottom: 10px;
}
.title-company-report {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  /* text-align: center; */
  /* background-color: red; */
  width: 354px;
  /* height: 40px; */
}
.title-company-report > label {
  font-size: 12px;
  font-weight: 500;
  color: #616161;
  text-align: center;
}
/* .line-loan-report {
    padding-top: 10px;
} */
.desc-item-loan-report {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  padding-top: 24px;
}
.mini-item-left {
  width: 152px;
  height: 66px;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.mini-item-left > label {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}
.mini-item-left > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}
.mini-item-middle {
  width: 152px;
  height: 66px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding-top: 10px;
  /* background-color: blue; */
}
.mini-item-middle > label {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}
.mini-item-middle > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}
.mini-item-right {
  width: 152px;
  height: 66px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  /* background-color: blue; */
}

.mini-item-right > label {
  font-size: 16px;
  font-weight: 400;
  color: #0a0a0a;
}
.mini-item-right > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}
.border-seperator {
  border-left: 1px solid #c2c2c2;
  height: 67px;
}
.loan-progress-container {
  padding-top: 24px;
}
.table-loan-progress {
  padding-top: 24px;
}
