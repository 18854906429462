.Background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.Container {
  min-width: 437px;
  width: 437px;
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Header {
  font-size: 1.25rem;
  color: #000;
  font-weight: 500;
}

.InputWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Input > span {
  font-size: 0.875rem;
  color: #616161;
  font-weight: 400;
}

.Description {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Description > span {
  font-size: 0.875rem;
  color: #616161;
  font-weight: 400;
}

.Description > textarea {
  font-size: 0.875rem;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 10px 8px;
  resize: vertical;
  min-height: 100px;
}

.ErrorMessage {
  padding-top: 0.5rem;
  font-size: 14px;
  color: #D42701;
  font-weight: 400;
}

.ButtonWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.ButtonWrapper > button:first-child {
  text-transform: none;
  border-radius: 8px;
  border: 1px solid #1571de;
  background-color: #fff;
  min-width: 5rem;
}

.ButtonWrapper > button:last-child {
  text-transform: none;
  border-radius: 8px;
  background-color: #1571de;
  min-width: 5rem;
}
