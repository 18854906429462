.SummaryCardContainer {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
}

.SCHeader {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
}

.SCHeader>span {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #404040;
}

.SCHeader button {
    background: #1571DE;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: #fff !important;
}

/* Content */

.SCContent {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.CalendarCard {
    padding: 0;
    margin: 0;
}

.CalendarHeader {
    padding: 16px;
    border-bottom: 1px solid #d9d9d9;
}

.CalendarHeader>span {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #404040;
}

.Daysbar,
.AbsencesContainer {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 1px;
    background-color: #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.EachDay {
    padding: 8px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6F6F70;
    text-transform: capitalize;
    background-color: #fff;
}

.AbsenceBox {
    min-height: 108px;
    height: auto;
    background-color: #fff;
    padding: 8px;
    cursor: pointer !important;
}

.Weekend {
    background-color: #FFF4F2 !important;
}

.Weekend>span{
    color: #D42701 !important;
}

.Weekend .ABTopLeft>span:first-child {
    color: #D42701 !important;
}

.Closing {
    width: 100% !important;
    height: 50px !important;
}

.ABTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
}

.ABTopLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px
}

.ABTopLeft>span:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #0A0A0A;
}

.ABTopLeft>span:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0A;
}

.ABTop>span {
    font-size: 22px !important;
}

.BottomDivider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 53%;
}

.ABCenter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.ABCenter>span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}

.ClockIcon {
    width: 19px !important;
    height: 19px !important;
    color: #acacac !important;
}

.ABBottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 18px !important;
}

.ABBottom>span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #0A0A0A;
}