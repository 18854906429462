.ContainerMasterCompetitor {
  width: 100%;
  height: 100vh;
}

.HeaderMasterCompetitor {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
}

.ButtonUploadAction {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-right: auto;
}

.ContainerTableMasterCompetitor {
  padding-top: 30px;
}

/* .ButtonAddCompetitor {
  margin-left: auto;
} */
