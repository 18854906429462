.Calendar {
  display: flex;
  flex-direction: column;
  // width: 90%;
  // min-width: 835px; 
  height: auto;
}

.CalendarRow {
  padding-block: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EachDayCard {
  width: 90px;
  aspect-ratio: 3/4;
}

.EachDayCardCheckImage {
  width: 50px;
  aspect-ratio: 1;
  margin-block: 8px;
}

.EachDayCardDate {
  margin-bottom: 4px;
  span {
    font-size: 14px;
    font-weight: 600;
    font-family: 'poppins', sans-serif;
  }
}

.EachDayCardContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.EachDayCardTime {
  span {
    font-size: 12px;
    color: #616161;
    font-weight: 400;
  }
}