.SidebarLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  list-style: none;
  height: 20px;
  text-decoration: none;
  transition-duration: .5s;
}

.SidebarLabel {
  width: 130px;
  /* margin-left: 16px; */
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}

.DropdownLink {
  height: 60px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
}

.SubnavItemInner {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: color 0.3s ease 0;
  column-gap: 10px;
  /* width: 100%; */
}

.SubnavItemInner > i {
  font-size: 18px;
  font-weight: 500;
}

.SubnavItemInner > span {
  padding-top: 2px;
  font-size: 16px;
  font-weight: 500;
}

.SubnavItemInner.active {
  border-radius: 8px;
  /* border-radius: var(--border-radius); */
  margin-left: 30px;
  margin-right: 25px;
  background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  );
  color: var(--txt-white);
}

