/* .wrapper-items-promo {
  padding-top: 20px;
} */

.items-top-promo {
  display: flex;
  justify-content: space-between;
}

.item-promo-right > button {
  text-transform: none;
  background-color: #1571de;
  color: #ffffff;
  height: 40px;
  font-size: 0.875rem;
}

.container-table-promo {
  padding-top: 32px;
}
