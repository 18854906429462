.modalBackground-ePay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer-ePay {
  width: 541px;
  height: 770px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: absolute;
  top: 10px;
}

.modalContainer-ePay .title-ePay {
  display: inline-block;
  text-align: center;
  /* margin-top: 10px; */
}

.text__main-ePay > span {
  font-size: 32px;
  font-weight: 500;
  color: #616161;
}

.titleCloseBtn-ePay {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.items__fill {
  display: hidden;
  font-size: 0;
}

.text__main-ePay {
  font-size: 28px;
  font-weight: 500;
  color: #616161;
  /* padding-bottom: 10px; */
}
.item__button-right {
  padding-top: 5px;
}
.item__button-right > button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
/* .titleCloseBtn-ePay > button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
} */

.modalContainer-ePay .body-ePay {
  display: flex;
}
.body-ePay {
  display: flex;
  flex-direction: column;
}
.items-body-one {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  /* padding-bottom: 5px; */
}
.items-body-one > label {
  /* padding-bottom: 3px; */
  font-size: 12px;
  font-weight: 500;
  color: #616161;
}
.items-body-one > span {
  font-size: 18px;
  font-weight: 400;
  color: #0a0a0a;
}

/* items-bpdy-two */
.wrapper-items-body-two {
  padding-top: 10px;
}
.items-body-two-main {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a0a;
  padding-bottom: 5px;
}
.items-body-two {
  display: flex;
  /* padding-left: 100px; */
  justify-content: space-between;
  padding-bottom: 8px;
}

.items-body-two > label {
  font-size: 14px;
  font-weight: 400;
  color: #0a0a0a;
  /* font-style: normal; */
}
.items-body-two > span {
  font-size: 14px;
  font-weight: 400;
  color: #0a0a0a;
}

.body__items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body__items-wrapper > button {
  margin-top: 10px;
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-ePay {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding-top: 20px;
}

.button__ePay-one > button {
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-left: 10px;
  width: 124px;
  height: 40px;
}

.button__ePay-two > button {
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #1571de;
  width: 124px;
  height: 40px;
}

/* .footer > button {
  border: 1px solid #1571de;
  border-radius: 4px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-left: 10px;
} */
