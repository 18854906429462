/* .ContainerNewUserChart {
} */

.WrapperNewUserChart {
  flex: 1;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  /* max-height: 386px; */
  height: 237px;
  display: flex;
  flex-direction: column;
}

.HeaderNewUserChart > span {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
}

.NewUserProgressBar {
  display: flex;
  align-items: center;
  column-gap: 2px;
  padding-top: 22px;
}

.WrapperProgressBar {
  flex: 1;
}

.ReportNewUserChart {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 20px;
}

.TodaysReportNewUser {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.TodaysTextReport {
  color: #616161;
  font-size: 16px;
  font-weight: 500;
}

.NumberTextReport {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.PercentageReportNewUser {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 8px;
}

.PercentageReportNewUser > span > :nth-child(2) {
  color: #1b45d9;
  font-size: 14px;
  font-weight: 400;
}

.YesterdayTextReport {
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
}
